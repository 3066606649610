import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthorized } from "./authorization";
export const ProtectedUserRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthorized()) {
          // Render the protected component if the user is authenticated
          return <Component {...props} />;
        } else {
          // Redirect to the login page if the user is not authenticated
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    />
  );
};
