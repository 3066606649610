import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "En",
};

export const LanguageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeToEn: (state) => {
      state.value = "En";
    },
    changeToFr: (state) => {
      state.value = "Fr";
    },
    changeLanguage: (state, payload) => {
      state.value = payload;
    },
  },
});

export const { changeToEn, changeToFr, changeLanguage } = LanguageSlice.actions;

export const selectLanguage = (state) => state.language?.value;

export default LanguageSlice.reducer;
