import React from "react";
import styled from "styled-components";
import UserLayout from "../layouts/UserLayout";
import UploadForm from "../components/UploadForm";
import UpdateForm from "../components/UpdateDataset";
// import EditProfile from "../../new_ui/editprofile/editprofile";
import { useLocation } from "react-router-dom";
import { DATASET } from "../config/url";
import { AXIOS_GET } from "../config/axios";

const UploadPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isUpdate = queryParams.get("update") === "true";
  const dValue = queryParams.get("d");
  return (
    <UserLayout>
      <Container>
        {isUpdate ? <UpdateForm code={dValue} /> : <UploadForm />}
      </Container>
    </UserLayout>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export default UploadPage;
