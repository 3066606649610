import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { ToastContainer, toast } from "react-toastify";
import LinkIcon from "@mui/icons-material/Link";
import DownloadIcon from "@mui/icons-material/Download";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import IosShareIcon from "@mui/icons-material/IosShare";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";
import { AXIOS_PATCH, AXIOS_POST } from "../config/axios";
import { DATASET } from "../config/url";
import DatasetView from "./Datasetview";

const DatasetContent = ({
  data,
  handleLikes,
  setSearchValue,
  openLoginBox,
}) => {
  const [views, setViews] = useState(data.views);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    AXIOS_PATCH(
      `${DATASET}/${data.code}/views`,
      {},
      sessionStorage.getItem("token")
    )
      .then((data) => {
        setViews((prev) => prev + 1);
        // console.log("View recorded");
      })
      .catch((error) => {
        // console.error(error);
      })
      .finally(() => {
        data.views = views;
        setOpen(true);
      });
  };
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("token");
  const handleLinkClick = (e) => {
    e.preventDefault();
    if (sessionStorage.getItem("token")) {
      // console.log(data)
      window.open(data.url, "_blank");
    } else {
      // alert("Please log in to access this link");
      openLoginBox(true);
    }
  };
  const handleRequest = (id) => {
    setLoading(true);
    if (token) {
      AXIOS_POST(`${DATASET}/requests`, { file_requested: id }, token)
        .then((response) => {
          // console.log(response.data)
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);

      // alert("Please log in to access this link");
      openLoginBox(true);

      // toast.error("Please sign in order to request access", {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: false,
      //   theme: "colored",
      // });
    }
  };

  return (
    <Container>
      <DatasetView
        setSearchValue={setSearchValue}
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        data={data}
        handleLikes={handleLikes}
      />
      <ToastContainer />
      <Content>
        <Icon>
          <DataIcon style={{ fontSize: 60, color: "#01944D" }} />
        </Icon>
        <Details>
          <h5>{data.name}</h5>
          <sub>
            <span> {data.organization} </span>
            <span
              style={{ padding: "0 5px", color: "#01944D", fontSize: "16px" }}
            >
              {" "}
              {data.is_private ? "Private" : "Public"}
            </span>
          </sub>
          <span> By {data.creator.name}</span>
          <span
            style={{
              fontStyle: "italic",
              fontSize: "12px",
              borderLeft: "2px solid #989393;",
            }}
          >
            {" "}
            At {data.updatedAt}
          </span>
          <br />
          <p style={{ paddingTop: "10px" }}>
            {data.description.slice(0, 300)}...
          </p>
          <LinkP>
            {data.is_private ? (
              <Link
                onClick={(event) => {
                  event.preventDefault();
                  handleRequest(data.code);
                }}
              >
                <IconRequest />
                {loading ? (
                  <BeatLoader color="#000000" css={override} size={10} />
                ) : (
                  "  Request Access"
                )}
              </Link>
            ) : (
              <>
                {data.post_type == "link" ? (
                  <Link href="" onClick={handleLinkClick}>
                    <IconLInk />
                    Link
                  </Link>
                ) : (
                  <a href="">
                    <IconDonwload />
                    download
                  </a>
                )}
              </>
            )}
            <Link
              to=""
              onClick={(e) => {
                e.preventDefault();
                handleOpen();
              }}
            >
              <IconReadMore />
              More
            </Link>
          </LinkP>
        </Details>
      </Content>
    </Container>
  );
};

const override = css`
  display: block;
  margin: 0 auto;
`;
const IconRequest = styled(IosShareIcon)`
  vertical-align: bottom;
  padding-right: 2px;
`;
const IconLInk = styled(LinkIcon)`
  vertical-align: bottom;
  padding-right: 2px;
`;
const IconDonwload = styled(DownloadIcon)`
  vertical-align: bottom;
  padding-right: 2px;
`;
const IconReadMore = styled(ReadMoreIcon)`
  vertical-align: bottom;
  padding-right: 2px;
`;
const LinkP = styled.p`
  a {
    padding-right: 10px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
`;

const Icon = styled.div`
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
const DataIcon = styled(DataUsageIcon)`
  vertical-align: bottom;
  @media screen and (max-width: 768px) {
    font-size: 30px !important;
  }
`;
const Details = styled.div`
  width: 70%;
  sub {
    line-height: 0;
    margin-bottom: 5px;

    span {
      font-weight: bold;
      border-right: 2px solid #989393;
      line-height: 0;
      padding: 0 5px 0 0;
      margin: 0 5px 5px 0;
    }
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  height: 100%;
  width: 98%;
  border-radius: 4px;
  margin: 2% auto;
  padding: 10px;
  background: #f8f6f6;
`;
export default DatasetContent;
