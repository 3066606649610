import styled from "styled-components";
import { RichTextEditor } from "./RichTextEditor";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { AXIOS_POST } from "../config/axios";
import { ToastContainer, toast } from "react-toastify";

export const NewDisCussion = (props) => {
  const [richText, setRichText] = useState("");
  const [title, setTitle] = useState("");

  const handleRichText = (value) => {
    setRichText(value);
  };
  const handlePost = (e) => {
    e.preventDefault();
    props.handleCancel();
    console.log(richText);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setRichText("");
    setTitle("");
    props.handleCancel();
    console.log("cancel");
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.content = richText;
    data.dataset_id = props.dataset.code;
    const token = sessionStorage.getItem("token");
    const jsondata = {};

    jsondata["dataset_id"] = data.dataset_id;
    jsondata["title"] = data.title;
    jsondata["content"] = data.content;

    AXIOS_POST("/dataset-discussions", jsondata, token)
      .then((res) => {
        toast.success("Discussion Posted Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
        setTimeout(() => {
          props.handleCancel();
        }, 3000);
      })
      .catch((err) => {
        toast.error("Discussion Posted Error", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
      });
  };
  // console.log(watch("content"));

  return (
    <Container>
      <ToastContainer />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>
          <label htmlFor="title">Title</label>
          <input
            {...register("title", { required: true })}
            id="title"
            type="text"
          />
        </Title>
        <Content>
          <label htmlFor="content">Content</label>
          <RichTextEditor
            // {...register("content", { required: true })}
            value={richText}
            required={true}
            height = "30vh"
            onChange={handleRichText}
          />
        </Content>
        <br /><br /><br /><br />
        <Buttons>
          <Submit className="submit" type="submit">
            Post
          </Submit>
          <Cancel onClick={handleCancel} className="cancel">
            Cancel
          </Cancel>
        </Buttons>
      </Form>
    </Container>
  );
};

const Submit = styled.button`
  padding: 5px 10px;
  margin: 10px;
  border-radius: 8px;
  border: none;
  background: #01944D;
  color: white;
  font-weight: 600;
  cursor: pointer;
`;
const Cancel = styled.button`
  padding: 5px 10px;
  margin: 10px;
  border-radius: 8px;
  border: none;
  background: #f82a2a;
  color: white;
  font-weight: 600;
  cursor: pointer;
`;
const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: start;
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  label {
    width: 10%;
    font-weight: bold;
    padding-top: 10px;
    
  }
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  label {
    width: 10%;
    font-weight: bold;
    padding-top: 10px;
  }
  input {
    width: 90%;
    border: none;
    border-bottom: 1px solid #989393;
    padding: 5px;
    font-size: 16px;
    &:focus {
      outline: none;
    }
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  background: white;
  /* height: auto; */
  min-height: 200px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  height: 100%;
  width: 90%;
  border-radius: 4px;
  /* border: 1px solid #01944D; */
  border-bottom: none;
  margin: 2% auto;
  padding: 10px;
  background: #f8f6f6;
`;
