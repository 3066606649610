import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import UserLayout from "../layouts/UserLayout";
import styled from "styled-components";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import SendIcon from "@mui/icons-material/Send";
import CollapsibleTable from "../components/DatasetTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import { AXIOS_GET } from "../config/axios";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { DATASET } from "../config/url";
import { Link } from "react-router-dom";
import Translate from "../translate";
import DatasetPaginatedTable from "../components/DatasetPaginatedTable";

const Dashboard = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [cursor, setCursor] = useState(null)
  const [hasMore, setHasMore] = useState()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const getUploads = async ()=>{
    const theCursor = cursor ? `?cursor=${cursor}`:""
    setLoading(true);
    const response  = await AXIOS_GET(`${DATASET}/uploads?limit=61${theCursor}`, sessionStorage.getItem("token"))
      .then((response) => {
        setLoading(false);
        // console.log(response.data.paging)
        setHasMore(response.data.paging.hasMore)
        setCursor(response.data.paging.nextCursor)
        setDatasets(
          response.data.data.map((dataset, index) => ({
            id: dataset.code,
            Title: dataset.name,
            Source: dataset.organization,
            Views: dataset.likes? dataset.likes:0,
            Downloads: dataset.downloads? dataset.downloads: 0,
            Requests: dataset.request? dataset.request:0,
            ...dataset,
          }))
        );
      })
      .catch((error) => {
        // console.error(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // Get more data
  const getMoreUploads = async ()=>{
    const theCursor = cursor ? `&cursor=${cursor}`:""
    // setLoading(true);
    const response = await AXIOS_GET(`${DATASET}/uploads?limit=61${theCursor}`, sessionStorage.getItem("token"))
     
    return response
  }


  const getMoreData = async ()=>{
    try{
    const theCursor = cursor ? `&cursor=${cursor}`:""
    // setLoading(true);
    const response  = await AXIOS_GET(`${DATASET}/uploads?limit=61${theCursor}`, sessionStorage.getItem("token"))

    setHasMore(response.data.paging.hasMore)
    setCursor(response.data.paging.nextCursor) 
    setDatasets([...datasets,
      ...response.data.data.map((dataset, index) => ({
        id: dataset.code,
        Title: dataset.name,
        Source: dataset.organization,
        Views: dataset.likes ? dataset.likes: 0,
        Downloads: dataset.downloads ? dataset.downloads : 0,
        Requests: dataset.request ? dataset.request : 0,
        ...dataset,
      }))]
    );
    }catch(error){

    }
    
  }



  useEffect(() => {
    getUploads()
  }, []);
  // console.log(datasets)
  return (
    <UserLayout isDash={true}>
      <Container>
        <DashCards>
          <Card  onClick={()=>{
            history.push('/dash')
          }} type="upload">
          
            <IconUpload style={{ fontSize: "50px" }} />
            <span>
              <Translate Word={"dataset-uploads"} />
            </span>
   
            {/* <span
              style={{
                padding: "none !impo",
              }}
            >
              10
            </span> */}
          </Card>
          <Card 
          onClick={()=>{
            history.push('/requests/recieved')
          }}
          type="sent">
            <IconSend style={{ fontSize: "50px" }} />
            <span>
              {" "}
              <Translate Word={"requests-sent"} />
            </span>
            {/* <span>10,000</span> */}
          </Card>
          <Card 
          onClick={()=>{
            history.push('/requests/sent')
          }}
          type="receive">
            <IconReceive style={{ fontSize: "50px" }} />
            <span>
              {" "}
              <Translate Word={"requests-received"} />
            </span>
            {/* <span>10</span> */}
          </Card>
        </DashCards>
        <TableContainer>
          <Title>
            {!isMobile && 
            <button style={{display:'flex'}} onClick={() => history.push("/upload")}>
              <IconAdd />
              <Translate Word={"post"} />
            </button>}
            {/* <button style={{ background: "#fd1d1d" }}>
              <IconDelete type="delete" />
              Delete
            </button> */}
            <p>
              <Translate Word={"your-datasets"} />
            </p>
          </Title>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            // <DatasetPaginatedTable setCursor={(val)=>{setCursor(val)}} setHasMore={(val)=>{setHasMore(val)}} cursor={cursor}  hasMore={hasMore} getMoreUploads={getMoreUploads} data={datasets} />
            <CollapsibleTable hasMore={hasMore} getMoreData={getMoreData} getUploads={getUploads} data={datasets} />
          )}
        </TableContainer>
      </Container>
    </UserLayout>
  );
};
const IconDelete = styled(DeleteIcon)`
  vertical-align: bottom;
`;
const IconAdd = styled(AddCircleIcon)`
  vertical-align: bottom;
`;
const Title = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  button {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 10%;
    font-family: "Bebas Neue", cursive;
    padding: 5px;
    background: #01944D;
    color: white;
    border: none;
    border-radius: 2px;
    margin-right: 2%;
    cursor: pointer;
  }
  p {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 86%;
    font-family: "Bebas Neue", cursive;
    text-align: center;
    padding-left: 20px;
  }
`;
const TableContainer = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background-color: white;
`;
const IconUpload = styled(CloudUploadIcon)`
  vertical-align: bottom;
`;
const IconSend = styled(SendIcon)`
  vertical-align: bottom;
`;
const IconReceive = styled(CallReceivedIcon)`
  vertical-align: bottom;
`;
const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 1rem;
  background-color: ${(props) =>
    props.type === "upload"
      ? "#f79233"
      : props.type === "sent"
      ? "#5e8b8b"
      : "#6E7C7C"};
  padding: 2rem;
  border-radius: 10px;
  color: white;
  box-shadow: 0 20px 30px -20px rgba(5, 5, 5, 0.24);
  margin: 0 10px;
  cursor: pointer;
  span {
    padding-left: 10px;
    font-family: "Bebas Neue", cursive;
  }
`;
const DashCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Container = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export default Dashboard;
