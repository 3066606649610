import React from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../app/features/languageSlice";
import En from "../ui/dictionary/En";
import Fr from "../ui/dictionary/Fr";

const Translate = ({ Word }) => {
  const languageValue = useSelector(selectLanguage);
  return <>{languageValue === "En" ? En[Word] : Fr[Word]} </>;
};

// Just a function which returns a string. useful incase u weant to use the dic in placeholders
export const transalateToString = (Word, languageValue) => {
  // const languageValue = useSelector(selectLanguage);
  if (languageValue === "En") {
    return En[Word];
  } else {
    return Fr[Word];
  }
};

// A reach text component. To use cautiously
export const RichTextTransalater = ({ Word }) => {
  const languageValue = useSelector(selectLanguage);

  return (
    <p
      dangerouslySetInnerHTML={{
        __html: languageValue === "En" ? En[Word] : Fr[Word],
      }}></p>
  );
};
export default Translate;
