import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GetPublicDataService from "../../services/GetPublicDataService";

const initialState = {
   datasets: [],
   message: "",
   isLoaded: false,
   loading: false,
};

export const getDatasets = createAsyncThunk(
  "datasets/getDatasetes",
  async (args, { rejectWithValue }) => {
    try {
      const pulicData = new GetPublicDataService();
      const response = await pulicData.getPublicData();
      const {data} = response;
      if(data === undefined)
      {
        return {data:[], message:"Something went wrong, try again later"}
      }
      return {data:data, message:""};
    } catch (e) {
      rejectWithValue("Something went wrong!");
    }
  }
);


export const authenticationSlice = createSlice({
  name: "datasets",
  initialState,
  reducers: {
    addDatasets: (state, action) => {
      state.datasets = action.payload;
    },
  },
  extraReducers: {
    [getDatasets.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getDatasets.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.datasets = payload.data;
      state.message = payload.message;
      state.isLoaded = true;
    },
    [getDatasets.rejected]: (state, { payload }) => {
      state.loading = false;
      state.datasets = [];
      state.message = payload;
      state.isLoaded = false;
    },


  },
});

export const { addDatasets } = authenticationSlice.actions;
export default authenticationSlice.reducer;
