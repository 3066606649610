import React, { useState } from "react";
import { getCountry } from "../../../utils/listOfCountries";
import "./search.css";

const Search = (props) => {
  const { setShowFilters, 
    searchHanler, 
    stopSearch, 
    isPrivate, 
    fileType, 
    startDate, 
    endDate, 
    setIsPrivate, 
    setFileType, 
    setStartDate, 
    setEndDate } = props;
  const [possibleMatch, setPossibleMatch] = useState([]);
  const [showAutocomplete, setAutoComplet] = useState(false);
  const [valueToSearch, setValueToSearch] = useState("");
  const [slug, setSlug] = useState("");
 

  const closeAutoComplete = () => {
    setAutoComplet(false);
    setPossibleMatch([]);
  };

  const searchMatch = (text) => {
    if (text.length === 0) {
      closeAutoComplete();
      return;
    }
    const countries = getCountry(text);
    if (countries.length > 0) {
      setAutoComplet(true);
      setPossibleMatch(countries);
    }
  };

  const addSlug = (e) => {
    const slug = e.target.value;
    if (slug.length > 2) {
      setSlug(slug);
      document.getElementById("m-search-slug-error").hidden = true;
    } else {
      document.getElementById("m-search-slug-error").hidden = false;
    }
  };

  
  const submit = () => {
    searchHanler({  slug, isPrivate, startDate, endDate, fileType });
  };

  const resetSearch = () => {
    stopSearch();
    setValueToSearch("");
    document.getElementById("m-search-input").value = "";
    document.getElementById("m-search-slug-error").hidden = true;
    document.getElementById("m-search-selector").value = "W";
    setFileType("all");
    setIsPrivate(false);
    setStartDate();
    setEndDate();
    setSlug("");
    setShowFilters(false)
    // const filtersDiv = document.getElementById("filters");
    // filtersDiv.hidden = true;
    document.getElementById("m-search-form").reset();
  };

  
  const showFiltersHandler = (e) => {
    console.log(e)
    const selected = e.target.value;
    // const filtersDiv = document.getElementById("filters");
    if (selected === "N") {
      // filtersDiv.hidden = false;
      setShowFilters(true)
    } else {
      setShowFilters(false)
      // filtersDiv.hidden = true;
    }
  };

  return (
    <form id="m-search-form" className="m-searchbar">
      <div className="m-group ">
        <div className="input-group search-compo">
          <div className="input-group-btn " data-search="students">
            <select
              id="m-search-selector"
              onChange={showFiltersHandler}
              className="btn btn-default dropdown-toggle m-dropdown"
              data-toggle="dropdown"
            >
              <option value="W">No Filters</option>
              <option value="N">With Filters</option>
            </select>
          </div>
          <input
            id="m-search-input"
            type="text"
            onChange={(e) => {
              addSlug(e);
            }}
            className="form-control"
            name="x"
            placeholder="Search term..."
          />
          <span className="input-group-btn">
            <button
              onClick={resetSearch}
              style={{ width: 20 }}
              className="m-search-clear"
              type="button"
            >
              &times;
            </button>
          </span>
          <span className="input-group-btn">
            <button
              onClick={submit}
              className="btn btn-default m-search"
              type="button"
            >
              SEARCH
            </button>
          </span>
        </div>
      </div>
      
      <p hidden style={{backgroundColor:'#fff', paddingLeft:10, paddingRight:10}} id="m-search-slug-error">
        Searh needs at least 2 characters ...
      </p>

      {/* <div hidden id="filters" className="m-search-filters">
        <div className="col-md-3 search-filter-block">
          <label className="label" htmlFor="">
            IS PRIVATE
          </label>
          <input
            onChange={chekIfPrivate}
            type="checkbox"
            name=""
            id="isPrivate"
          />
        </div>

        <div className="col-md-4">
          <p style={{ color: "black", textAlign: "left" }}>FILE TYPE:</p>
          <div className="m-search-radio-container">
            <div className="m-search-radio-group">
               {" "}
              <label className="label" htmlFor="javascript">
                ALL TYPES
              </label>
              <input
                className="m-search-radio-box"
                type="radio"
                name="fileType"
                onClick={() => setFileType("all")}
              />
            </div>
            <div className="m-search-radio-group">
               {" "}
              <label className="label" htmlFor="html">
                FILE
              </label>
              <input
                className="m-search-radio-box"
                type="radio"
                name="fileType"
                onClick={() => setFileType("file")}
              />
            </div>
             {" "}
            <div className="m-search-radio-group">
               {" "}
              <label className="label" htmlFor="css">
                LINK
              </label>
               {" "}
              <input
                className="m-search-radio-box"
                type="radio"
                onClick={() => setFileType("link")}
                name="fileType"
              />
            </div>
          </div>
        </div>
        <div className="col-md-5 m-search-dates-container">
          <label className="label" htmlFor="startDate">
            Start date:
          </label>
          <input
            onChange={addStartDate}
            className="m-search-dates-input"
            type="date"
            id="startDate"
            name="startDate"
          />
          <label className="label" htmlFor="endDate">
            End date:
          </label>
          <input
            onChange={addEndDate}
            className="m-search-dates-input"
            type="date"
            id="endDate"
            name="endDate"
          />
        </div>
      </div> */}

      {/* {showAutocomplete && <div className="m-search-autoComplete"><AddItem/></div>} */}
    </form>
  );
};

export default Search;
