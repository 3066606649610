import React, { useState } from "react";
// import "./datasetCard.css";
import GradeIcon from "@mui/icons-material/Grade";
import FavoriteIcon from "@mui/icons-material/Favorite";
import styled from "styled-components";
import Translate from "../translate";
import DatasetView from "./Datasetview";
import { AXIOS_PATCH } from "../config/axios";
import { DATASET } from "../config/url";
const DatasetCard = (props) => {
  const [likes, setLikes] = useState(props.data.likes);
  const [views, setViews] = useState(props.data.views);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    AXIOS_PATCH(
      `${DATASET}/${props.data.code}/views`,
      {},
      sessionStorage.getItem("token")
    )
      .then((data) => {
        setViews((prev) => prev + 1);
        // console.log("View recorded");
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  const handleLikes = () => {
    setLikes((prev) => prev + 1);
    AXIOS_PATCH(
      `${DATASET}/${props.data.code}/likes`,
      {},
      sessionStorage.getItem("token")
    )
      .then((data) => {
        // console.log("Likes recorded");
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  const handleClose = () => setOpen(false);
  return (
    <Container className="data-card">
      <DatasetView
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        data={props.data}
      />
      <DataHeader className="data-header" onClick={handleOpen}>
        <div>
          <DataTitle className="data-title" href="#">
            {props.title}
          </DataTitle>
          <DataName className="data-name">
            <Source>
              <SourceTitle>Source</SourceTitle>
              <SourceType>Public</SourceType>
            </Source>
            <span style={{ lineHeight: 0 }}>
              <Translate Word={"by"} /> {props.author}
            </span>
          </DataName>
        </div>
      </DataHeader>
      <DataDesc className="data-description">{props.desc}</DataDesc>
      <DataPostInfo className="data-post-info">
        <DataCr className="data-cr">
          <DataDt className="data-dt">
            <Translate Word={"published"} />
          </DataDt>
          <DataDd className="data-dd">{props.published}</DataDd>
        </DataCr>
        <DataCr className="data-cr">
          <DataDt className="data-dt">
            <Translate Word={"views"} />
          </DataDt>
          <DataDd className="data-dd">{views}</DataDd>
        </DataCr>
        <DataCr className="data-cr">
          <DataDt className="data-dt">
            <Likes onClick={handleLikes} />
          </DataDt>
          <DataDd className="data-dd">
            {likes} <Translate Word={"likes"} />
          </DataDd>
        </DataCr>
      </DataPostInfo>
    </Container>
  );
};
const Container = styled.div`
  width: 320px;
  max-height: 420px;
  min-height:420px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 1rem;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 20px 30px -20px rgba(5, 5, 5, 0.24);
  margin: 10px 10px;
  height: 100%; /* added */
  flex: 1; /* added */
  flex-basis: auto; /* added */
`;
const DataHeader = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  text-align: center;
  grid-gap: 1rem;
  gap: 1rem;
`;
const DataTitle = styled.p`
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: rgba(17, 24, 39, 1);
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline royalblue;
  }
`;
const DataName = styled.p`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  /* font-weight: 500; */
  color: #01944D;
  font-weight: bold;
`;
const DataDesc = styled.p`
  font-size: 15px;
  font-weight: 400;
  max-height:120px;
  overflow:hidden;
  line-height: 1.25rem;
  color: rgba(107, 114, 128, 1);
`;
const DataPostInfo = styled.dl`
  margin-top: 1.5rem;
  display: flex;
  align-items: end;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
`;
const DataCr = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;
const DataDt = styled.dt`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: rgba(75, 85, 99, 1);
  display:flex;
  aligne-items:center;
  justify-content:center;
  &:hover {
    cursor: pointer;
    color: #01944D;
  }
`;
const DataDd = styled.dd`
  font-size: 0.75rem;
  line-height: 1rem;
  color: #01944D;
  text-align: center;
`;
const Likes = styled(FavoriteIcon)`
  font-size: 20px;
  vertical-align: bottom;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: #01944D;
  }
`;
const Source = styled.p`
  line-height: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const SourceTitle = styled.span`
  color: rgba(75, 85, 99, 1);
  font-weight: bold;
  border-right: 2px solid rgba(75, 85, 99, 1);
  padding-right: 4px;
`;
const SourceType = styled.span`
  padding-left: 4px;
  text-transform: capitalize;
  text-transform: uppercase;
  color: #01944D;
`;
export default DatasetCard;
