import {
  DataGrid,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import React from 'react';
import styled from 'styled-components';

import RequestSearch from './RequestSearch';
import RequestsTabs from './RequestsTabs';
import { Box } from '@mui/system';
import SentRequestsTabs from './SentRequestsTabs';
import CircularProgress from '@mui/material/CircularProgress';
import MuiPagination from '@mui/material/Pagination';
import EmptyRowsOverlay from './EmptyRow';





const RequestTable = ({
  columns,
  rows,
  type,
  loading,
  status,
  handleTabChange,
  getMoreData,
  hasMore,
  loadingPagination

}) => {


  function Pagination({
    page,
    onPageChange,
    className,
  }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <MuiPagination
        // color="#db0718"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={async (event, newPage) => {
          
          onPageChange(event, newPage - 1);
          if(pageCount === (newPage) & hasMore){
            await getMoreData()
          }

        }}
      />
    );
  }
  
  function CustomPagination(props) {
      return <GridPagination ActionsComponent={Pagination} {...props} />;
  }


  return (
    <Container>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : !rows ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h2>No Requests</h2>
        </Box>
      ) : (
        <>
          {/* <SearchContainer>
            <RequestSearch />
          </SearchContainer> */}

          {type === 'sent' ? (
            <SentRequestsTabs
              status={status}
              handleTabChange={handleTabChange}
            />
          ) : (
            <RequestsTabs status={status} handleTabChange={handleTabChange} />
          )}

          <DataGrid
            style={{minHeight:300}}
            loading={loadingPagination}
            rows={rows}
            columns={columns}
            autoHeight
            pagination
            slots={{
              pagination: CustomPagination,
              // noRowsOverlay: EmptyRowsOverlay
            }}
            sx={{ '--DataGrid-overlayHeight': '300px' }}

            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            // checkboxSelection
            idsableRowSelectionOnClick
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 90%;
  margin-top: 20px;
`;

const SearchContainer = styled.div`
  width: 70%;
  padding: 2rem 0.5rem;
`;

export default RequestTable;
