import { Redirect, Route } from "react-router-dom";
import { isAdmin } from "../utils/authorizations";

const AdminProtectedRoute = (props) => {
  const isLoggedIn = isAdmin();
  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: "/admin",
          state: { message: "What you are requesting is reserved for the website administrators, please login to gain access.", next: props.path },
        }}
      />
    );
  }
  return <Route {...props} />;
};

export default AdminProtectedRoute;
