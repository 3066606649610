import AdminApiExport from "./ApiAdmin";

/**
 * Req : {data:{token:$VALUE}, cursor:$VALUE}
 */
export const getVerificationRequests = async (req) =>{
    req.data.url = '/officialize?nextCursor='+req.cursor+"&status="+req.status
    const response = await AdminApiExport.getRequest(req)
    return response;
}

export const getAllAccounts = async (req)=>{
    req.data.url = "/users?nextCursor="+req.cursor
    const resopnse = await AdminApiExport.getRequest(req)
    return resopnse
}

export const getAccount = async (req)=>{
    req.data.url = "/users/profile/"+req.user_id
    const response = await AdminApiExport.getRequest(req)
    return response
}

/**
 * 
 * @param {user_id:STRING, data:{token:$VALUE, activate:BOOLEAN}} req 
 * @returns ANY
 */
export const activateAccount = async (req)=>{
    req.data.url = "/users/activate/"+req.user_id
    const response = await AdminApiExport.patchRequest(req)
    return response
}

/**
 * 
 * @param {user_id:STRING, data:{token:$VALUE, verified:BOOLEAN}} req 
 * @returns ANY
 */
export const verifyAccount = async(req)=>{
    req.data.url = "/users/verify/"+req.user_id
    const response = await AdminApiExport.patchRequest(req)
    return response
}

/**
 * 
 * @param {user_id:STRING, data:{token:$VALUE, status:BOOLEAN}} req 
 * @returns ANY
 */
export const officializeAccount = async(req)=>{
    req.data.url = "/officialize/"+req.req_id
    const response = await AdminApiExport.patchRequest(req)
    return response
}
