import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";
import DatasetView from "./Datasetview";
import { useEffect } from "react";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, hasNext } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    console.log(`This is has next ${hasNext}`);
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    // fetch data from the server if there is
    // console.log(`This is has next ${hasNext}`);
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



const IconMore = styled(ReadMoreIcon)`
  vertical-align: bottom;
  cursor: pointer;
  color: #fa8518;
  font-weight: bold;
`;
const Container = styled.div`
  height: 400px;
  width: 90%;
  margin-top: 20px;
`;
export default function DatasetPaginatedTable({
  hasMore,
  getMoreUploads,
  data,
  setHasMore,
  setCursor
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [displayData, setDisplayData] = useState([])
  const [loadMoreData, setLoadMoreData] = useState(false)

  useEffect(()=>{
    setDisplayData(data)
  },[data])


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - displayData.length) : 0;

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    try{
    const lastPage = Math.ceil(displayData.length / rowsPerPage) - 1;

    if (lastPage <= newPage & hasMore) {
      
      setLoading(true);
      const response = await getMoreUploads();
      setDisplayData([...displayData, ...response.data.data])
      setCursor(response.data.paging.nextCursor)
      setHasMore(response.data.paging.hasMore)
      setLoading(false)
    }
  }catch(e){
    // console.log(e)
    setLoading(false)


  }

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = (rowData) => {
    // console.log("_______________________________________", displayData);
    setRowData(rowData);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRowData(null);
  };
  const getRowId = (row) => row.id;
  // console.log(data)
  if (rowData) {
    return (
      <Container>
        <DatasetView
          open={open}
          getRowId={getRowId}
          handleClose={handleClose}
          handleOpen={handleOpen}
          data={rowData}
          isAuthor={true}
        />
      </Container>
    );
  }else{
  return (
    // <Container >
    <TableContainer style={{ padding: 50 }}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ width: "40%" }}
              style={{
                backgroundColor: "#fff",
                fontWeight: "bold",
                fontSize: 14,
                textTransform: "uppercase",
              }}
            >
              Title
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#fff",
                fontWeight: "bold",
                fontSize: 14,
                textTransform: "uppercase",
              }}
            >
              Source
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#fff",
                fontWeight: "bold",
                fontSize: 14,
                textTransform: "uppercase",
              }}
            >
              Views
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#fff",
                fontWeight: "bold",
                fontSize: 14,
                textTransform: "uppercase",
              }}
            >
              Downloads
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#fff",
                fontWeight: "bold",
                fontSize: 14,
                textTransform: "uppercase",
              }}
            >
              Requests
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#fff",
                fontWeight: "bold",
                fontSize: 14,
                textTransform: "uppercase",
              }}
            >
              More
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
            ? displayData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : displayData
          ).map((row) => (
            <TableRow key={row.id}>
              <TableCell scope="row">{row.name}</TableCell>
              <TableCell style={{ width: 160 }} align="right">
                {row.organization}
              </TableCell>
              <TableCell style={{ width: 160 }} align="right">
                {row.Views}
              </TableCell>
              <TableCell scope="row">{row.Downloads}</TableCell>
              <TableCell style={{ width: 160 }} align="right">
                {row.Requests}
              </TableCell>
              <TableCell style={{ width: 160 }} align="right">
                <IconMore onClick={() => handleOpen(row)} />
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
          {loading && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell align="right" colSpan={6}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              //   colSpan={3}
              count={displayData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    // </Container>
  );
            }
}
