import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Table } from "react-bootstrap";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import CustomSearchBar from "../CustomSearchBar";

const DatasetsList = (props) => {
  const originalData = props.datasets;
  const [allDatasets, setAllDatasets] = useState([]);
  const loading = props.loading;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allDatasets.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  useEffect(() => {
    setAllDatasets(originalData);
  }, []);

  const getDate = (dateIn) => {
    let date = new Date(dateIn);
    var options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <TableContainer style={{ padding: 10 }}>
      <h3>Datasets</h3>
      <hr />
      {loading ? (
        <div>Loading ...</div>
      ) : (
        <Paper style={{padding:5}}>
          <CustomSearchBar
            displayedList={allDatasets}
            filterAttribs={"name"}
            originalList={originalData}
            changeDisplayList={(list) => {
              setAllDatasets(list);
            }}
          />
          <br/>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "#fff" }}>Title</TableCell>
                <TableCell style={{ color: "#fff" }}>Date uploaded</TableCell>
                <TableCell style={{ color: "#fff" }}>Source</TableCell>
                <TableCell hidden style={{ color: "#fff" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? allDatasets.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : allDatasets
              ).map((row) => (
                <TableRow
                  key={row.code}
                  // sx={{ '&:last-child td, &:last-child td': { border: 0 } }}
                >
                  <TableCell
                    style={{ textTransform: "capitalize" }}
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell>{getDate(row.createdAt)}</TableCell>
                  <TableCell>{row.organization}</TableCell>
                  <TableCell hidden>
                    <Button
                      onClick={() => {}}
                      className="mt-btn"
                      variant="outlined"
                    >
                      More details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  count={allDatasets.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      )}
    </TableContainer>
  );
};

export default DatasetsList;
