import React, { useState, useEffect } from "react";
import Header from "../layouts/header";
import styled from "styled-components";
import RefreshIcon from "@mui/icons-material/Refresh";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Chip, Typography } from "@mui/material";
import Footer from "../layouts/footer";
import DatasetDetails from "./DatasetDetails";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import Translate from "../translate";
import DatasetIcon from "@mui/icons-material/Dataset";
import CommentIcon from "@mui/icons-material/Comment";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useHistory } from "react-router-dom";
import { Discussion } from "./Discussion";
import { NewDisCussion } from "./new-discussion";
import { useLoginBox } from "../../../helper/authHelper";

const DatasetView = ({ open, handleClose, data, isAuthor, setSearchValue }) => {
  // console.log("DatasetView", data.likes);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [discussion, setDiscussion] = useState(false);
  const { showModel, modelComponent, open2, handleLoginBox } = useLoginBox();

  const handleLikes = () => {
    data.likes = data.likes + 1;
  };

  const handleAddDicusssion = () => {
    if (!sessionStorage.getItem("token")) {
      // alert("Please login to initiate discussion");
      handleLoginBox(true);
    } else {
      setDiscussion(!discussion);
    }
  };
  const serachTag = (value) => {
    setSearchValue(value);
    handleClose();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling
    });
  };

  useEffect(() => {
    // console.log("DatasetView", data.likes);
  }, []);

  return (
    <ModelContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <FilterPage>
          {/* <Header /> */}
          <DataSection>
            <FilterContainer>
              <h6>
                {/* <span>About Dataset</span>0 */}
                <span>
                  <Translate Word="about-dataset" />
                </span>
                <Reset>
                  <IconCLose onClick={handleClose} fontSize="20px" />
                </Reset>
              </h6>
              <AboutDataset>
                <p>
                  <sub className="about-title">
                    <Translate Word="accessibility" />
                  </sub>{" "}
                  <span className="about-content">
                    {data.is_private ? "Private" : "Publlic"}
                  </span>
                </p>
                <p>
                  <sub className="about-title">
                    <Translate Word="created" />
                  </sub>{" "}
                  <span className="about-content">{data.createdAt}</span>
                </p>
                <p>
                  <sub className="about-title">
                    <Translate Word="updated" />
                  </sub>{" "}
                  <span className="about-content">{data.updatedAt}</span>
                </p>
                <p>
                  <sub className="about-title">
                    <Translate Word="author" />
                  </sub>{" "}
                  <span className="about-content">{data.creator.name}</span>
                </p>
                <p>
                  <sub className="about-title">Views</sub>{" "}
                  <span className="about-content">{data.views}</span>
                </p>
                <p>
                  <sub className="about-title">Likes</sub>{" "}
                  <span className="about-content">{data.likes}</span>
                </p>
                <p>
                  <sub className="about-title">
                    <Translate Word="downloads" />
                  </sub>{" "}
                  <span className="about-content">{data.downloads}</span>
                </p>
                <p>
                  <sub className="about-title">Tags</sub>{" "}
                </p>
                <p>
                  <span className="about-content">
                    {data.tags &&
                      data.tags.map((e) => {
                        if (!e) {
                          return;
                        }
                        if (e.split(" ").length < 4) {
                          return (
                            <Chip
                              onClick={() => {
                                serachTag(e);
                              }}
                              style={{ margin: 3 }}
                              label={e}
                              variant="outlined"
                            />
                          );
                        }
                      })}{" "}
                  </span>
                </p>
                {isAuthor ? (
                  <p style={{ marginTop: "5px", color: "#01944D" }}>
                    <sub
                      style={{ marginTop: "10px", color: "#01944D" }}
                      className="about-title"
                    >
                      Requests
                    </sub>{" "}
                    <span
                      style={{ marginTop: "10px", color: "#01944D" }}
                      className="about-content"
                    >
                      {data.request}{" "}
                    </span>
                  </p>
                ) : (
                  ""
                )}
              </AboutDataset>
            </FilterContainer>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              
              <DataSetContainer>
                <ResetMobile>
                  <IconCLose onClick={handleClose} fontSize="20px" />
                </ResetMobile>
                <Tabs>
                  <TabLink
                    onClick={() => setActiveTab(1)}
                    active={activeTab === 1}
                  >
                    <IconDataset></IconDataset><Translate Word={"dataset-details-tab"} />
                  </TabLink>
                  <TabLink
                    onClick={() => setActiveTab(2)}
                    active={activeTab === 2}
                  >
                    <IconComment></IconComment>Discussions
                  </TabLink>
                </Tabs>
                {activeTab === 1 ? (
                  <>
                    {" "}
                    <h6>{data.name}</h6>
                    <DatasetDetails
                      isAuthor={isAuthor}
                      key={data.code}
                      data={data}
                      handleLikes={handleLikes}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <DiscusionHeader>
                      <h6>Discussions</h6>
                      <p onClick={handleAddDicusssion}>
                        {discussion ? "Close" : <Translate Word={"discussion-new-discussion"} />}
                      </p>
                    </DiscusionHeader>
                    {/* <Discussion dataset={data} /> */}
                    {discussion ? (
                      <NewDisCussion
                        user={localStorage.getItem("user")}
                        dataset={data}
                        handleCancel={handleAddDicusssion}
                      />
                    ) : (
                      <Discussion
                        dataset={data}
                        user={localStorage.getItem("user")}
                        handleCancel={handleAddDicusssion}
                      />
                    )}
                  </>
                )}
              </DataSetContainer>
            )}
          </DataSection>
          <Footer />
        </FilterPage>
      </Fade>
    </ModelContainer>
  );
};

const DiscusionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  p {
    background: white;
    font-weight: bold;
    border: 2px solid #01944D;
    border-radius: 4px;
    padding: 2px;
    cursor: pointer;
    &:hover {
      background: #01944D;
      color: white;
    }
  }
`;
const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 100%;
`;
const TabLink = styled.span`
  ${(props) =>
    props.active
      ? `border-bottom: 2px solid #01944D;`
      : `border-bottom: 2px solid #f8f6f6;`}
  padding: 10px;
  cursor: pointer;
  /* color: #01944D !important; */
`;
const ModelContainer = styled(Modal)`
  position: absolute;
  top: 0;
  z-index: 1;
`;
const IconDataset = styled(DatasetIcon)`
  vertical-align: bottom;
  color: #01944D;
  padding-right: 2px;
`;
const IconComment = styled(CommentIcon)`
  vertical-align: bottom;
  color: #01944D;
  padding-right: 2px;
`;
const IconBookmark = styled(BookmarkIcon)`
  vertical-align: bottom;
`;
const IconCLose = styled(CloseIcon)`
  font-size: 20px;
  vertical-align: bottom;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: #01944D;
  }
`;
const Likes = styled(FavoriteIcon)`
  font-size: 20px;
  vertical-align: bottom;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: #01944D;
  }
`;
const FilterPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
`;
const DataSection = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  background-color: #f8f6f6;
  border-radius: 4px;
  opacity: 1;
  margin: 3%;
  overflow-y: auto;
  height: 90vh;
  width: 94%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
`;
const FilterContainer = styled.div`
  border-top: 2px solid #01944D;
  width: 35%;
  margin: 2%;
  min-height: 400px;
  background: white;
  border-radius: 4px;
  h6 {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    span {
      font-size: larger;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const DataSetContainer = styled.div`
  width: 65%;
  margin: 2%;
  background: white;
  min-height: 400px;
  border-radius: 4px;
  h6 {
    text-align: left;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
  }
  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
    height: 100vh;
  }
`;
const Reset = styled.span`
  color: #01944D;
  font-weight: bold;
  cursor: pointer;
`;
const ResetMobile = styled.span`
  color: #01944D;
  font-weight: bold;
  cursor: pointer;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
  `
const ReIcon = styled(RefreshIcon)`
  vertical-align: bottom;
  font-size: 20px;
`;
const AboutDataset = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  padding: 20px;
  p {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    sub {
      color: rgb(153, 148, 148);
      flex: 1;
      font-size: 16px;
    }
    span {
      flex: 1;
    }
  }
`;
export default DatasetView;
