import { React, useEffect, useState } from "react";
import "./page.css";
import Header from "../layouts/header";
import Translate from "../translate";
import styled from "styled-components";
import Footer from "../layouts/footer";
import { AXIOS_GET } from "../config/axios";
import { GET_LANDING } from "../config/url";
import ContentLoader, { Facebook } from "react-content-loader";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import AppLayout from "../layouts/AppLayout";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AXIOS_GET(GET_LANDING)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <AppLayout>
      <AboutContainer>
        <DataIcon style={{ fontSize: "200px" }} />
        <div className="left item" style={{ flex: 1 }}>
          <h1 style={{ fontWeight: "bold" }}>OPEN DATA PORTAL</h1>
          {loading ? (
            <ContentLoader>
              <rect x="20" y="17" rx="4" ry="4" width="300" height="13" />
              <rect x="20" y="40" rx="3" ry="3" width="250" height="10" />
            </ContentLoader>
          ) : (
            <p>Open Data Portal <Translate Word={"open-data-description"} />
            </p>
          )}
        </div>
      </AboutContainer>
    </AppLayout>
  );
};
const DataIcon = styled(DataUsageIcon)`
  vertical-align: bottom;
  color: #01944D;
  padding-right: 40px;
`;
const AboutContainer = styled.div`
  background: #e1dfdf;
  width: 100%;
  height: calc(100vh - 360px);
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;
    height: auto;
  }
`;
export default AboutUs;
