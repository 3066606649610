import Api from "./Api";

class GetPublicDataService extends Api {
  getYourRequests =(user, status)=>{
    const data = {
      data:{
        url:'/dataset/owners/'+status,
        token:user.token
      }
    }

    const response = this.getRequest(data)

    return response;
  }

  getStatisticsData=(token)=>{
    const data ={
      data:{
        url:'/dashboard/all-numbers',
        token:token
      }
    }
    const response = this.getRequest(data)

    return response;
  }

  getRequestsYouMade=(user, status)=>{
    const data = {
      data:{
        url:'/dataset/requesters/'+status,
        token:user.token
      }
    }

    const response = this.getRequest(data)

    return response;
  }
  getPublicData = () => {
    const data = {
      data: {
        url: "/data",
      },
    };
    const response = this.getRequest(data)

    return response;
  };

  getSingleDataset = (id)=>{
    const data = {
      data: {
        url: `/data/details/${id}`,
      },
    };
    const response = this.getRequest(data)
    // console.log(response)

    return response;
  }

  getUserUploads = (user) =>{
      const data = {
        data: {
          url: "/data/uploads",
          token:user.token
        },
      };
      const response = this.getRequest(data);
      return response;
  };

  getAllData = (user) =>{
    const data = {
      data: {
        url: "/data",
        token: user.token,
      },
    };
    const response = this.postRequest(data);
    return response;
  }
}

export default GetPublicDataService;
