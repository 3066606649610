import React, { useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const PasswordTextField = ({
  label,
  placeholder,
  value,
  onChange,
  required,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <TextField
      required={required}
      type={showPassword ? "text" : "password"}
      onChange={handleChange}
      label={label}
      placeholder={placeholder}
      size="medium"
      color="warning"
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        style: {
          borderColor: "#01944D",
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePassword} edge="end">
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default PasswordTextField;
