import React, { useState, useEffect } from "react";
import Header from "../layouts/header";
import styled from "styled-components";
import Footer from "../layouts/footer";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Login from "./login";

const CustomModel = ({ open, handleOpen, handleClose, data }) => {
  return (
    <ModelContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Container>
          <DataSection>{data}</DataSection>
          <Footer />
        </Container>
      </Fade>
    </ModelContainer>
  );
};
const ModelContainer = styled(Modal)`
  position: absolute;
  top: 0;
  z-index: 1;
`;
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const DataSection = styled.div`
  position: relative;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  border-radius: 4px;
  opacity: 1;
  margin: 3%;
  height: 90vh;
  width: 94%;
`;

export default CustomModel;
