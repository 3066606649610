import React, { useState } from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { POST_REGISTER_URL } from "../config/url";
import { AXIOS_POST } from "../config/axios";
import Translate, { transalateToString } from "../translate";
import Loader from "../../../helper/Loader";
import ErrorMessage from "./ErrorMessage";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../app/features/languageSlice";


const Register = ({ handleClose, handleOtp, handleLogin }) => {
  const history = useHistory();
  const languageValue = useSelector(selectLanguage);

  let requestError = "Something went wrong";
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // Add your validation rules here
    defaultValues: {
      type: "",
      affiliation: "",
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    criteriaMode: "all",
    resolver: async (data) => {
      // Regular expressions for email and password validation
      const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const PasswordRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$*%^&+=]).{8,}$/;

      // Function to check if a string is empty or has length less than 5
      function isMinLength(value, minLength) {
        return typeof value === "string" && value.length >= minLength;
      }

      // Initialize errors and validData objects
      const errors = {};
      const validData = {};

      // Validate name and affiliation (must be required and have a min length of 5 characters)
      if (!isMinLength(data.name, 2)) {
        errors.name = "Invalid name";
      } else {
        validData.name = data.name;
      }

      if (!isMinLength(data.affiliation, 1)) {
        errors.affiliation = "Invalid affiliation";
      } else {
        validData.affiliation = data.affiliation;
      }

      // Validate email using EmailRegex
      if (!EmailRegex.test(data.email || "")) {
        errors.email = "Invalid email.";
      } else {
        validData.email = data.email;
      }

      // Validate password and confirmPassword (must match and follow PasswordRegex)
      const password = data.password || "";
      const confirmPassword = data.confirmPassword || "";

      if (password !== confirmPassword) {
        errors.password = "Passwords do not match.";
      } else if (!PasswordRegex.test(password)) {
        errors.password =
          "Password must be at least 8 characters long and contain at least one digit, one lowercase letter, one uppercase letter, and one special character (@#$%^&+=).";
      } else {
        validData.password = password;
        validData.confirmPassword = confirmPassword;
      }

      // Return the result
      if (Object.keys(errors).length > 0) {
        return { values: {}, errors };
      } else {
        return { values: data, errors: {} };
      }
    },
  });

  const onSubmit = (data) => {
    // console.log("onSubmit", data);
    setLoading(true);
    AXIOS_POST(POST_REGISTER_URL, data)
      .then((res) => {
        localStorage.setItem("user", data.email);
        handleOtp(data.email);
      })
      .catch((error) => {
        console.log(error)
        setLoading(false);
        const message = error.response? String(error.response.data.message) : "Something went wrong! Please try again later."
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <ToastContainer />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Title>
          <Translate Word={"signup"} />
          <p style={{ fontSize: "16px", fontWeight: "normal" }}>
            <Translate Word={"get-started-register"} />
          </p>
        </Title>

        <InputContainer>
          <FormControl>
            <label htmlFor="account"><Translate Word="registration-form-label-account-type"/></label>
            <select required {...register("type")}>
              <option value="" selected disabled>
                {/* Account type */}
                {transalateToString("registration-form-label-account-type", languageValue)}
              </option>
              <option value="individual">{transalateToString("registration-form-value-account-type-individual", languageValue)}</option>
              <option value="organization">{transalateToString("registration-form-value-account-type-organization", languageValue) }</option>
            </select>
          </FormControl>
          <FormControl>
            <label htmlFor="account"><Translate Word="registration-form-label-affiliation" /></label>
            <input
              type="text"
              placeholder={transalateToString("registration-form-label-affiliation", languageValue)}
              {...register("affiliation")}
            />
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl>
            {errors.type && <ErrorMessage message={errors.type} />}
          </FormControl>
          <FormControl>
            {errors.affiliation && (
              <ErrorMessage message={errors.affiliation} />
            )}
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl>
            <label htmlFor="account"><Translate Word="registration-form-label-full-name" /></label>
            <input
              type="text"
              placeholder={transalateToString("registration-form-label-full-name", languageValue)}
              required
              {...register("name")}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="account"><Translate Word="registration-form-label-email" /></label>
            <input
              type="email"
              placeholder={transalateToString("registration-form-label-account-email", languageValue)}
              required
              {...register("email")}
            />
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl>
            {errors.name && <ErrorMessage message={errors.name} />}
          </FormControl>
          <FormControl>
            {errors.email && <ErrorMessage message={errors.email} />}
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl>
            <label htmlFor="account"><Translate Word="registration-form-label-password" /></label>
            <input
              type="password"
              placeholder={transalateToString("registration-form-label-password", languageValue)}
              required
              {...register("password")}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="account"><Translate Word="registration-form-label-confirm-password"/></label>
            <input
              type="password"
              placeholder={transalateToString("registration-form-label-confirm-password", languageValue)}
              required
              {...register("confirmPassword")}
            />
          </FormControl>
        </InputContainer>

        <InputContainer>
          <FormControl>
            {/* <li hidden id="error-digit" style={{fontSize:13, color:'red'}}>Atleast one special character ($#@&*^...)</li> */}
            {errors.password && <ErrorMessage message={errors.password} />}
          </FormControl>
          <FormControl>
            {errors.confirmPassword && (
              <ErrorMessage message={errors.confirmPassword} />
            )}
          </FormControl>
        </InputContainer>

        <ButtonContainer>
          <SubmitButton>
            {loading ? <Loader color="white" /> : <Translate Word={"signup"} />}
          </SubmitButton>
          <CancelButton type="button" onClick={handleClose}>
            <Translate Word={"cancel"} />
          </CancelButton>
        </ButtonContainer>
        <SignupText>
          <p>
            <Translate Word={"already-have-account"} />?{" "}
            <span onClick={handleLogin}>
              <Translate Word={"signin-here"} />
            </span>
          </p>
        </SignupText>
      </FormContainer>
    </>
  );
};

const FormContainer = styled.form`
  width: 600px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  transition: 0.4s ease-in-out;
  position: relative;
  margin: 20px;
  padding: 20px;

  &:hover {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    scale: 0.99;
  }
  svg {
    position: relative;
    align-self: center;
    top: 4em;
  }
`;
const Title = styled.p`
  position: relative;
  text-align: center;
  color: black;
  top: 1em;
  font-weight: bold;
`;
const InputContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  gap: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
const FormControl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 50%;
  padding: 1% 2%;
  gap: 0;
  input,
  select {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 3px;
    resize: vertical;
    padding: 0.3em 0;
    margin: 0; /* Remove margin */
    background-color: rgb(235, 235, 235);
    padding-left: 15px;
    transition: 0.4s ease-in-out;
    &:hover {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
      background-color: lightgrey;
    }
    &:focus {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
      background-color: lightgrey;
    }
  }
  label {
    margin: 0;
    color: #01944D;
    font-family: "Bebas Neue", cursive;
    font-size: 15px;
  }
`;
const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  top: 0.5em;
  width: 100%;
  margin: 2%;
  justify-content: center;
  align-items: center;
`;
const SubmitButton = styled.button`
  position: relative;
  background-color: #01944D;
  margin-right: 13px;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;
  cursor: pointer;

  &:active {
    box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
  }
`;
const CancelButton = styled.button`
  margin-left: 13px;
  background-color: #989393;
  position: relative;
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;
  cursor: pointer;
  &:active {
    box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
  }
`;
const SignupText = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0px 20px;
  align-items: center;
  margin: 0;
  p {
    line-height: 0%;
  }
  p span {
    line-height: 1;
    color: #01944D;
    cursor: pointer;
    border-bottom: 1px solid #01944D;
  }
`;
export default Register;
