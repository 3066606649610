import React, { useEffect, useState } from "react";
import Footer from "../../../components/new_components/footer/footer";
import Messages from "../../../components/new_components/messages_bar/messages";
import NavBar from "../../../components/new_components/navbar/NavBar";
import "./dashboard.css";
import CustomTable from "../../../components/new_components/custom_table/CustomTable";
import SideNav from "../sidenav/Sidenav";
import DashboarNav from "./dashbaordNav";

const AdminDashboard = (props) => {

  /**
   * 
   * To add a screen 
   * 1. add a <li> to the nav 
   * 2. add the screen in the screens state for navigation
   * 3. Create a component and add it to the <DashboardNav/>
   */
  

  const [screens, setScreens] = useState([
    { id: 1, page: "accounts", active: true },
    { id: 2, page: "datasets", active: false },
    { id: 3, page: "datasets_requests", active: false },
    { id: 4, page: "verifications", active: false},
  ]);
  // const navigate =(id)=>{
  //   console.log(screens)
  //     setScreens(screens.map((screen)=>{
  //       if(screen.id === id){
  //         screen.active = true
  //       }else{
  //         screen.active = false
  //       }
  //       return screen
  //     }))
  //   console.log(screens)
  // }
  // console.log(uploads)
  
  return (
    <div >
      <NavBar hideBarner={true} showAdminLogout={true} hideAllLinks={true}  />
      <hr style={{margin:0}} />
      <div className="admin-body">
        <div className="admin-side-nav">
          <SideNav screens={screens} setScreens={(screens)=>{setScreens(screens)}} />
        </div>
        <div className="admin-main-content">
          <DashboarNav page={screens.filter(screen=>screen.active)[0].page} />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
