export const isAuthorized = () => {
  if (!sessionStorage.isAuth) {
    return false;
  }
  return true;
};

export const isAdmin = () => {
  let authUser = JSON.parse(localStorage.getItem("user"));
  if (authUser) {
    if (authUser.isAdmin) {
      return true;
    }
  }
  return false;
};

export const isTheCreator = (creatorId) => {
  let authUser = JSON.parse(localStorage.getItem("user"));
  if (authUser) {
    if (authUser._id === creatorId) {
      return true;
    }
  }
  return false;
};
