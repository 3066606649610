import styled from "styled-components";
import AppLayout from "../layouts/AppLayout";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);
  const handleLoad = () => {
    setLoading(false);
  };


  return (
    <AppLayout>
        <Container>
        {loading && <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>}
        <Iframe 
            title="Privacy Policy"
            onLoad={handleLoad}
            src="https://docs.google.com/document/d/e/2PACX-1vSx_SHjah9VPtzYj5B0vOwwanZ7lvtwVwEuGz-8sCvY1nvJA_VyG3JSy37kVZjaPC1lEO7a2_xskKPQ/pub?embedded=true" 
            />
        </Container>
    </AppLayout>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Iframe = styled.iframe`
width: 50%;
height: 100vh;
text-align: center;
border: none;

@media screen and (max-width: 768px) {
    width: 100%;
} 
`

export default PrivacyPolicy;