import { React, useEffect, useState } from "react";
import "./page.css";
import Header from "../layouts/header";
import Translate from "../translate";
import styled from "styled-components";
import Footer from "../layouts/footer";
import { AXIOS_GET } from "../config/axios";
import { GET_LANDING } from "../config/url";
import ContentLoader, { Facebook } from "react-content-loader";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import AppLayout from "../layouts/AppLayout";

const Contacts = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AXIOS_GET(GET_LANDING)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <AppLayout>
      <AboutContainer>
        <div className="right item" style={{ flex: 1 }}>
          <DataIcon style={{ fontSize: "200px" }} />
        </div>
        <div className="left item" style={{ flex: 5 }}>
          <h1 style={{ fontWeight: "bold" }}>CONTACT US</h1>
          {loading ? (
            <ContentLoader>
              <rect x="20" y="17" rx="4" ry="4" width="300" height="13" />
              <rect x="20" y="40" rx="3" ry="3" width="250" height="10" />
            </ContentLoader>
          ) : (
            <>
              <h3>Administration</h3>

              <b>
                <u>Lenah Chacha </u>
              </b>
              <p>
              <Translate Word={"admin-desc"} />
              </p>
              <a href="mailto:lchacha@andrew.cmu.edu">
                Email: lchacha@andrew.cmu.edu
              </a>
              <br />
              <br />
              <h3>Dev Team</h3>

              <b>
                <u>Jean Paul Nishimirwe</u>
              </b>
              <p>
              <Translate Word={"dev-team"} />
              </p>
              <a href="mailto:jnishimi@andrew.cmu.edu">
                Email: jnishimi@andrew.cmu.edu
              </a>
              <p></p>
              <b>
                <u>David NTAMAKEMWA</u>
              </b>
              <p>
              <Translate Word={"dev-team"} />
              </p>
              <a href="mailto:daveleo250@gmail.com">
                Email: daveleo250@gmail.com
              </a>
              <p></p>
              <b>
                <u>Patrick IRADUKUNDA</u>
              </b>
              <p>
              <Translate Word={"dev-team"} />
              </p>
              <a href="mailto:iradupat@yahoo.fr">
                Email: iradupat@yahoo.fr
              </a>
              <p></p>
            </>
          )}
        </div>
      </AboutContainer>
    </AppLayout>
  );
};
const DataIcon = styled(DataUsageIcon)`
  vertical-align: bottom;
  color: #01944D;
  padding-right: 40px;
`;
const AboutContainer = styled.div`
  background: #e1dfdf;
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  // align-items: center;
`;
export default Contacts;
