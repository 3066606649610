import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { ToastContainer, toast } from "react-toastify";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";
import AXIOS_POST_FILE, { AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from "../config/axios";

import {
  Box,
  TextField,
  createTheme,
  Select,
  ThemeProvider,
} from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { countryList } from "../../../helper/country";
import { useEffect } from "react";

const UpdateForm = ({ code }) => {
  const history = useHistory();
  const [accessibility, setAccessibility] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const [IsFileType, setIsFileType] = useState(false);
  const [loading, setLoading] = useState(false);

  //form values
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");

  const [source, setSource] = useState("");
  const [sourceError, setSourceError] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");

  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");

  const [links, setLink] = useState("");
  const [linkError, setLinkError] = useState("");

  const [tags, setTags] = useState("");
  const [tagsError, setTagsError] = useState();
  const [cat, setCat] = useState("");
  const [desc, setDesc] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const reverseFormattedTags = (formattedTagsArray) => {
    const originalTags = formattedTagsArray.map((tag) => `"${tag}"`);
    return originalTags.join(", ");
  };
  useEffect(() => {
    setIsLoading(true);
    AXIOS_GET(`/auth/datasets/${code}/details`, sessionStorage.getItem("token"))
      .then((response) => {
        // console.log("dataset----------------------", response.data.data);
        // console.log("desc: " + response.data.data.description);
        setCat(response.data.data.category);
        setDesc(response.data.data.description);
        setTitle(response.data.data.name);
        setSource(response.data.data.organization);
        setCountry(response.data.data.country);
        setAccessibility(response.data.data.isPrivate ? "Private" : "Public");
        setLink(response.data.data.link);
        setTags(reverseFormattedTags(response.data.data.tags));
      })
      .catch((error) => {
        setIsLoading(false);
        // console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleFileType = (e) => {
    e.preventDefault();
    setIsFileType(!IsFileType);
  };
  const handleSubmit = (e) => {
    setIsValidated(true);
    if (cat.trim() === "") {
      setCategoryError("Category is required");
      setIsValidated(false);
      // console.log("================================", cat);
    } else {
      setCategoryError("");
    }
    if (title.trim() === "") {
      setTitleError("Title is required");
      // console.log("================================", title);
      setIsValidated(false);
    } else {
      setTitleError("");
    }

    if (source.trim() === "") {
      setSourceError("Source is required");
      // console.log("================================", source);
      setIsValidated(false);
    } else {
      setSourceError("");
    }

    // if (file.trim() === "") {
    //   setFileError("File is required");
    //   setIsValidated(false);
    // } else {
    //   setFileError("");
    // }
    if (tags.trim() !== "") {
      validateTags(tags);
    }

    if (desc.trim() === "") {
      setDescriptionError("Description is required");
      // console.log("================================", desc);
    } else {
      setDescriptionError("");
    }
    // console.log("================================", isValidated);
    const formData = new FormData();
    const jsonData = {};
    if (IsFileType) {

      // Create a FormData object to store the form data
      formData.append("name", title);
      formData.append("organization", source);
      formData.append("isPrivate", accessibility == "Public" ? false : true);
      formData.append("country", country);
      formData.append("tags", formatTags(tags));
      formData.append(`${links ? "link" : "data"}`, links ? links : file);
      formData.append("type", links ? "link" : "file");
      formData.append("description", desc);
      formData.append("category", cat);
    } else {
      jsonData.name = title;
      jsonData.organization = source;
      jsonData.isPrivate = accessibility === "Public" ? false : true;
      jsonData.country = country;
      jsonData.tags = tags;
      jsonData[links ? "link" : "data"] = links ? links : file;
      jsonData.type = links ? "link" : "file";
      jsonData.description = desc;
      jsonData.category = cat;
      // console.log(jsonData)
    }

    try {
      if (isValidated) {
        setLoading(true);
        AXIOS_PATCH(
          IsFileType ? "/datasets/"+code+"/update" : "/datasets/"+code+"/update",
          !IsFileType ? jsonData : formData,
          sessionStorage.getItem("token")
        ).then((response) => {
          toast.success("Dataset updated successfull", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
          setTimeout(()=>{
            history.push("/dash");
          }, 5000)
          
        }).catch((error)=>{
          setLoading(false);

          console.error(error); // Handle the error
          const message =  error.response.data.status > 299? error.response.data.message : "Something went wrong!"
          toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        });
      }
    } catch (error) {
      setLoading(false);
      // console.error(error); // Handle the error
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "colored",
      });
    } finally {
      // Set loading back to false after the request is completed (success or error)
      setLoading(false);
    }
  };
  const validateTags = () => {
    const regex = /^("[\w\s]+",?\s*)*"[^,"]*"$/;
    // Regular expression for the specified format
    if (!regex.test(tags)) {
      setIsValidated(false);
      // console.log("================================", tags);
      setTagsError(
        'Tags must be in the format: "Education","Quality Education",'
      );
    } else {
      setTagsError("");
    }
  };
  const formatTags = (value) => {
    const formattedTags = value
      .split(",")
      .map((tag) => tag.trim().replace(/"/g, ""))
      .map((tag) => tag.charAt(0).toUpperCase() + tag.slice(1));
    setTags(formattedTags.join(", "));
  };

  return (
    <Container>
      <ToastContainer />
      <Title>Publish Dataset</Title>
      {!isLoading && (
        <>
          <InputContainer>
            <FormControl>
              <label htmlFor="title">Title</label>
              <input
                onChange={(e) => {
                  setTitle(e.target.value);
                  setTitleError("");
                }}
                type="text"
                value={title}
                placeholder="E.g: Education in East Africa"
                id="title"
              />
              {titleError && (
                <FormHelperText error>{titleError}</FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <label htmlFor="source">Source</label>
              <input
                value={source}
                onChange={(e) => {
                  setSource(e.target.value);
                  setSourceError("");
                }}
                type="text"
                placeholder="E.g: World Bank"
                id="source"
              />
              {sourceError && (
                <FormHelperText error>{sourceError}</FormHelperText>
              )}
            </FormControl>
          </InputContainer>
          <InputContainer>
            <FormControl>
              <label htmlFor="tags">Tags</label>
              <input
                value={tags}
                onChange={(e) => {
                  setTags(e.target.value);
                  validateTags();
                }}
                type="text"
                placeholder='E.g: "Education", "africa", "East Africa"'
                id="tags"
              />
              {tagsError && <FormHelperText error>{tagsError}</FormHelperText>}
            </FormControl>
            <FormControl>
              <label htmlFor="country">Country</label>
              <select
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  setCountryError("");
                }}
                type="text"
                placeholder="E.g: World Bank"
                id="country"
              >
                <option value="" disabled selected>
                  Select Country related to dataset
                </option>
                {countryList.map((country) => (
                  <option value={country}>{country}</option>
                ))}
              </select>
              {countryError && (
                <FormHelperText error>{countryError}</FormHelperText>
              )}
            </FormControl>
          </InputContainer>
          <InputContainer>
            <FormControl>
              <label htmlFor="category">Category</label>
              <input
                type="text"
                list="options"
                id="search-input"
                value={cat}
                onChange={(e) => {
                  setCat(e.target.value);
                }}
                placeholder="Type to search"
              />
              <datalist id="options">
                <option value="Education" />
                <option value="Health" />
                <option value="Geospatiol" />
                <option value="Others" />
              </datalist>
              {categoryError && (
                <FormHelperText error>{categoryError}</FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <label htmlFor="access">Accessibility</label>
              <select
                id="access"
                value={accessibility}
                onChange={(e) => setAccessibility(e.target.value)}
              >
                <option value="Public" selected>
                  Public
                </option>
                <option value="Private">Private</option>
              </select>
            </FormControl>
          </InputContainer>
          <FileContainer>
            <DTitle>Dataset Description</DTitle>
            <StyledTextareaAutosize
              label="Description"
              labelId="textareaLabel"
              value={desc}
              onChange={(e) => {
                setDesc(e.target.value);
              }}
              error={!!descriptionError}
              helperText={descriptionError}
              id="textarea"
              minRows={3}
              placeholder="Enter a brief description of the dataset, including its purpose, contents, and any relevant details."
            />
            <DTitle hidden >
              {!IsFileType
                ? "Paste a link to the dataset"
                : "Choose the file (.csv)"}{" "}
              or{" "}
              <a href="" onClick={handleFileType}>
                {!IsFileType ? "Upload a file" : "Paste a dataset link"}
              </a>
              {/* <FileInput >
                {IsFileType ? (
                  <input type="File" />
                ) : (
                  <input
                    type="text"
                    value={links}
                    name="link"
                    onChange={(e) => {
                      setLink(e.target.value);
                    }}
                    error={!!linkError}
                    helperText={linkError}
                    placeholder="paste your link here"
                  />
                )}
              </FileInput> */}
            </DTitle>
          </FileContainer>
          <SubmitContainer>
            <SubmitButton disabled={loading} onClick={handleSubmit}>
              {loading ? "Saving..." : "Save"}
            </SubmitButton>
          </SubmitContainer>
        </>
      )}
    </Container>
  );
};
const FormControl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 50%;
  padding: 1% 2%;
  gap: 0;
  input,
  select {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 3px;
    resize: vertical;
    padding: 0.3em 0;
    margin: 0; /* Remove margin */
  }
  label {
    margin: 0;
    color: #01944D;
    font-family: "Bebas Neue", cursive;
    font-size: 15px;
  }
`;
const override = css`
  display: block;
  margin: 0 auto;

@media screen and (max-width: 768px) {
  width: 100vw !important;
}
`;

const loaderColor = "#ffffff"; // Customize the color of the loader
const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
`;
const SubmitContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const SubmitButton = styled.button`
  cursor: pointer;
  width: 90%;
  padding: 5px;
  border: none;
  background-color: #01944D;
  font-family: "Bebas Neue", cursive;
  color: white;
  border-radius: 2px;
`;
const FileInput = styled.div`
  height: 50px;
  width: 100%;
  input {
    width: 100%;
    height: inherit;
  }
`;
const DTitle = styled.p`
  width: 100%;
  text-align: center;
  font-family: "Bebas Neue", cursive;
`;
const StyledTextareaAutosize = styled(TextareaAutosize)`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  width: 100%;
  resize: vertical;
  margin-bottom: 10px;
`;
const InputContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  gap: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
const Title = styled.p`
  font-family: "Bebas Neue", cursive;
  text-align: center;
`;
const Container = styled.div`
  width: 70%;
  margin: 1%;
  padding: 2%;
  background: white;
  @media screen and (max-width: 768px) {
    width: 100vw !important;
    margin: 0;
    padding: 0;
  }
`;
export default UpdateForm;
