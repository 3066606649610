import Api from "./Api";

class UploadDatasetService extends Api {
        shareLink =(params)=>{
        let loggedinUser = JSON.parse(localStorage.getItem('authUser'))

        const data = {
            data: {
              url: "/link",
              token: loggedinUser.token,
              name: params.name,
              link:params.url,
              description:params.description,
              type:"link",
              organization:params.organization
            },
          };
          const response = this.postRequest(data);
          return response;
        }
        uploadDataset = (params) => {  
        const formData = new FormData();

        // for (let i = 0; i < params.file.length; i++) {
        //   let file = params.file.item(i);
        //   formData.append("file[" + i + "]", file); 
        // }

        formData.append("data", params.file[0])
        // console.log("Params to be submtted", params)
        // if(params.isPrivate===true)
        // {
        formData.append("isPrivate",params.isPrivate)
          // console.log("This is true")
        // }
        let loggedinUser = JSON.parse(localStorage.getItem('authUser'));
        formData.append("description", params.description);
        formData.append("createdAt", params.createdAt);
        formData.append("organization", params.organization);
        // formData.append("token", loggedinUser.access_token);
        formData.append("name", params.name);
        formData.append("type", params.isAfile?"file":"link")
        // console.log("Params to be submtted", formData)

         const data = {
             formData: formData,
             token:loggedinUser.token,
             url: "/data",        
         };   

    const response = this.uploadRequest(data);
    return response;
  };
}

export default UploadDatasetService;
