import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Chip, TableFooter, TablePagination } from "@mui/material";
import { MdVerified } from "react-icons/md";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import CustomSearchBar from "../CustomSearchBar";
import AccountDetails from "./AccountDetails";

const AccountsList = (props) => {
  const [openDetails, setOpenDetails] = useState(false);
  const loading = props.loading;
  const originalData = props.accounts;
  const [accounts, setAccounts] = useState([]);
  const [userData, setUserData] = useState({})
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - accounts.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const showDetails=(data)=>{
    setUserData(data)
    setOpenDetails(true)
  }

  useEffect(() => {
    setAccounts(originalData);
  }, [originalData]);

  return (
    <TableContainer style={{ padding: 10 }}>
     {!openDetails ? <h3 >Accounts </h3>
      :
      <h3 style={{display:'flex', alignItems:'center'}}> <p onClick={()=>{setOpenDetails(false)}} style={{textDecoration:'underline', cursor:'pointer'}}>Accounts/ </p>   <p >Details</p></h3>
    }
      <hr />

      {loading ? (
        <div>Loading ...</div>
      ) : openDetails ? (
        <AccountDetails
          isOpen={openDetails}
          data={userData}
          setUpdated={props.setUpdated}
          save={() => {}}
          close={() => {
            setOpenDetails(false);
          }}
        />
      ) : (
        <Paper style={{ padding: 5 }}>
          <CustomSearchBar
            displayedList={accounts}
            filterAttribs={"username"}
            originalList={originalData}
            changeDisplayList={(list) => {
              setAccounts(list);
            }}
          />
          <br />
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "#fff" }}>Name</TableCell>
                <TableCell style={{ color: "#fff" }}>Account Type</TableCell>
                <TableCell style={{ color: "#fff" }}>Verified</TableCell>
                <TableCell style={{ color: "#fff" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? accounts.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : accounts
              ).map((row) => (
                <TableRow
                  style={{ textTransform: "capitalize" }}
                  key={row.code}
                  // sx={{ '&:last-child td, &:last-child td': { border: 0 } }}
                >
                  <TableCell scope="row">{row.username}</TableCell>
                  <TableCell>{row.account}</TableCell>
                  <TableCell>
                    {row.isVerified ? (
                      <div>
                        <Chip
                          style={{ color: "#ffbc78", borderColor: "#ffbc78" }}
                          // onDelete={() => {}}
                          deleteIcon={<MdVerified size={15} color="#ffbc78" />}
                          label={"Verified account"}
                          variant="outlined"
                        />{" "}
                      </div>
                    ) : (
                      <Chip label="Normal account" variant="outlined" />
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={()=>showDetails(row)}
                      className="mt-btn"
                      // variant="outlined"
                    >
                      More details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  count={accounts.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      )}
    </TableContainer>
  );
};

export default AccountsList;
