import { useState } from 'react';
import { Login } from "@mui/icons-material";
import Register from '../containers/ui/components/register';
import ForgetPass from '../containers/ui/components/forgetPassword';
import OtpBox from '../containers/ui/components/Otp';
import ResetPassword from '../containers/ui/components/resetPassword';

export const useLoginBox = (next=false) => {
  const [showModel, setShowModel] = useState(false);
  const [modelComponent, setModelComponent] = useState(<></>);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const HandleRegisterBox = () => {
    setShowModel(true);
    setOpen(true);
    setModelComponent(
      <Register
        handleLogin={handleLoginBox}
        handleOtp={handleOtpBox}
        handleClose={handleClose}
      />
    );
  };
  const handleResetBox = () => {
    setShowModel(true);
    setOpen(true);
    setModelComponent(
      <ResetPassword
        handleLogin={handleLoginBox}
        handleClose={handleClose}
        handleOtp={handleOtpBox}
      />
    );
  };
  const handleForgetBox = (event) => {
    setShowModel(true);
    setOpen(true);
    setModelComponent(
      <ForgetPass handleClose={handleClose} handleReset={handleResetBox} />
    );
  };
  const handleOtpBox = (email) => {
    setShowModel(true);
    setOpen(true);
    setModelComponent(
      <OtpBox
        handleLogin={handleLoginBox}
        handleClose={handleClose}
        handleReset={handleResetBox}
        emailValue={email}
      />
    );
  };
  const handleLoginBox = () => {
    setShowModel(true);
    setOpen(true);
    setModelComponent(
      <Login
        next={next}
        handleClose={handleClose}
        handleRegister={HandleRegisterBox}
        handleOtp={handleOtpBox}
        handleForget={handleForgetBox}
      />
    );
  };

  return { showModel, modelComponent, open, handleLoginBox };
};