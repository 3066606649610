import React from "react";
import RiseLoader from "react-spinners/RiseLoader";

const Loader = ({ size = 8, loading = true, color = "#36d7b7" }) => {
  return (
    <>
      <RiseLoader color={color} size={size} loading={loading} />
    </>
  );
};

export default Loader;
