import { React, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  selectLanguage,
  changeToEn,
  changeToFr,
  changeLanguage,
} from "../../../app/features/languageSlice";

import { useSelector } from "react-redux";
import LanguageIcon from "@mui/icons-material/Language";
import Suggestion from "../components/suggestion";
import Login from "../components/login";
import Register from "../components/register";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Box, Typography } from "@mui/material";
import logo from "../Upanzi Network logo.png";
import Translate from "../translate";
import VerifyUser from "../components/verifyUser";
import ForgetPass from "../components/forgetPassword";
import ResetPassword from "../components/resetPassword";
import { Link } from "react-router-dom";
const Header = () => {
  const history = useHistory();
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [loginBox, setLoginBox] = useState(false);
  const [registerBox, setRegisterBox] = useState(false);
  const [otpBox, setOTPBox] = useState(false);
  const [passwordBox, setPasswordBox] = useState(false);
  const [resetPasswordBox, setResetPasswordBox] = useState(false);

  const handleHomepage = () => {
    history.push("/");
  };
  const handleAbout = () => {
    history.push("/about-us");
  };
  const handleResetBox = () => {
    setResetPasswordBox(true);
    setOTPBox(false);
    setLoginBox(false);
    setRegisterBox(false);
    setPasswordBox(false);
  };
  const handleOtpBox = () => {
    setOTPBox(true);
    setLoginBox(false);
    setRegisterBox(false);
  };
  const handleSuggestionStyle = () => {
    setSuggestionBox(true);
  };
  const handleLoginBox = () => {
    setLoginBox(true);
    setRegisterBox(false);
    setResetPasswordBox(false);
    setOTPBox(false);
  };
  const HandleRegisterBox = () => {
    setRegisterBox(true);
    setLoginBox(false);
  };
  const handleCancelClick = (event) => {
    event.preventDefault();
    setLoginBox(false);
    setSuggestionBox(false);
    setRegisterBox(false);
    setOTPBox(false);
    setPasswordBox(false);
    setResetPasswordBox(false);
  };
  const handleForgetBox = (event) => {
    event.preventDefault();
    setPasswordBox(true);
    setLoginBox(false);
  };
  const dispatch = useDispatch();
  const languageVal = useSelector(selectLanguage);
  const [selectedLang, setselectedLang] = useState(languageVal);
  const changeLanguage = (e) => {
    setselectedLang(e.target.value);
    if (selectedLang == "En") {
      dispatch(changeToFr());
    } else {
      dispatch(changeToEn());
    }
    // console.log("change language" + languageVal);
  };
  return (
    <>
      <div className="nav-container">
        <div style={{ flexGrow: 1 }} className="logo">
          <img
            style={{ cursor: "pointer" }}
            className="image-logo"
            src={logo}
            alt="logo"
            onClick={handleHomepage}
          />
        </div>
        <div style={{ flexGrow: 4 }} className="navigation">
          <ul className="nav_ul">
            <li onClick={handleAbout}>
              <Translate Word={"contact"} />
            </li>
            <li onClick={handleAbout}>
              <Translate Word={"about-us"} />
            </li>
            <li  onClick={handleSuggestionStyle}>
              <Translate Word={"suggestion"} />
            </li>
          </ul>
        </div>
        <div style={{ flexGrow: 2 }} className="auth">
          <span onClick={handleLoginBox}>
            <Translate Word={"login"} />
          </span>
          <span onClick={HandleRegisterBox}>
            <Translate Word={"signup"} />
          </span>
        </div>
        <div style={{ flexGrow: 3 }} className="user-guide-language">
          <div className="language">
            <Box display="flex" alignItems="center">
              <LanguageIcon variant="contained" className="language-icon" />
              <Typography variant="body1">
                <select onChange={changeLanguage}>
                  {selectedLang == "En" && (
                    <>
                      <option value="En">En</option>
                      <option value="Fr">Fr</option>
                    </>
                  )}
                  {selectedLang == "Fr" && (
                    <>
                      <option value="Fr">Fr</option>
                      <option value="En">En</option>
                    </>
                  )}
                </select>
              </Typography>
            </Box>
          </div>
          <div className="user-guide">
            <MenuBookIcon className="menu-book" size="large"></MenuBookIcon>
            <span style={{ fontSize: 12, fontWeight: 500 }}>
              <Translate Word={"user-guide"} />
            </span>
          </div>
          {/* <div className="hamburger">Hamburger Menu</div> */}
        </div>
      </div>
      {registerBox && (
        <Register
          onCancel={handleCancelClick}
          Login={handleLoginBox}
          otp={handleOtpBox}
        />
      )}
      {loginBox && (
        <Login
          onCancel={handleCancelClick}
          forgetPassword={handleForgetBox}
          Signup={HandleRegisterBox}
          otp={handleOtpBox}
        />
      )}
      {suggestionBox && <Suggestion onCancel={handleCancelClick} />}
      {otpBox && (
        <VerifyUser onCancel={handleCancelClick} loginForm={handleLoginBox} />
      )}
      {passwordBox && (
        <ForgetPass onCancel={handleCancelClick} resetForm={handleResetBox} />
      )}
      {resetPasswordBox && (
        <ResetPassword
          onCancel={handleCancelClick}
          loginForm={handleLoginBox}
        />
      )}
    </>
  );
};

export default Header;
