import React from "react";
import Translate from "../translate";
import styled from "styled-components";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
const GroupCategory = (props) => {
  return (
    <Container className="card">
      <CardBorderTop className="card-border-top"></CardBorderTop>
      <Img className="img link-button">
        <span>{props.icon}</span>
      </Img>
      <span>
        {" "}
        <Translate Word={props.title} /> <br />
      </span>
      <button className="link-button">
        {/* <Translate Word={"more"} /> */}
      </button>
    </Container>
  );
};
const Container = styled.div`
  height: 254px;
  width: auto;
  margin-bottom: 20px;
  background: white;
  border-radius: 15px;
  box-shadow: 1px 5px 60px 0px #100a886b;
  margin: 5px 15px;
  button {
    padding: 8px 25px;
    display: block;
    margin: auto;
    border-radius: 8px;
    border: none;
    margin-top: 30px;
    background: #01944D;
    color: white;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      background: #01944D;
    }
  }
  span {
    font-weight: 600;
    color: #989393;
    text-align: center;
    display: block;
    padding-top: 10px;
    font-size: 16px;
  }
`;
const CardBorderTop = styled.div`
  width: 60%;
  height: 3%;
  background: #01944D;
  margin: auto;
  border-radius: 0px 0px 15px 15px;
`;
const Img = styled.div`
  width: 150px;
  height: 80px;
  background: #01944D;
  border-radius: 15px;
  margin: auto;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    width: inherit;
    height: inherit;
    padding-top: 25px;
    color: white;
  }
`;
export default GroupCategory;
