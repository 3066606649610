export const getTokenFromLocalStorage =() =>
{
    let loggedInUser = JSON.parse(localStorage.getItem('authUser'));
    if(!loggedInUser) return false;
    return String(loggedInUser.token).trim();
}

export const addAdminAccountToLocalStorage =(account)=>{
    let user = account
    user.isAdmin = true
    user.username = account.name
    localStorage.setItem('authUser',JSON.stringify(user))
}

export const getAccount =()=>{
    let loggedInUser = JSON.parse(localStorage.getItem('authUser'));
    if(!loggedInUser) return false;
    return loggedInUser
}

export const isAdmin=()=>{
    let loggedInUser = JSON.parse(localStorage.getItem('authUser'));
    if(!loggedInUser) return false;
    if(!loggedInUser.isAdmin) return false;
    return loggedInUser.isAdmin
}

export const setAccountStatus =(STATUS)=>{
    // NOT_REQUESTED, REQUESTED, VERIFIED
    if(STATUS === "NOT_REQUESTED"){
        localStorage.setItem('ACCOUNT_STATUS', STATUS)
    }else if(STATUS === "REQUESTED"){
        localStorage.setItem('ACCOUNT_STATUS', STATUS)
    }else if(STATUS === "VERIFIED"){
        localStorage.setItem('ACCOUNT_STATUS', STATUS)
    }else{
        localStorage.setItem('ACCOUNT_STATUS', "NOT_REQUESTED")
    }
}

export const getAccountStatus =()=>{
    return localStorage.getItem("ACCOUNT_STATUS")
}
export const isLoggedIn = () =>{
    let loggedInUser = JSON.parse(localStorage.getItem('authUser'));
    if(!loggedInUser) return false;
    return loggedInUser.token ? true : false;
}

export const getLoggedInUser = () =>{
    let loggedInUser = JSON.parse(localStorage.getItem('authUser'));
    return loggedInUser;
}

export const setCurrentNav =(location)=>{
    localStorage.setItem('location', location)
}

export const getCurrentNav =()=>{

    let location = localStorage.getItem('location')
    if(location){
        return parseInt(location)
    }
    setCurrentNav(1)
    return 1
}