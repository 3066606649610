
export function isValidUserName(input) {
    const regex = /^[a-zA-Z0-9 _]*$/;
    return regex.test(input);
  }


  const themes  = [
    {
        "id":"SDGS",
        "name":"THE 17 GOALS",
        "description":"",
        "subthemes":[
            {
                "id":"SDG1",
                "name":"GOAL 1 : NO POVERTY",
            },
            {
                "id":"SDG2",
                "name":"GOAL 2 : END HUNGER",
            },
            {
                "id":"SDG3",
                "name":"GOAL 3 : GOOD HEALTH AND WELL-BEING",
            },
            {
                "id":"SDG4",
                "name":"GOAL 4 : QUALITY EDUCATION",
            },
            {
                "id":"SDG5",
                "name":"GOAL 5 : GENDER EQUALITY",
            },
            {
                "id":"SDG6",
                "name":"GOAL 6 : CLEAN WATER AND SANITATION",
            },
            {
                "id":"SDG7",
                "name":"GOAL 7 : AFFORDABLE AND CLEAN ENERGY",
            },
            {
                "id":"SDG8",
                "name":"GOAL 8 : DECENT WORK AND ECONOMIC GROWTH",
            },
            {
                "id":"SDG9",
                "name":"GOAL 9 : INDUSTRY INOVATION AND INFRASTRUCTURE",
            },
            {
                "id":"SDG10",
                "name":"GOAL 10 : REDUCE INEQUALITIES",
            },
            {
                "id":"SDG11",
                "name":"GOAL 11 : SUSTAINABLE CITIES AND COMMUNITIES",
            },
            {
                "id":"SDG12",
                "name":"GOAL 12 : RESPONSIBLE CONSUMPTION AND PRODUCTION",
            },
            {
                "id":"SDG13",
                "name":"GOAL 13 : CLIMATE ACTION",
            },
            {
                "id":"SDG14",
                "name":"GOAL 14 : LIFE BELOW WATER",
            },
            {
                "id":"SDG15",
                "name":"GOAL 15 : LIFE ON LAND",
            },
            {
                "id":"SDG16",
                "name":"GOAL 16 : LIFE ON LAND",
            },
            {
                "id":"SDG17",
                "name":"GOAL 17 : PARTENERSHIPS FOR THE GOAL",
            }
        ]
    },
]
export function getThemeNames(theme, themeId){
    return themes.find(elemenet=>elemenet.id===theme).subthemes.find(element=>element.id===themeId).name
}


