import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GetPublicDataService from "../../services/GetPublicDataService";

const initialState = {
  dataset:null,
  message: "",
  isLoaded: false,
  loading: false,
};

export const getDatasetById= createAsyncThunk(
    "dataset/getDatasetbyId",
    async(id, {rejectWithValue})=>{
        try {
            const publicData = new GetPublicDataService();
            const data = await publicData.getSingleDataset(id);
            return data;
        } catch (error) {

            rejectWithValue(error)
        }
    }
)

export const authenticationSlice = createSlice({
  name: "dataset",
  initialState,
  extraReducers: {
    [getDatasetById.pending]: (state, { payload }) => {
        state.loading = true;
      },
      [getDatasetById.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.dataset = payload;
        state.isLoaded = true;
      },
      [getDatasetById.rejected]: (state, { payload }) => {
        state.loading = false;
        state.dataset = null;
        state.message = payload;
        state.isLoaded = false;
      },
  },
});


export default authenticationSlice.reducer;
