import React from "react";
import styled from "styled-components";

const Mobile = () => {
  return (
    <Container>
      <h1>Mobile Version Coming Soon</h1>
      <br />
      <p>
        We're currently working on the mobile version of our app to provide you
        with an even better experience on smaller screens.
      </p>
      <br />
      <p>
        We apologize for any inconvenience this may cause. In the meantime,
        please continue to enjoy our desktop version.
      </p>
      <br />
      <p>Thank you for your understanding!</p>
    </Container>
  );
};
const Container = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 40px;
    color: #01944D;
    /* font-weight: bold; */
    font-family: "Bebas Neue", cursive;
  }
  p {
    margin: 0;
    font-weight: bold;
    text-align: justify;
    color: black;
    font-size: 20px;
  }
`;
export default Mobile;
