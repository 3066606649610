import React, { useEffect, useState } from 'react'
import "../dashboard.css"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@material-ui/core';
import { Avatar, Checkbox } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { activateAccount as activateAccountHandler, getAccount, verifyAccount } from '../../../../services/admin/AdminDataAPI';
import { getTokenFromLocalStorage } from '../../../../utils/localStorageUtil';
import { useHistory, Link } from "react-router-dom";
import { FaLink } from 'react-icons/fa';
import { useProfiler } from '@sentry/react';
import ConfirmBox from '../../../../components/new_components/ConfirmMaterial/ConfirmBox';
import { SnackbarProvider, useSnackbar } from 'notistack';


const AccountDetails = ({save, isOpen, close, data, setUpdated}) => {
      const { enqueueSnackbar } = useSnackbar();
      const [userProfile, setUserProfile] = useState({username:"", posts:[]})
      const [loading, setLoading] = useState(false)
      const [loadingUpdates, setLoadingUpdates] = useState(false)
      const [activateAccount, setActivateAccount] = useState()
      const [verifiedAccount, setVerifiedAccount] = useState()
      const [openPopup, setOpenPopup] = useState(false)
      const [canUpdate, setCanUpdate] = useState(false)

      const history = useHistory()

      const handleSnack = () => {
        enqueueSnackbar('Account updated!');
      };
    
      const VerifyAccountComp=()=>{
        if(userProfile.isVerified && !verifiedAccount){
            return (
                <div>
                    <h4>You are going to remove the verification checkmark for this account!</h4>
                </div>
            )
        }else if(!userProfile.isVerified && verifiedAccount){
            return (
                <div>
                    <h4>You are going to make this account verified!</h4>
                </div>
            ) 
        }else{
            return <></>
        }
      }

      const ActivateAccountComp=()=>{
        if(userProfile.isActive && !activateAccount){
            return (
                <div>
                    <h4>You are going to desactivate/disable this account!</h4>
                </div>
            )
        }else if(!userProfile.isActive && activateAccount){
            return (
                <div>
                    <h4>You are going to activate/enable this account!</h4>
                </div>
            ) 
        }else{
            return <></>
        }
      }

      const openPopupHandler=()=>{
        if((userProfile.isActive === activateAccount) && (userProfile.isVerified === verifiedAccount)){
            return
        }
        setOpenPopup(true)
      }
      const handleClose = () => {
        close();
      };

      const updateProfile = async()=>{
        // console.log(activateAccount)
            setLoadingUpdates(true)
            const token = getTokenFromLocalStorage()
            const response1 = await activateAccountHandler({user_id:userProfile.code, data:{token:token, active:activateAccount}});
            const response2 = await verifyAccount({user_id:userProfile.code, data:{token:token, verified:verifiedAccount}})
            handleSnack()
            setLoadingUpdates(false)
            setCanUpdate(false)
            setUpdated(true)
      }

      const getProfileInfo =async()=>{
        setLoading(true)
        const req = {data:{token:getTokenFromLocalStorage()},user_id:data.code}
        const response = await getAccount(req)
        setUserProfile(response.user)
        setActivateAccount(response.user.isActive)
        setVerifiedAccount(response.user.isVerified)
        setLoading(false)
      }

      const datasetDetails = (id,name)=>{
        history.push({
            pathname: "/details/dataset",
            state: { id: id, dataset_name: name },
          });
      }

      useEffect(()=>{
        getProfileInfo()
      },[])

      useEffect(()=>{
        if(canUpdate){
            updateProfile()
        }
      },[canUpdate])

    if(loading){
        return <p>Loading ...</p>
    }
    return(
         <div className='account-main'>
            <ConfirmBox open={openPopup} allow={()=>{
                setOpenPopup(false)
                setCanUpdate(true)
            }} setOpen={()=>{
                setOpenPopup(false)
            }} >
                <VerifyAccountComp/>
                <ActivateAccountComp/>
            </ConfirmBox>
            <div className="account-topbar">
                <Button onClick={close} variant="outlined" >
                    {"< Back"}
                </Button>
            </div>
            

            <div className="account-content">
                <div className="account-main-info">
                        <div className="account-avatar">
                        <Avatar sx={{ height:120, width:120, fontSize:26 }}>{userProfile.username[0]}</Avatar>
                        </div>
                        <div className="account-detais">
                            <h4 style={{textTransform:'capitalize'}}><u>{userProfile.username}</u></h4>
                            <div className="account-table">
                                <div className="left-value">
                                        Email
                                </div>
                                <div className="right-value">
                                    {userProfile.email}                                    
                                </div>
                            </div>
                            <div className="account-table">
                                <div className="left-value">
                                        Affiliation
                                </div>
                                <div className="right-value">
                                    {userProfile.organization}                                   
                                </div>
                            </div>
                            <div className="account-table">
                                <div className="left-value">
                                        Job
                                </div>
                                <div style={{textTransform:'capitalize'}} className="right-value">
                                    {userProfile.position}                                  
                                </div>
                            </div>
                        </div>
                </div>
                <hr />

                <div className="account-main-info">
                        <div className="account-avatar">
                        {/* <Avatar sx={{ height:120, width:120, fontSize:26 }}>P I</Avatar> */}
                        </div>
                        <div className="account-detais">
                            <div className="account-table">
                                <div className="left-value">
                                        Account is verified
                                </div>
                                <div className="right-value">
                                <Checkbox
                                    checked={verifiedAccount}
                                    onChange={()=>{setVerifiedAccount(!verifiedAccount)}}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    {/* <input disabled type="checkbox" defaultChecked={userProfile.isVerified} name="" id="" />                                    */}
                                </div>
                            </div>
                            <div className="account-table">
                                <div className="left-value">
                                        Account activated
                                </div>
                                <div className="right-value">
                                <Checkbox
                                    checked={activateAccount}
                                    onChange={()=>{setActivateAccount(!activateAccount)}}
                                    // inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    {/* <input type="checkbox" onChange={(e)=>{setActivateAccount(e.target.checked)}}  defaultChecked={userProfile.isActive}  name="" id="" />                                   */}
                                </div>
                            </div>
                            
                        </div>
                </div>

                <hr />
                <div className="account-main-info">
                       
                        <div className="account-detais">
                            <h6>Datasets uploaded ({userProfile.numberOfPosts})</h6>
                           <ol>
                            {
                                userProfile.posts.map((link, index)=>{
                                    if(index === (userProfile.posts.length-1)){
                                        return  <li style={{color: '#990000'}}> <Link to={{pathname:'/details/dataset', state:{id: link.code, dataset_name: link.name}}}   href='#'   >{link.name}</Link> <br/> <a target='_blank' href={link.url} title='Download the datset or visit the link directly' style={{fontSize:11}}> <FaLink size={7} /> Link</a> </li>
                                                          
                                    }
                                    return <li style={{color: '#990000'}}><Link to={{pathname:'/details/dataset', state:{id: link.code, dataset_name: link.name}}}   href='#'   >{link.name}</Link>, <br/> <a target='_blank' href={link.url} title='Download the datset or visit the link directly' style={{fontSize:11}}> <FaLink size={7} /> Link</a> </li>
                                })
                            }
                            </ol>
                        </div>
                        <div className="account-avatar">
                        {/* <Avatar sx={{ height:120, width:120, fontSize:26 }}>P I</Avatar> */}
                        </div>
                </div>
                <div className="account-main-info">
                       
                        <div className="account-actions">
                             <Button  variant="outlined" onClick={openPopupHandler} color="primary">
                               {loadingUpdates?"Updating..":"Save changes"}
                            </Button>
    
                            <Button onClick={handleClose} variant="outlined" color="secondary">
                                Go back
                            </Button>
                        </div>
                        <div className="account-avatar">
                        {/* <Avatar sx={{ height:120, width:120, fontSize:26 }}>P I</Avatar> */}
                        </div>
                </div>
            </div>
         </div>
        )
}

export default AccountDetails