import React, { useState } from "react";
import styled from "styled-components";
import { MuiOtpInput } from "mui-one-time-password-input";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { POST_RESET_URL } from "../config/url";
import { AXIOS_POST } from "../config/axios";
import Translate from "../translate";
import Loader from "../../../helper/Loader";

const ResetPassword = ({ handleClose, handleLogin }) => {
  const [value, setValue] = useState("");
  const handleComplete = () => {};
  const handleChange = (value) => {
    setValue(value);
  };
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleNavigation = (path) => {
    history.push(path); // Replace '/path' with the desired URL or route
  };
  const onSubmit = (data) => {
    setLoading(true);
    AXIOS_POST(POST_RESET_URL, { ...data, otp: value })
      .then((res) => {
        toast.success("Your password changed successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
        setTimeout(() => handleLogin(), 2000);
      })
      .catch((errors) => {
        // console.error(errors);
        const message = errors.code === "ERR_NETWORK"?"Something went wrong! Please try again later.":errors.response.data.message
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
        setLoading(false);
      })
      .finally(setLoading(false));
  };
  return (
    <>
      <ToastContainer />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Title>
          <Translate Word={"reset-password"} />
          <p style={{ fontSize: "16px", fontWeight: "normal", margin: "0 5%" }}>
            <Translate Word={"enter-otp-code-sent"} />
          </p>
        </Title>
        {/* <OtpTitle>Enter Otp code sent below</OtpTitle> */}
        <InputOTP class="box">
          <Input
            length={5}
            onComplete={handleComplete}
            value={value}
            onChange={handleChange}
            // {...register("otp")}
          />
        </InputOTP>
        <InputContainer>
          <input
            type="Email"
            placeholder="Enter E-mail"
            {...register("email")}
          />
        </InputContainer>
        <InputContainer>
          <input
            type="password"
            placeholder="New Password"
            required
            {...register("newpassword")}
          />
        </InputContainer>
        <InputContainer>
          <input
            type="password"
            placeholder="Confirm password"
            required
            {...register("confirmpassword")}
          />
        </InputContainer>
        <ButtonContainer>
          <SubmitButton>
            {loading ? <Loader color="white" /> : <Translate Word={"submit"} />}
          </SubmitButton>
          <CancelButton type="button" onClick={handleClose}>
            <Translate Word={"cancel"} />
          </CancelButton>
        </ButtonContainer>
      </FormContainer>
    </>
  );
};

const FormContainer = styled.form`
  width: 380px;
  min-height: 365px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  transition: 0.4s ease-in-out;
  position: relative;
  &:hover {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    scale: 0.99;
  }
  svg {
    position: relative;
    align-self: center;
    top: 4em;
  }
`;
const Title = styled.p`
  position: relative;
  text-align: center;
  color: black;
  top: 1em;
  font-weight: bold;
`;
const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  input {
    position: relative;
    margin: 8px 5px;
    width: 70%;
    height: 2.4em;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: rgb(235, 235, 235);
    padding-left: 15px;
    transition: 0.4s ease-in-out;

    &:hover {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
      background-color: lightgrey;
    }
    &:focus {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
      background-color: lightgrey;
    }
  }
`;
const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  top: 0em;
  width: 100%;
  margin: 2%;
  justify-content: center;
  align-items: center;
`;
const SubmitButton = styled.button`
  position: relative;
  background-color: #01944D;
  margin-right: 13px;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;
  cursor: pointer;

  &:active {
    box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
  }
`;
const CancelButton = styled.button`
  margin-left: 13px;
  background-color: #989393;
  position: relative;
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;
  cursor: pointer;
  &:active {
    box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
  }
`;
const SignupText = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0px 20px;
  align-items: center;
  margin: 0;
  p {
    line-height: 0%;
  }
`;
const ForgetPassword = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  align-items: center;
  a {
    line-height: 1;
    border-bottom: 1px solid #01944D;
  }
`;
const InputOTP = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Input = styled(MuiOtpInput)`
  padding: 0;
  margin: 0;
  width: 70%;
`;
const OtpTitle = styled.p`
  line-height: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export default ResetPassword;
