/**
 * 
 * Something to change here, is the way we return the response without giving a status ?!?
 */



async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;
  
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal  
  });
  clearTimeout(id);
  return response;
}



class Api {
  
  constructor (){
    this.BASE_URL = process.env.REACT_APP_BASE_URL;
    this.ALTER_URL = process.env.REACT_APP_BASE_URL_LOCAL;
    this.isLocal = false;
    
  }
  getBaseUrL(){
    return (this.isLocal? this.ALTER_URL: this.BASE_URL);
  }
  async setBaseUrl (){
    try {
      // https://data.africa.cmu.edu/api/v1/data
      const response = await fetchWithTimeout(this.BASE_URL+"/data", {
        timeout: 7000
      });
      // const data = await response.json();
      if(response.ok){
        this.isLocal = false;
        return
      }
      this.isLocal = true;
    } catch (error) {
      this.isLocal = true;
     
    }
  }
  async getRequest(req) {
    let headers = {};
    if (req.data.token !== undefined) {
      headers = {
        "Content-Type": "application/json",
        Authorization: req.data.token,
      };
      
    } else {
      headers = { "Content-Type": "application/json" };
    }

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    await this.setBaseUrl()

    try {
      // console.log(this.isLocal)
      const response = await fetch(this.getBaseUrL()+ req.data.url,requestOptions).catch((error) => {
        return new Response({
          code: 500,
          message: "server error",
          status: false,
        });
      });

      const data = await response.json();
      return data;

    } catch (error) {
      return new Response({ code: 500, message: "server error" });
    }
  }
  async postRequest(req) {
    let headers = {};

    if (req.data.token !== undefined) {
      headers = {
        "Content-Type": "application/json",
        Authorization: req.data.token,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(req.data),
    };
    await this.setBaseUrl()

    try {
      const response = await fetch(
        this.getBaseUrL() + req.data.url,
        requestOptions
      ).catch((error) => {
        return new Response({
          code: 500,
          message: "server error",
          status: false,
        });
      });

      const data = await response.json();
      return data;
    } catch (error) {
    // console.log("+++++++++++", error)

      return new Response({
        code: 500,
        message: "server error",
        status: false,
      });
    }
  }


  async patchRequest(req) {
    let headers = {};

    if (req.data.token !== undefined) {
      headers = {
        "Content-Type": "application/json",
        Authorization: req.data.token,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }

    const requestOptions = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(req.data),
    };
    this.setBaseUrl()
    try {
      const response = await fetch(
        this.getBaseUrL() + req.data.url,
        requestOptions
      ).catch((error) => {
        return new Response({
          code: 500,
          message: "server error",
          status: false,
        });
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return new Response({
        code: 500,
        message: "server error",
        status: false,
      });
    }
  }



  async uploadRequest(req) {
    let headers = {};
    headers = {
      // headers: { Accept: "application/x-www-form-urlencoded" },
      // headers : {
        Accept: "application/x-www-form-urlencoded",
        Authorization: req.token,
      // }
    };

    const requestOptions = {
      method: "POST",
      body: req.formData,
      headers: headers,
    };
    this.setBaseUrl()
    try {
      const response = await fetch(this.getBaseUrL() + req.url, requestOptions).catch(
        (error) => {
          return new Response({
            code: 500,
            message: "server error",
            status: false,
          });
        }
      );

      const data = await response.json();

      return data;
    } catch (error) {
      return new Response({
        code: 500,
        message: "server error",
        status: false,
      });
    }
  }
}

export default Api
