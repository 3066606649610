import React from "react";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
const CategoriesCard = (props) => {
  const history = useHistory();
  const handleClick = (category) => {
    try {
      history.push({
        pathname: "/filter",
        state: { category: category, searchValue: category },
      });
    } catch (error) {
      // console.error("Error navigating to filter page:", error);
    }
  };
  return (
    <Container class="cat-card">
      <HoverLink
        href=""
        class="cat-card1"
        onClick={(e) => {
          e.preventDefault();
          handleClick(props.category);
        }}
      >
        <HoverTitle>{props.title}</HoverTitle>
        <MinTitle class="cat-small">{props.desc}.</MinTitle>
        <CatGoCorner class="cat-go-corner" href="#">
          <CatGoArrow class="cat-go-arrow">→</CatGoArrow>
        </CatGoCorner>
      </HoverLink>
    </Container>
  );
};
const Container = styled.div`
width: 100%;
  a {
    display: block;
    position: relative;
    max-width: 262px;
    background-color: #cddddf;
    border-radius: 4px;
    color: black;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
  }
  @media screen and (max-width: 768px) {
    a {
      max-width: 100%;
      padding: 10px;
      margin: 10px;
    }
    a:before{
    }
  }
  a:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #01944D;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }
  a:hover:before {
    transform: scale(21);
  }
  a:hover Title {
    transition: all 0.3s ease-out;
    color: white;
  }
  a:hover MinTitle {
    transition: all 0.3s ease-out;
    color: white;
  }
  a:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
  }
`;
const Title = styled.p`
  p {
    font-size: 25px;
    font-weight: bold;
    line-height: 20px;
    color: #666;
  }
`;
const HoverTitle = styled(Title)`
  text-transform: uppercase;
  &:hover {
    transition: all 0.3s ease-out;
    color: white;
  }
`;
const HoverLink = styled(Link)`
  &:hover {
    transition: all 0.3s ease-out;
    color: white;
  }
`;
const MinTitle = styled.p`
  font-size: 14px;
`;
const CatGoCorner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #01944D;
  border-radius: 0 4px 0 32px;
`;
const CatGoArrow = styled.div`
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
`;
export default CategoriesCard;
