export const stringContains = (toverify, theString)=>{
    if(theString.includes(toverify)){
        return true;
    }else{
        return false;
    }
}

export const replaceString = (toReplace, substitute, theString, component)=>{
   
        let theNewString =  theString.replace(toReplace, substitute)
        // console.log(substitute)
        if(component){
            return <div>{theNewString} {component}</div>
        }
        return theNewString;
    // }
    // return theString.replace(toReplace, substitute)
}

export const stringContainsReplace =(toverify, theString, substitute, component)=>{
    if(stringContains(toverify, theString)){
        let toReplace = toverify;
    
        return replaceString(toReplace, substitute, theString, component);
    }
    return theString;
}

export const splitName =(name)=>{
        if(name){
            let initialOne = name.split(' ')[0][0];
            return initialOne;
        }
        return ""
}