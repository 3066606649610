// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container{
    width: 80%;
}
.custom-table{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/new_components/custom_table/table.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,WAAW;AACf","sourcesContent":[".table-container{\n    width: 80%;\n}\n.custom-table{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
