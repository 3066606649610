import React from "react";
import styled from "styled-components";
import logo from "../Upanzi Network logo.png";
import MailIcon from "@mui/icons-material/Mail";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import Translate from "../translate";
const Footer = () => {
  return (
    <FooterContainer>
      <Container>
        <LogoContainer>
          <img src={logo} alt="logo" />
          <br />
          <p>OPEN DATA PORTAL</p>
        </LogoContainer>
        <ContactContainer>
          <Title>Contacts</Title>
          <ContactItems>
            <p>
              <IconMail />
              <span>upanzi@andrew.cmu.edu</span>
            </p>
            <p>
              <IconGuide />
              <span>
                <Translate Word={"read-more-on-cylab"} />{" "}
                <a href="https://www.africa.engineering.cmu.edu/research/cylab/index.html">
                  <Translate Word={"here"} />
                </a>
              </span>
            </p>
          </ContactItems>
        </ContactContainer>
        <AboutUsContainer>
          <Title>
            <Translate Word={"about-us"} />
          </Title>
          <p>
            <Translate Word={"open-data-portal-product"} />{" "}
            <span>Cylab Africa/Upanzi network</span>,{" "}
            <Translate Word={"about-us-desc"} />
          </p>
        </AboutUsContainer>
      </Container>
      <SocialMediaContainer>
        <p>
          <Translate Word={"follow-us-on-social-media"} />
        </p>
        <SocialMedia>
          {/* <a href="">
            <IconFacebook />
          </a> */}
          <a
            target="_blank"
            href="https://twitter.com/CyLabAfrica"
            rel="noreferrer">
            <IconTwitter />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/cylabafrica/mycompany/verification/"
            rel="noreferrer">
            <IconLinkedIn />
          </a>
          <a
            target="_blank"
            href="https://github.com/cylab-africa"
            rel="noreferrer">
            <IconGithub />
          </a>
        </SocialMedia>
      </SocialMediaContainer>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #d0cdcd;
  padding: 2% 2% 0% 2%;
  border-bottom: 1px solid black;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  img {
    height: 100px;
    width: auto;
  }
  p {
    padding: 0%;
    transform: scale(1.1);
    font-weight: bolder;
    line-height: 10px;
    color: #01944d;
    font-size: 16px;
  }
`;

const ContactContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
`;
const ContactItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  p {
    font-size: 16px;
    font-weight: 500;
  }
`;
const AboutUsContainer = styled.div`
  flex: 1;
  p span {
    color: #01944d;
    font-weight: 600;
  }
`;
const IconMail = styled(MailIcon)`
  color: #01944d;
  vertical-align: bottom;
  margin: 0 5px;
`;
const IconGuide = styled(MenuBookIcon)`
  color: #01944d;
  vertical-align: bottom;
  margin: 0 5px;
`;
const SocialMediaContainer = styled.div`
  background-color: #d0cdcd;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  p {
    font-weight: bold;
    font-size: medium;
  }
`;
const SocialMedia = styled.div`
  line-height: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
`;
const IconGithub = styled(GitHubIcon)`
  cursor: pointer;
  margin: 0 5px;
  font-size: 30px;
  color: #01944d;
  vertical-align: bottom;
`;
const IconFacebook = styled(FacebookIcon)`
  cursor: pointer;
  margin: 0 5px;
  font-size: 30px;
  color: #01944d;
  vertical-align: bottom;
`;
const IconTwitter = styled(TwitterIcon)`
  cursor: pointer;
  margin: 0 5px;
  font-size: 30px;
  color: #01944d;
  vertical-align: bottom;
`;
const IconLinkedIn = styled(LinkedInIcon)`
  cursor: pointer;
  margin: 0 5px;
  font-size: 30px;
  color: #01944d;
  vertical-align: bottom;
`;
