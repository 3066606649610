import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  autoLogin,
  logout as reduxLogout,
} from "../../../app/features/authenticationSlice";
import { reset as resetDashboard } from "../../../app/features/dashboard";
import { useWindowSize } from "../../../utils/cutomHooks";
import "./navbar.css";
// import Fade from "react-reveal/Fade";
import { splitName } from "../../../utils/stringUtils";
import { HiMenu } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { FaHome } from "react-icons/fa";
import { AiOutlineDashboard, AiOutlinePlusCircle } from "react-icons/ai";
import {
  RiSendPlaneFill,
  RiFolderReceivedFill,
  RiAccountPinCircleFill,
} from "react-icons/ri";
import { CgLogOut, CgLogIn } from "react-icons/cg";

import Search from "../search_bar/search";
import Filters from "../filters/filters";
import { getAccount, isAdmin } from "../../../utils/localStorageUtil";

const NavBar = (props) => {
  const { showAdminLogout, hideAllLinks, isAdminPage, showSearch, removeSearch, sometext, stopSearch, hideBarner, searchHanler, hideUpload } =
    props;
  // const auths = useSelector((state) => state.authentication);
  const auths = getAccount()
  // console.log(auths)
  // console.log(authsTwo)
  const textBarnner = sometext
    ? sometext
    : "The Open Data Platform is a platform for faculty, students, staff, and others willing to share datasets from surveys or post links to data-rich websites.";
  const [showSideNav, setShowSideNav] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [onLargePage, setOnLargePage] = useState(true);
  const [width, height] = useWindowSize();
  const [activeLink, setActiveLink] = useState({
    "/profile": false,
    "/": false,
    "/dashboard": false,
    "/access": false,
    "/requests": false,
    "/signup": false,
    "/signin": false,
  });

  // Search filters
  const [isPrivate, setIsPrivate] = useState(false);
  const [fileType, setFileType] = useState("all");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showFilters, setShowFilters] = useState(false);

  const iConColrs = {
    active: "#990000",
    disabled: "#6e6b6b",
  };
  const openNav = (e) => {
    document.getElementById("nav").hidden = false;
  };

  const closeNav = (e) => {
    document.getElementById("nav").hidden = true;
  };

  const activateLink = () => {
    const currentPage = history.location.pathname;
    for (let key in activeLink) {
      // activeLink[key] =
      if (key === currentPage) {
        setActiveLink({ ...activeLink, [key]: true });

        // break;
      }
    }
  };
  const automatiCloseBar = useCallback(() => {
    if (width > 800) {
      setShowSideNav(false);
      setOnLargePage(true);
    } else {
      setOnLargePage(false);
    }
  }, [width]);

  useEffect(() => {
    activateLink();
  }, []);
  useEffect(() => {
    automatiCloseBar();
  }, [automatiCloseBar]);

  // const navogate = () => {
  //   // setShowSideNav(!showSideNav);
  // };

  const navigateTo = (page, state) => {
    if (state === undefined) {
      history.push(page);
    }
  };

  const getUsername = () => {
    // console.log(auths)
    
    return splitName(auths.username);
  };
  const isNormalUser = () => {
    const isUserLoggedIn = localStorage.getItem("authUser");
    if (auths && !isAdmin()) {
      return true;
    } else if (isUserLoggedIn && !auths) {
      dispatch(autoLogin());
    }
    return false;
  };

  const isLoggedIn = () => {
    const isUserLoggedIn = localStorage.getItem("authUser");
    if (isUserLoggedIn && auths) {
      return true;
    } else if (isUserLoggedIn && !auths) {
      dispatch(autoLogin());
    }
    return false;
  };


  const logout = async () => {
    if (window.confirm("Do you want to log out?")) {
      dispatch(resetDashboard());
      // window.location.reload();
      // history.go(0)
      if(isAdmin()){
        localStorage.clear();
        setTimeout(() => {}, 5000);
        navigateTo("/admin");
  
      }else{
        localStorage.clear();
        dispatch(reduxLogout());
        setTimeout(() => {}, 5000);
        navigateTo("/signin");
  
      }
      
    }
  };
  // console.log(activeLink)

  if(isAdminPage){
    return (
      <div className="m-nav">
        <div style={{padding:10}} className="m-navigation">
          <h4
            onClick={() => {
              navigateTo("/");
            }}
            className="logo-color m-logo"
          >
            Open Data Portal
          </h4>

          <ul  className="nav">
            <li hidden={!showAdminLogout} style={{marginRight:15}} className="nav-item">
              <a onClick={logout} href="">Logout</a>
            </li>
            </ul>
           
            </div>
            <div hidden={hideBarner} className="m-details">
            <div
              className="row"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
             <p
                style={{
                  textAlign: "center",
                  marginLeft: 40,
                  marginRight: 40,
                  color: "#fff",
                }}
              >
                {textBarnner}
              </p> 
               
            </div>
            </div>
            </div>
    )  
  }
 

  return (
    <>
      <div className="m-nav">
        <div className="m-navigation">
          <h4
            onClick={() => {
              navigateTo("/");
            }}
            className="logo-color m-logo"
          >
            Open Data Portal
          </h4>

          <ul  className="nav">
            <li hidden={!showAdminLogout} style={{marginRight:15}} className="nav-item">
              <a onClick={logout} href="">Logout</a>
            </li>
            {!isNormalUser() &&(
              <li hidden={hideAllLinks} className="nav-item">
                <a
                  onClick={() => {
                    if(isAdmin()){
                      navigateTo("/admin");
                    }else{
                      navigateTo("/signin");
                    }
                  }}
                  className="nav-link m-nav-text"
                  href="#"
                >
                  Signin
                </a>
              </li>
            )}
            {!isLoggedIn() &&  (
              <li hidden={hideAllLinks} className="nav-item">
                <a
                  onClick={() => {
                    navigateTo("/signup");
                  }}
                  className="nav-link"
                  href="#"
                >
                  Signup
                </a>
              </li>
            )}
          </ul>
          {isNormalUser() && (
            <div hidden={hideAllLinks} className="nav-item-profile ">
              <div
                onClick={() => {
                  navigateTo("/profile");
                }}
                className="profile-container"
              >
                <div className="m-nav-profile-diplayname">
                  <p style={{ textTransform: "capitalize", marginTop: 20 }}>
                    {getUsername()}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        <div hidden={hideBarner} className="m-details">
          {isNormalUser() ? (
            <div className="m-row">
              <div className="m-menu-block">
                <HiMenu
                  onClick={openNav}
                  className="m-details-menu-icon"
                  size={30}
                />
              </div>
              <div className="m-serach-block">
                {!removeSearch && showSearch && (
                  <Search
                    searchHanler={searchHanler}
                    stopSearch={stopSearch}
                    isPrivate={isPrivate}
                    showFilters={showFilters}
                    setShowFilters={(val) => {
                      setShowFilters(val);
                    }}
                    setIsPrivate={(val) => {
                      setIsPrivate(val);
                    }}
                    fileType={fileType}
                    setFileType={(val) => {
                      setFileType(val);
                    }}
                    startDate={startDate}
                    setStartDate={(val) => {
                      setStartDate(val);
                    }}
                    endDate={endDate}
                    setEndDate={(val) => {
                      setEndDate(val);
                    }}
                  />
                )}
              </div>
            </div>
          ) : (
            <div
              className="row"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <p
                style={{
                  textAlign: "center",
                  marginLeft: 40,
                  marginRight: 40,
                  color: "#fff",
                }}
              >
                {textBarnner}
              </p> */}
              {showSearch ? (
                  <Search
                    searchHanler={searchHanler}
                    stopSearch={stopSearch}
                    isPrivate={isPrivate}
                    showFilters={showFilters}
                    setShowFilters={(val) => {
                      setShowFilters(val);
                    }}
                    setIsPrivate={(val) => {
                      setIsPrivate(val);
                    }}
                    fileType={fileType}
                    setFileType={(val) => {
                      setFileType(val);
                    }}
                    startDate={startDate}
                    setStartDate={(val) => {
                      setStartDate(val);
                    }}
                    endDate={endDate}
                    setEndDate={(val) => {
                      setEndDate(val);
                    }}
                  />
                ):<p
                style={{
                  textAlign: "center",
                  marginLeft: 40,
                  marginRight: 40,
                  color: "#fff",
                }}
              >
                {textBarnner}
              </p> }
               
            </div>
          )}
          {isNormalUser() && !hideUpload &&
          <div style={{ width:150}}>
          <button  onClick={()=>{history.push("/edit")}} className="btn btn-upload"> <AiOutlinePlusCircle style={{ marginRight: 4, marginBottom:3.5 }} /> UPLOAD</button>
          </div>}
        </div>
        

        {/* side nav */}

        <div hidden id="nav" className="fade-in sidenav-main ">
          <div id="side-menu" className="slide-right side-menu ">
            <div
              className="sidenav-logo"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h4
                onClick={() => {
                  navigateTo("/");
                }}
                className="m-logo logo-color"
              >
                Open Data Portal
              </h4>
              {/* <div className="sidenav-close"> */}
              <IoMdClose
                onClick={closeNav}
                size={27}
                className="sidenav-close-icon close-icon"
                // size={170}
              />
              {/* </div> */}
            </div>
            <div className="sidenav-menu-list">
              <ul>
                <li
                  style={{ display: "flex", alignItems: "center" }}
                  className="nav-item"
                >
                  <FaHome
                    color={
                      !activeLink["/"] ? iConColrs.active : iConColrs.disabled
                    }
                    style={{ marginTop: -4 }}
                    size={15}
                  />
                  {!activeLink["/"] ? (
                    <a
                      onClick={() => {
                        navigateTo("/");
                      }}
                      className="nav-link"
                      href="#"
                    >
                      Home
                    </a>
                  ) : (
                    <div className="nav-item link-active">Home</div>
                  )}
                </li>

                {!isNormalUser() && (
                  <li className="nav-item">
                    {!activeLink["/signin"] ? (
                      <a
                        onClick={() => {
                          navigateTo("/signin");
                        }}
                        className="nav-link m-nav-text"
                        href="#"
                      >
                        Signin
                      </a>
                    ) : (
                      <div className="nav-item link-active">Signin</div>
                    )}
                  </li>
                )}
                {!isNormalUser() && (
                  <li
                    style={{ display: "flex", alignItems: "center" }}
                    className="nav-item"
                  >
                    <FaHome
                      color={
                        !activeLink["/signup"]
                          ? iConColrs.active
                          : iConColrs.disabled
                      }
                      style={{ marginTop: -4 }}
                      size={15}
                    />
                    {!activeLink["/signup"] ? (
                      <a
                        onClick={() => {
                          navigateTo("/signup");
                        }}
                        className="nav-link"
                        href="#"
                      >
                        Signup
                      </a>
                    ) : (
                      <div className="nav-item link-active">Signup</div>
                    )}
                  </li>
                )}

                {isNormalUser() && (
                  <li
                    style={{ display: "flex", alignItems: "center" }}
                    className="nav-item"
                  >
                    <AiOutlineDashboard
                      color={
                        !activeLink["/dashboard"]
                          ? iConColrs.active
                          : iConColrs.disabled
                      }
                      style={{ marginTop: -4 }}
                      size={15}
                    />
                    {/* <GrDashboard size={} /> */}
                    {!activeLink["/dashboard"] ? (
                      <a
                        onClick={() => {
                          navigateTo("/dashboard");
                        }}
                        className="nav-link"
                        href="#"
                      >
                        Dashboard
                      </a>
                    ) : (
                      <div className="nav-item link-active">Dashboard</div>
                    )}
                  </li>
                )}

                {isNormalUser() && (
                  <li
                    style={{ display: "flex", alignItems: "center" }}
                    className="nav-item"
                  >
                    <RiSendPlaneFill
                      color={
                        !activeLink["/requests"]
                          ? iConColrs.active
                          : iConColrs.disabled
                      }
                      style={{ marginTop: -4 }}
                      size={15}
                    />
                    {!activeLink["/requests"] ? (
                      <a
                        onClick={() => {
                          navigateTo("/requests");
                        }}
                        className="nav-link"
                        href="#"
                      >
                        Requests you sent
                      </a>
                    ) : (
                      <div className="nav-item link-active">
                        Requests you sent
                      </div>
                    )}
                  </li>
                )}

                {isNormalUser() && (
                  <li
                    style={{ display: "flex", alignItems: "center" }}
                    className="nav-item"
                  >
                    <RiFolderReceivedFill
                      color={
                        !activeLink["/access"]
                          ? iConColrs.active
                          : iConColrs.disabled
                      }
                      style={{ marginTop: -4 }}
                      size={15}
                    />
                    {!activeLink["/access"] ? (
                      <a
                        onClick={() => {
                          navigateTo("/access");
                        }}
                        className="nav-link"
                        href="#"
                      >
                        Requests you recieved
                      </a>
                    ) : (
                      <div className="nav-item link-active">
                        Requests you recieved
                      </div>
                    )}
                  </li>
                )}

                {isNormalUser() && (
                  <li
                    style={{ display: "flex", alignItems: "center" }}
                    className="nav-item"
                  >
                    <RiAccountPinCircleFill
                      color={
                        !activeLink["/profile"]
                          ? iConColrs.active
                          : iConColrs.disabled
                      }
                      style={{ marginTop: -4 }}
                      size={15}
                    />
                    {!activeLink["/profile"] ? (
                      <a
                        onClick={() => {
                          navigateTo("/profile");
                        }}
                        href="#"
                        className="nav-link"
                      >
                        Profile
                      </a>
                    ) : (
                      <div className="nav-item link-active">Profile</div>
                    )}
                  </li>
                )}

                {isNormalUser() && (
                  <li
                    style={{ display: "flex", alignItems: "center" }}
                    className="nav-item"
                  >
                    <CgLogOut
                      color={
                        !activeLink["/signin"]
                          ? iConColrs.active
                          : iConColrs.disabled
                      }
                      style={{ marginTop: -4 }}
                      size={15}
                    />
                    <a onClick={logout} href="#" className="nav-link">
                      Log out
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div onClick={closeNav} className="sidenav-close ">
            <IoMdClose
              hidden
              onClick={closeNav}
              className="sidenav-close-icon close-icon"
              // size={170}
            />
          </div>
        </div>
      </div>

      {showFilters && (
        <Filters
          isPrivate={isPrivate}
          setIsPrivate={(val) => {
            setIsPrivate(val);
          }}
          fileType={fileType}
          setFileType={(val) => {
            setFileType(val);
          }}
          startDate={startDate}
          setStartDate={(val) => {
            setStartDate(val);
          }}
          endDate={endDate}
          setEndDate={(val) => {
            setEndDate(val);
          }}
        />
      )}
    </>
  );
};

export default NavBar;
