import React, { useState } from "react";
import "./filters.css";
import { useDispatch, useSelector } from "react-redux";

const Filters = (props) => {
  const {
    fileType,
    setFileType,
    isPrivate,
    setIsPrivate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = props;
  const [minimized, setMinimized] = useState(false);

  const chekIfPrivate = () => {
    setIsPrivate(!isPrivate);
  };

  const addStartDate = (e) => {
    // console.log(e.target.value)
    document.getElementById("endDate").min = e.target.value;
    setStartDate(e.target.value);
  };

  const addEndDate = (e) => {
    // console.log(e.target.value)
    setEndDate(e.target.value);
  };

  const reset = () => {
    setFileType("all");
    setIsPrivate(false);
    setStartDate();
    setEndDate();
  };

  const minimize = (e) => {
    const body = document.getElementById("body");
    const header = document.getElementById("header");

    if (minimized) {
      body.classList.remove("filter-content-mini");
      header.classList.remove("modal-header-mini");
      e.target.classList.remove("close-up-reversed");
      setMinimized(false);
    } else {
      body.classList.add("filter-content-mini");
      header.classList.add("modal-header-mini");
      e.target.classList.add("close-up-reversed");
      setMinimized(true);
    }
  };

  return (
    <div class="modal-content">
      <div class="modal-body">
        <div id="header" class="modal-header">
          <h4>Filter datasets</h4>
          {/* <span onClick={minimize} class="close">
            ^
          </span> */}
          <span onClick={minimize}  className="close-up">^</span>
        </div>
        <div id="body" className="row ">
          <div className="col-md-3 search-filter-block">
            <p className="label" htmlFor="">
              PRIVATE DATASETS
            </p>
            <input
              onChange={chekIfPrivate}
              type="checkbox"
              name=""
              id="isPrivate"
            />
          </div>

          <div className="col-md-4">
            <p
              style={{ color: "black", textAlign: "left", fontWeight: "bold" }}
            >
              FILE TYPE:
            </p>
            <div className="m-search-radio-container">
              <div className="m-search-radio-group">
                <p className="label" htmlFor="javascript">
                  ALL TYPES
                </p>
                <input
                  className="m-search-radio-box"
                  type="radio"
                  name="fileType"
                  onClick={() => setFileType("all")}
                />
              </div>
              <div className="m-search-radio-group">
                <p className="label">FILE</p>
                <input
                  className="m-search-radio-box"
                  type="radio"
                  name="fileType"
                  onClick={() => setFileType("file")}
                />
              </div>
              <div className="m-search-radio-group">
                <p className="label">LINK</p>
                <input
                  className="m-search-radio-box"
                  type="radio"
                  onClick={() => setFileType("link")}
                  name="fileType"
                />
              </div>
            </div>
          </div>
          <div className="col-md-5 m-search-dates-container">
            <label className="label" htmlFor="startDate">
              Start date:
            </label>
            <input
              onChange={addStartDate}
              className=" form-control"
              type="date"
              id="startDate"
              name="startDate"
            />
            <label className="label" htmlFor="endDate">
              End date:
            </label>
            <input
              onChange={addEndDate}
              className=" form-control"
              type="date"
              id="endDate"
              name="endDate"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
