import ApiAdmin from "./admin/ApiAdmin";
import Api from "./Api";

export class AunthenticationService extends Api {
  constructor(user) {
    super();
    this.user = user;
  }

  updateProfile =(newName, affiliation, token)=>{
    const data = {
      data:{
        url:"/users/update-profile",
        token: token,
        name: newName,
        affiliation:affiliation
      }
    }  
    const response = this.patchRequest(data);
    return response;
  }

  changePassword =(oldpassword, newpassword, confirmpassword, token)=>{
    const data = {
      data:{
        url:"/users/changePassword",
        token: token,
        oldpassword: oldpassword,
        newpassword:newpassword,
        confirmpassword:confirmpassword

      }
    }  
    const response = this.patchRequest(data);
    return response;
  }
  signinUser = () => {
    const data = {
      data: {
        url: "/login",
        email: this.user.email,
        password: this.user.password,
      },
    };

    const response = this.postRequest(data);
    return response;
  };

  verifyAccount= async(formData)=>{
    const data = {
      data: {
        url: "/users/officialize",
        supervisor_name: formData.supervisor_name,
        supervisor_email: formData.supervisor_email,
        job_title: formData.job_title,
        token: formData.token,
      },
    };

    const response = await this.postRequest(data);
    return response;
  }
}
  

