import React from 'react'
import { useEffect } from 'react';
import "./messages.css"

const Messages = (props) => {
  const {message} = props;
  // console.log(message)

  

  return (
    <div  className="m-messages">
          {message?
            <p style={{textAlign:'center', fontWeight:'bold'}}>{message}</p>:
          <div>
            
     {/*     <h5>Make Africa the world's leading AI laboratory</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Malesuada fames ac turpis egestas integer eget. Venenatis urna
            cursus eget nunc scelerisque viverra mauris in. Sed elementum tempus
            egestas sed.
          </p> */}

      <div id="carouselExampleSlidesOnly" data-interval={15000} class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
          <h5>Post data platform</h5>
          <p className='m-messages-content'>
          “Where there is data smoke, there is business fire.” 
          <strong>— Thomas Redman</strong>
          <br />
          <a target="_blank" title='Go to www.pipeline.zoominfo.com' className='m-messages-quotation' href="https://pipeline.zoominfo.com/operations/19-inspirational-quotes-about-data">~pipeline.zoominfo.com</a>
          </p>
          </div>
          <div class="carousel-item">
          <h5>Did you know?</h5>
          <p className='m-messages-content'>
          For a Fortune 1000 enterprise, only a 10 percent increase in the accessibility of data will lead to net additional revenue of 65 million dollars
          <br />
          <a target="_blank" title='Go to www.simplilearn.com' className='m-messages-quotation' href="https://www.simplilearn.com/data-science-facts-article">~simplilearn.com</a>
          </p>
          </div>
          <div class="carousel-item">
          <h5>Data analytics to better compete </h5>
          <p className='m-messages-content'>
          47 percent of organizations believe that data analytics has fundamentally or significantly transformed how their industries compete 
          <br />
          <a target="_blank" title='Go to www.simplilearn.com' className='m-messages-quotation' href="https://www.simplilearn.com/data-science-facts-article">~simplilearn.com</a>
          </p>
          </div>
        </div>
      </div>

     
          </div>
          }
      </div>
  )
}

export default Messages