import React, { useRef, useState } from "react";
import styled from "styled-components";
import UserLayout from "../layouts/UserLayout";
import { ToastContainer, toast } from "react-toastify";
import { RichTextEditor } from "../components/RichTextEditor";

const PrivacyUpdate = () => {
  const editorRef = useRef(null);
  return (
    <UserLayout isPrivacyUpdate = {true}>
      <ToastContainer />
      <Container>
        <RichTextEditor ref={editorRef} height = {500}/>
      </Container>
    </UserLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-around;
  padding: 2%;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export default PrivacyUpdate;
