import React, { useState } from "react";
import {
  DataGrid,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import styled from "styled-components";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import DatasetView from "./Datasetview";
import MuiPagination from '@mui/material/Pagination';
import Translate from "../translate";

const IconMore = styled(ReadMoreIcon)`
  vertical-align: bottom;
  cursor: pointer;
  color: #fa8518;
  font-weight: bold;
`;
const Container = styled.div`
  height: 400px;
  width: 90%;
  margin-top: 20px;
`;



export default function DataTable({ data, hasMore, getMoreData }) {
  // const [page, setPage] = useState(1)
  // const pageSize = Math.round(data.length/5)
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [rowData, setRowData] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const columns = [
    { field: "Title", headerName: <Translate Word={"dashboard-title"} />, width: 400 },
    { field: "Source", headerName: <Translate Word={"dashboard-source"} />, width: 100 },
    {
      field: "Views",
      headerName: <Translate Word={"dashboard-views"} />,
      width: 100,
    },
    {
      field: "Downloads",
      headerName: <Translate Word={"dashboard-downloads"} />,
      sortable: false,
      width: 100,
    },
    {
      field: "Requests",
      headerName: <Translate Word={"dashboard-requests"} />,
      sortable: false,
      width: 100,
    },
    
  ];
 
  columns.push(!isMobile && {
    field: "More",
    headerName: <Translate Word={"dashboard-more"} />,
    width: 100,
    renderCell: (params) => (
      <IconMore
        onClick={() => handleOpen(data.find((d) => d.code === params.row.id))}
      />
    ),
  })


  function Pagination({
    page,
    onPageChange,
    className,
  }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <MuiPagination
        // color="#db0718"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={async (event, newPage) => {
          onPageChange(event, newPage - 1);
          if(pageCount === (newPage) & hasMore){
            setLoading(true)
            await getMoreData()
            setLoading(false)

          }

        }}
      />
    );
  }
  
  function CustomPagination(props) {
      return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  const handleOpen = (rowData) => {
    // console.log("_______________________________________", rowData);
    setRowData(rowData);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRowData(null);
  };
  const getRowId = (row) => row.id;
  return (
    <Container>
      {rowData && (
        <DatasetView
          open={open}
          getRowId={getRowId}
          handleClose={handleClose}
          handleOpen={handleOpen}
          data={rowData}
          isAuthor={true}
        />
      )}
      <DataGrid
        rows={data}
        columns={columns}
        loading={loading}
        pagination
        slots={{
          pagination: CustomPagination
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize:5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </Container>
  );
}
