import React, { useState, useEffect } from "react";
import UserLayout from "../layouts/UserLayout";
import styled from "styled-components";
import RequestTable from "../components/RequestTable";
import { Chip } from "@mui/material";
import axios from "axios";
import moment from "moment/moment";
import Translate from "../translate";

const SentRequests = () => {
  const [sentRequestsstatus, setSentRequestsStatus] = useState("all");
  const [loading, setLoading] = useState(false);
  const [loadingPagination, setLoadingPagination ] = useState(false);
  const [sentRequests, setSentRequests] = useState([]);
  const [filteredSentRequests, setFilteredSentRequests] = useState(sentRequests);
  const [cursor, setCursor] = useState()
  const [hasNext, setHasNext] = useState(false)
  const token = sessionStorage.getItem("token");


  const getMoreData = async () =>{
    try {
      setLoadingPagination(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/datasets/requests/all/requester?limit=20&cursor=${cursor}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setCursor(response.data.paging.nextCursor)
      setHasNext(response.data.paging.hasMore)
      const responseData = response.data.data.map((item) => {
        const { code, to, file, sentOn, ...rest } = item;
        return {
          id: code,
          to: to.username,
          file: file.name,
          sentOn: moment(sentOn).format("MM/DD/YYYY HH:MM"),
          ...rest,
        };
      });
      setSentRequests([...sentRequests ,...responseData]);
      setFilteredSentRequests([...sentRequests ,...responseData]);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoadingPagination(false);
    }
  }


  const getSentRequests = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/datasets/requests/all/requester?limit=20`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setCursor(response.data.paging.nextCursor)
      setHasNext(response.data.paging.hasMore)
      const responseData = response.data.data.map((item) => {
        const { code, to, file, sentOn, ...rest } = item;
        return {
          id: code,
          to: to.username,
          file: file.name,
          sentOn: moment(sentOn).format("MM/DD/YYYY HH:MM"),
          ...rest,
        };
      });
      setSentRequests(responseData);
      setFilteredSentRequests(responseData);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSentRequests();
    return () => {
      setSentRequests();
    };
  }, []);

  useEffect(() => {
    const filteredData =
      sentRequestsstatus === "all"
        ? sentRequests
        : sentRequests.filter((row) => row.status === sentRequestsstatus);
    setFilteredSentRequests(filteredData);
  }, [sentRequestsstatus]);

  const handleSentRequestsTabChange = (event, newStatus) => {
    setSentRequestsStatus(newStatus);
    // console.log("Status changed");
  };

  const columns = [
    // { field: 'id', headerName: 'ID', width: 200 },
    { field: "to", headerName: <Translate Word={"outgoing-request-to"} />, flex: 0.7, minWidth: 200 },
    { field: "file", headerName: <Translate Word={"outgoing-request-file"} />, flex: 1, minWidth: 300 },
    { field: "sentOn", headerName: <Translate Word={"outgoing-request-senton"} />, flex: 0.5, minWidth: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        const status = params.value;
        return status === "approved" ? (
          <Chip label={"APPROVED"} color="success" variant="outlined" />
        ) : status === "rejected" ? (
          <Chip label={"REJECTED"} color="error" variant="outlined" />
        ) : (
          <Chip label={"PENDING"} color="warning" variant="outlined" />
        );
      },
    },
    
  ];
  return (
    <UserLayout isSentRequest={true}>
      <>
        <Container>
          <Title>
            <h2><Translate Word={"requests-sent"} /></h2>
          </Title>
          <TableContainer>
            <RequestTable
              hasMore={hasNext}
              loadingPagination={loadingPagination}
              getMoreData={getMoreData}
              loading={loading}
              status={sentRequestsstatus}
              handleTabChange={handleSentRequestsTabChange}
              columns={columns}
              rows={filteredSentRequests}
              type="sent"
            />
          </TableContainer>
        </Container>
      </>
    </UserLayout>
  );
};
const Title = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 20px;
    color: #01944D;
    /* font-weight: bold; */
    font-family: "Bebas Neue", cursive;
  }
`;
const Container = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TableContainer = styled.div`
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  h2 {
    font-size: 20px;
    color: #01944D;
    /* font-weight: bold; */
    font-family: "Bebas Neue", cursive;
  }
`;

export default SentRequests;
