// User account
export const POST_REGISTER_URL = "/signup";
export const POST_VERIFY_URL = "/signup/confirm-otp";
export const POST_LOGIN_URL = "/login";
export const POST_CHANGE_URL = "/auth/change-password";
export const POST_RESET_URL = "/resetPassword/confirm-otp";
export const POST_OTP_VERIFICATION = "/signup/confirm-otp";
export const POST_FORGET_URL = "/resetPassword";
export const GET_LANDING = "/landing";
export const DATASET = "/datasets";
export const GET_PROFILE = "/users/";
export const PATCH_CHANGE_PASSWORD = "/users/changePassword";
export const SUGGESTION = "/suggestions";
