import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IoMdArrowDropdown } from "react-icons/io";
import Paper from "@mui/material/Paper";
import {
  getVerificationRequests,
  officializeAccount,
} from "../../../../services/admin/AdminDataAPI";
import { getTokenFromLocalStorage } from "../../../../utils/localStorageUtil";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import CustomSearchBar from "../CustomSearchBar";
import { SnackbarProvider, useSnackbar } from 'notistack';


const DetailsPage = ({ reject, accept, isOpen, close, data, loading }) => {
  const handleClose = () => {
    close(false);
  };


  const messages ={
    "invalid":{
      "title": "This account has been deleted.",
      "body":""
    },
    "verify":{
      "title":"VERIFY A USER ACCOUNT",
      "body": ""
    }
  }
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{data.invalid?messages.invalid.title:messages.verify.title}</DialogTitle>
      <DialogContent hidden={data.invalid }>
        <DialogContentText hidden={data.requestStatus !== "unapproved"}>
          By selecting <b>ACCEPT</b>, we will enable user verification on our
          website for the account with the following details.
        </DialogContentText>
        <br />
        <table>
          <tr>
            <td>The applicatnt </td>
            <td>{data.sender.name}</td>
            <td>
              {" "}
              <a href={"mailto:" + data.sender.email}>
                {data.sender.email}
              </a>{" "}
            </td>
          </tr>
          <tr>
            <td>Affiliation (Company/Inst)</td>
            <td colSpan="2">{data.sender.organization}</td>
          </tr>
          <tr>
            <td>Applicant position</td>
            <td colSpan="2">{data.userJobTitle}</td>
          </tr>

          <tr>
            <td>Supervisor</td>
            <td>{data.supervisorName} </td>
            <td>
              {" "}
              <a href={"mailto:" + data.supervisorEmail}>
                {data.supervisorEmail}
              </a>{" "}
            </td>
          </tr>
          <tr>
            <td>Request status</td>
            <td colSpan={2}> {data.requestStatus} </td>
          </tr>
          {/* <tr>
          <td>The reason</td>
          <td style={{fontSize:13}} colSpan={2}> Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem voluptates soluta nam. Vero nihil molestiae temporibus! Sit expedita repellendus ipsum animi odit porro nostrum culpa quibusdam blanditiis. Consectetur, ratione dicta.  </td>
        </tr> */}
          {/* <tr>
          <td>Important documents</td>
          <td style={{fontSize:11}} colSpan={2}> 
            {
              data.files.map((file, index)=>{
                return <a href="">File {index+1}</a> 
              })
            }
            </td>
        </tr> */}

          {/* <tr>
          <td>ACTIONS</td>
          <td>
          <Button variant="contained" style={{backgroundColor:'green', color:'#fff'}} color="success">
            ACCEPT
          </Button>
          </td>
          <td>
          <Button variant="outlined" color="error">
            REJECT
          </Button>
          </td>
        </tr> */}
        </table>
      </DialogContent>
      <DialogActions>
        <Button className="mt-btn" onClick={handleClose}>
          CANCEL
        </Button>
        <Button
          hidden={ data.requestStatus === "approved" || data.requestStatus === "rejected" || data.invalid }
          style={{ backgroundColor: "red", color: "#fff" }}
          onClick={reject}
        >
          {" "}
          {loading ? "Updating ..." : "REJECT"}
        </Button>
        <Button
          hidden={data.requestStatus === "approved" || data.requestStatus === "rejected" || data.invalid }
          style={{ backgroundColor: "green", color: "#fff" }}
          onClick={accept}
        >
          {loading ? "Updating ..." : "ACCEPT"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const VerificationListPage = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const [requestList, setRequestList] = useState([]);
  const originalData = props.requestList;
  const [openDetails, setOpenDetails] = useState(false);
  const [userData, setUserData] = useState({ sender: {}, files: [] });
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();


  const handleSnack = () => {
    enqueueSnackbar('Request updated!');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showApproved = () => {
    props.setStatus("approved");
    handleClose();
  };

  const showUnApproved = () => {
    props.setStatus("unapproved");
    handleClose();
  };

  const showRejected = () => {
    props.setStatus("rejected");
    handleClose();
  };

  const acceptRequest = async () => {
    setLoading(true);
    const token = getTokenFromLocalStorage();
    // console.log(userData.code)

    const response = await officializeAccount({
      req_id: userData.code,
      data: { token: token, status: "approved" },
    });
    setLoading(false);
    handleSnack()
    setOpenDetails(false);
    props.setUpdated(true);
  };

  const rejectRequest = async () => {
    setLoading(true);
    const token = getTokenFromLocalStorage();
    const response = await officializeAccount({
      req_id: userData.code,
      data: { token: token, status: "rejected" },
    });
    setLoading(false);
    handleSnack()
    setOpenDetails(false);
    props.setUpdated(true);

  };

  const closeDetails = () => {
    setOpenDetails(false);
  };

  const openDetailsHandler = (data) => {
    setUserData(data);
    setOpenDetails(true);
  };
  const saveDetails = () => {
    setOpenDetails(false);
  };

  useEffect(() => {
    setRequestList(originalData);
  }, [originalData]);

  // console.log(requestList)
  return (
    <TableContainer style={{ padding: 10 }}>
      <DetailsPage
        data={userData}
        isOpen={openDetails}
        accept={acceptRequest}
        reject={rejectRequest}
        close={closeDetails}
      />
      <h3>Verification requests</h3>
      <hr />
      {props.loading ? (
        <div>Loading ...</div>
      ) : (
        <Paper style={{ padding: 5 }}>
          <CustomSearchBar
            loading={loading}
            displayedList={requestList}
            filterAttribs={"name"}
            originalList={originalData}
            changeDisplayList={(list) => {
              setRequestList(list);
            }}
          />
          <br />
          <Button
            id="demo-positioned-button"
            aria-controls={openMenu ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            onClick={handleClick}
          >
            {props.status === "unapproved"?"pending": props.status} <IoMdArrowDropdown />
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem style={{ padding: 15 }} onClick={showApproved}>
              APPROVED
            </MenuItem>
            <br />
            <MenuItem style={{ padding: 15 }} onClick={showUnApproved}>
              PENDING
            </MenuItem>
            <br/>
            <MenuItem style={{ padding: 15 }} onClick={showRejected}>
              REJECTED
            </MenuItem>
          </Menu>
          <br />
          <br />
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "#fff" }}>Applicant name</TableCell>
                <TableCell style={{ color: "#fff" }}>Company</TableCell>
                <TableCell style={{ color: "#fff" }}>Supervisor</TableCell>
                <TableCell style={{ color: "#fff" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList.length === 0 ? (
                <TableRow>
                  <TableCell align="center" scope="row" colSpan={4}>
                    EMPTY LIST
                  </TableCell>
                </TableRow>
              ) : (
                requestList.map((row) => (
                  <TableRow
                    key={row.code}
                    // sx={{ '&:last-child td, &:last-child td': { border: 0 } }}
                  >
                    
                    <TableCell scope="row">{row.name}</TableCell>
                    <TableCell>{row.sender.organization}</TableCell>
                    <TableCell>{row.supervisorName}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          openDetailsHandler(row);
                        }}
                        className="mt-btn"
                        variant="outlined"
                      >
                        More details
                      </Button>
                    </TableCell>
                    {/* <TableCell align="right">{row.protein}</TableCell> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Paper>
      )}
    </TableContainer>
  );
};

export default VerificationListPage;
