import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
// import Header from "../layouts/header";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import styled from "styled-components";
import RefreshIcon from "@mui/icons-material/Refresh";
import { countryList } from "../../../helper/country";
import DatasetContent from "../components/DatasetContent";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import Footer from "../layouts/footer";
import { useLocation } from "react-router-dom";
import { AXIOS_POST } from "../config/axios";
import { DATASET } from "../config/url";
import CircularProgress from "@mui/material/CircularProgress";
import AppLayout from "../layouts/AppLayout";
import Translate from "../translate";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../app/features/languageSlice";
import { scrollToTop } from "../../../utils/scroll";
import { getThemeNames } from "../../../utils/helper";



const Filter_search = () => {
  // const history = useHistory();
  
  const location = useLocation();
  // console.log("location", location.state);
  const [searchValue, setSearchValue] = useState(location.state?.searchValue);
  const [category, setCategory] = useState(location.state?.category);
  const [theme, setTheme] = useState(location.state?.theme)
  const [themeId, setThemeId] = useState(location.state?.themeId)
  const [country, setCountry] = useState("");
  const [others, setOthers] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredResults, setFilteredResults] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const ITEMS_PER_PAGE = 10; // Maximum number of items to show on each page
  const [currentPage, setCurrentPage] = useState(1);
  const numPages = Math.ceil(filteredResults.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentResults = filteredResults.slice(startIndex, endIndex);
  const [triggerLoginFromOuside, setLoginTrigger] = useState(false);

  const handleLoginTrigger = (value) => {
    setLoginTrigger(value);
  };

  const handleCategory = (e) => {
    let value = e.target.value;
    setCategory(value);
    // populateSearches(value, country, others);
  };
  const handleCountry = (e) => {
    let value = e.target.value;
    setCountry(value);
    // populateSearches(value, value, others);
  };
  const handleOthers = (e) => {
    let value = e.target.value;
    setOthers(value);
    // populateSearches(category, country, value);
  };
  const handleReset = () => {
   
    setFilteredResults(searchResult);
    setCategory("");
    setCountry("");
    setOthers("");
    setTheme(undefined)
    setThemeId(undefined)
  };
  const handleFilter = (e) => {
    if (window.innerWidth <= 768) {
      e.preventDefault();
      setIsFilterOpen(!isFilterOpen);
    }
  }

  const searchByThemes = () =>{
    // /search?choice=SDGS&sdgid=SDG1
    setLoading(true);
      let datasets = [];
      AXIOS_POST(`${DATASET}/search?choice=${theme}&sdgid=${themeId}`)
        .then((response) => {
          datasets = response.data.data;
          // console.log(datasets);

          setSearchResult(datasets);
        })
        .catch((error) => {
          // console.error(error);
          setLoading(false);
        })
        .finally(() => setLoading(false));
  }
  const populateSearches = () => {
    setLoading(true);
    setTheme(undefined)
    if (searchValue.length < 150) {
      let datasets = [];
      AXIOS_POST(`${DATASET}/search?choice=quick`, { searchQuery: searchValue })
        .then((response) => {
          datasets = response.data.data;
          setSearchResult(datasets);
        })
        .catch((error) => {
          // console.error(error);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    } else {
      alert(
        "Please use specific keywords, such as 'coffee exports,' 'basic education in Uganda,' or 'agriculture in Kenya,' as this search query contains too much text for a single search."
      );
    }
  };

 
  // Need to be revised !!!!
  useEffect(() => {
    setLoading(true);

    // console.log("local search", location.state.searchValue);
    if (location.state?.searchValue || location.state?.category) {
      AXIOS_POST(`${DATASET}/search?choice=quick`, { searchQuery: searchValue })
        .then((result) => {
          setSearchResult(result.data.data);
          setCategories(result.data.categories);
          if (location.state.category && location.state.category !== "others") {
            setSearchResult(
              result.data.data.filter(
                (dataset) => dataset.category === category
              )
            );
            setCategories(result.data.categories);
          }
          if (location.state.category && location.state.category === "others") {
            setSearchResult(
              result.data.data.filter(
                (dataset) =>
                  dataset.category !== "education" &&
                  dataset.category !== "health" &&
                  dataset.category !== "geospatial"
              )
            );
            setCategories(result.data.categories);
          }
        })
        .catch((error) => {
          // console.error(error);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });

      }else if(location.state?.themeId && location.state?.theme){
        searchByThemes()

    }else if(location.state?.category){
// Add a serach by actegory also
    }else{

      AXIOS_POST(`${DATASET}/search?choice=quick`, { searchQuery: "Education in africa" })
      .then((result) => {
        setSearchResult(result.data.data);
        setCategories(result.data.categories);
        if (location.state.category && location.state.category !== "others") {
          setSearchResult(
            result.data.data.filter(
              (dataset) => dataset.category === category
            )
          );
          setCategories(result.data.categories);
        }
        if (location.state.category && location.state.category === "others") {
          setSearchResult(
            result.data.data.filter(
              (dataset) =>
                dataset.category !== "education" &&
                dataset.category !== "health" &&
                dataset.category !== "geospatial"
            )
          );
          setCategories(result.data.categories);
        }
      })
      .catch((error) => {
        // console.error(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });

    }
    // setLoading(false);
  // This is here to reset everything
  }, []);

  const compareByField = (field, a, b) => {
    if (a[field] < b[field]) return -1;
    if (a[field] > b[field]) return 1;
    return 0;
  };
  const sortByMostRecent = (a, b) => {
    return compareByField("createdAt", b, a);
  };
  const sortbyMostViewed = (a, b) => {
    return b.views - a.views;
  };
  const sortbyMostDownloads = (a, b) => {
    return b.downloads - a.downloads;
  };
  const applySorting = (filtered, sortBy) => {
    switch (sortBy) {
      case "created_at":
        filtered.sort(sortByMostRecent);
        break;
      case "views":
        filtered.sort(sortbyMostViewed);
        break;
      case "downloads":
        filtered.sort(sortbyMostDownloads);
        break;
      default:
        break;
    }
  };
  const filterAndSortResults = (searchResult, category, country, sortBy) => {
    let filtered = searchResult
      .filter((dataset) => !category || dataset.category === category)
      .filter((dataset) => !country || dataset.country === country);
    applySorting(filtered, sortBy);
    // console.log(filtered);
    return filtered;
  };
  useEffect(() => {
    setFilteredResults(
      filterAndSortResults(searchResult, category, country, others)
    );
    setCurrentPage(1);
  }, [category, country, others, searchResult]);
  const languageValue = useSelector(selectLanguage);
  return (
    <AppLayout
      triggerLoginFromOuside={triggerLoginFromOuside}
      setTriggerLoginFromOuside={handleLoginTrigger}
    >
      <FilterPage>
        <SearchSection>
          <BoxContainer>
            <SearchInput
              type="text"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <SearchBox className="search-button" onClick={populateSearches}>
              <BoxIcon
                display="flex"
                alignItems="center"
                onClick={populateSearches}
              >
                <PersonSearchIcon
                  variant="contained"
                  className="person-search"
                />
                <Typography variant="body1">
                  {" "}
                  {/* <button style={{ all: "unset" }}>Search</button> */}
                </Typography>
              </BoxIcon>
            </SearchBox>
          </BoxContainer>
        </SearchSection>
        <DataSection>
          <FilterContainer>
            <h6>
              <span onClick={handleFilter}>
                <Translate Word={"search-filter"} />
                <IconClose fontSize="20px" />
              </span>
              {isFilterOpen && 
              (
                <Reset onClick={handleReset}>
                <ReIcon fontSize="20px" />
                <Translate Word={"reset"} />
              </Reset>
              )}
         
            </h6>
            {isFilterOpen && (
                          <SearchCriteria>
                          <ul>
                            <li>
                              <details>
                                <summary>
                                  <Translate Word={"category"} />
                                </summary>
                                <select
                                  name="category"
                                  value={category}
                                  onChange={handleCategory}
                                >
                                  {category === "" || category === undefined ? (
                                    <option value={category} selected disabled>
                                      {languageValue === "En" ? "Select" : "sélectionnez"}
                                    </option>
                                  ) : (
                                    <option
                                      style={{ background: "#d0cdcd" }}
                                      value={category}
                                      selected
                                    >
                                      {category}
                                    </option>
                                  )}
                                  {categories.map((category) => (
                                    <option value={category}>{category}</option>
                                  ))}
                                </select>
                              </details>
                            </li>
                            <li>
                              <details>
                                <summary>
                                  <Translate Word={"country"} />
                                </summary>
                                <select
                                  name="country"
                                  id=""
                                  value={country}
                                  onChange={handleCountry}
                                >
                                  {country === "" ? (
                                    <option value={country} selected disabled>
                                      {languageValue === "En" ? "Select" : "sélectionnez"}
                                    </option>
                                  ) : (
                                    <option
                                      style={{ background: "#d0cdcd" }}
                                      value={country}
                                      selected
                                    >
                                      {country}
                                    </option>
                                  )}
                                  {countryList.map((country) => (
                                    <option value={country} key={country}>
                                      {country}
                                    </option>
                                  ))}
                                </select>
                              </details>
                            </li>
                            <li>
                              <details>
                                <summary>
                                  <Translate Word={"other-criterias"} />
                                </summary>
                                <select
                                  name="others"
                                  id=""
                                  value={others}
                                  onChange={handleOthers}
                                >
                                  {others === "" ? (
                                    <option value={others} selected disabled>
                                      {languageValue === "En" ? "Select" : "sélectionnez"}
                                    </option>
                                  ) : (
                                    <option
                                      style={{ background: "#d0cdcd" }}
                                      value={others}
                                      selected
                                    >
                                      {others}
                                    </option>
                                  )}
                                  <option value="Most recent">
                                    {languageValue === "En"
                                      ? "Most recent"
                                      : "les plus récent"}
                                  </option>
                                  <option value="Most viewed">
                                    {languageValue === "En" ? "Most viewed" : "les plus vu"}
                                  </option>
                                  <option value="Most downloaded">
                                    {languageValue === "En"
                                      ? "Most downloaded"
                                      : "les plus téléchargé"}
                                  </option>
                                </select>
                              </details>
                            </li>
                          </ul>
                        </SearchCriteria>
            )}

          </FilterContainer>
          <DataSetContainer>
            <h6>
              <Translate Word={"search-result"} />{" "}
              <span style={{ color: "#01944D" }}>
                {!loading && filteredResults.length}
              </span>{" "}
              <Translate Word={"dataset-found"} />
            </h6>
            {theme &&
            <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "center",
                  paddingLeft:1,
                  alignItems: "center",
                }}
              >
                <span className="filter-badge" >{getThemeNames(theme, themeId)}</span>
            </Box>}
            {loading? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              currentResults.map((result, index) => (
                <DatasetContent
                  openLoginBox={handleLoginTrigger}
                  setSearchValue={setSearchValue}
                  key={index}
                  data={result}
                />
              ))
            )}
            {!loading && (
              <Pagination>
                {currentPage > 1 && (
                  <Link onClick={() => setCurrentPage(currentPage - 1)}>
                    <IconLeft style={{ fontSize: 40 }} />
                  </Link>
                )}
                {Array.from(
                  { length: numPages },
                  (_, i) =>
                    i < 15 &&
                    (i + 1 !== currentPage ? (
                      <Link
                        key={i}
                        onClick={() => {
                          setCurrentPage(i + 1);
                        }}
                        className={i + 1 === currentPage ? "active" : ""}
                      >
                        {i + 1}
                      </Link>
                    ) : (
                      <LinkActive
                        key={i}
                        onClick={() => setCurrentPage(i + 1)}
                        className={i + 1 === currentPage ? "active" : ""}
                      >
                        {" "}
                        {i + 1}{" "}
                      </LinkActive>
                    ))
                )}

                {currentPage > 15 && <Link>...</Link>}
                {currentPage > 15 && (
                  <LinkActive onClick={() => setCurrentPage(currentPage + 1)}>
                    {" "}
                    {currentPage}{" "}
                  </LinkActive>
                )}

                {currentPage < numPages && (
                  <Link onClick={() => setCurrentPage(currentPage + 1)}>
                    <IconRight style={{ fontSize: 40 }} />
                  </Link>
                )}
                {/* To scroll up when the last page is reached */}
                {currentPage === numPages && scrollToTop()}
              </Pagination>
            )}
          </DataSetContainer>
        </DataSection>
      </FilterPage>
    </AppLayout>
  );
};
const IconClose = styled(ArrowDropUpIcon)`
  color: black;
  vertical-align: bottom;
  font-size: 25px;
  transform: ${props=> props.open ? "rotate(0deg)" : "rotate(180deg)"};
  `
const Link = styled.a`
  padding: 10px;
  text-align: center;
  justify-content: center;
  margin: 2px;
  &:hover {
    color: white !important;
    background: #01944D;
    cursor: pointer;
  }
`;

const LinkActive = styled.a`
  padding: 10px;
  text-align: center;
  justify-content: center;
  margin: 2px;
  color: white !important;
  background: #01944D;
  cursor: pointer;
`;

const IconLeft = styled(KeyboardArrowLeftIcon)`
  color: #01944D;
  cursor: pointer;
  &:hover {
    background: #01944D;
    color: white;
    cursor: pointer;
  }
`;
const IconRight = styled(KeyboardArrowRightIcon)`
  color: #01944D;
  &:hover {
    background: #01944D;
    color: white;
    cursor: pointer;
  }
`;
const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const FilterPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SearchSection = styled.section`
  display: flex;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  background-color: #d0cdcd;
  width: 100%;
`;
const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px;
  width: 100%;
`;
const SearchBox = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
`;
const SearchInput = styled.input`
  width: 600px;
  height: 50px;
  border-radius: 4px;
  border: none;
  outline: 1px solid #01944D;
`;
const BoxIcon = styled(Box)`
  width: 80px;
`;
const DataSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  background-color: #f8f6f6;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const FilterContainer = styled.div`
  border-top: 2px solid #01944D;
  margin: 2% 0;
  width: 20%;
  min-height: 400px;
  background: white;
  border-radius: 4px;
  h6 {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    span {
      font-size: larger;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 768px) {
      width: 100%;
      min-height: auto;
    }
`;
const DataSetContainer = styled.div`
  width: 70%;
  margin: 2% 0;
  background: white;
  min-height: 400px;
  border-radius: 4px;
  h6 {
    text-align: center;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
  }
  @media screen and (max-width: 768px) {
      width: 100%;
      min-height: auto;
    }
`;
const Reset = styled.span`
  color: #01944D;
  font-weight: bold;
  cursor: pointer;
`;
const ReIcon = styled(RefreshIcon)`
  vertical-align: bottom;
  font-size: 20px;
`;
const SearchCriteria = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  ul li {
    list-style-type: none;
    padding: 10px 0;
    width: 100%;
    align-items: left;
  }
  ul li details {
    width: 200px;
    align-items: left;
    summary {
      /* font-weight: bold; */
      width: 100%;
      align-items: left;
    }
    select {
      width: 100%;
      height: 30px;
      border: 1px solid #b15805;
      border-radius: 2px;
    }
  }
  @media screen and (max-width: 768px) {

  }
`;
export default Filter_search;
