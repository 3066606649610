import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from "../../../app/features/authSlicer";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { AXIOS_POST } from "../config/axios";
import { POST_LOGIN_URL } from "../config/url";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../helper/Loader";
import Translate, { transalateToString } from "../translate";
import ErrorMessage from "./ErrorMessage";
import { selectLanguage } from "../../../app/features/languageSlice";
import axios from "axios";

const Login = ({
  handleClose,
  handleForget,
  handleOtp,
  handleRegister,
  next,
}) => {
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");
  const languageValue = useSelector(selectLanguage);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const handleNavigation = (path) => {
    history.push(path); // Replace '/path' with the desired URL or route
  };
  async function getCountry() {
    try {
      const response = await fetch("https://ipinfo.io/json");
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching country:", error);
      return null; // Handle the error gracefully in your application
    }
  }

  const onSubmit = async (data) => {
    data.location = await getCountry();
    setLoading(true);
    AXIOS_POST(POST_LOGIN_URL, data)
      .then((res) => {
        if (!res.data) {
          return;
        }
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("isAuth", true);
        localStorage.setItem("user", JSON.stringify(res.data));

        dispatch(loginSuccess(res.data));
        if (next) {
          handleNavigation("/dash");
        }
        handleClose();
        return;
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 403) {
          toast.error("Unauthorized: Account not confirmed", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
          setTimeout(() => handleOtp, 2000);
        } else if (error.response && error.response.status === 404) {
          toast.error("Unauthorized: Account never exist", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        } else if (error.response && error.response.status === 423) {
          toast.error("Unauthorized: Account is disabled", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        } else if (error.response && error.response.status === 401) {
          toast.error("Unauthorized: Password or email is incorrect", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        } else {
          toast.error("Unauthorized: something went wrong", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        }
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      handleNavigation("/dash");
    }
  }, []);
  return (
    <>
      <ToastContainer />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Title>
          <span>
            <Translate Word={"login"} />
          </span>
          <p style={{ fontSize: "16px", fontWeight: "normal" }}>
            <Translate Word={"get-started-register"} />
          </p>
        </Title>
        <InputContainer>
          <input
            type="email"
            name="email"
            placeholder={transalateToString(
              "login-form-lable-email",
              languageValue
            )}
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
          />
          {errors.email && <ErrorMessage message={errors.email.message} />}
          <input
            type="password"
            name="password"
            placeholder={transalateToString(
              "login-form-lable-password",
              languageValue
            )}
            {...register("password", {
              required: "Password is required",
            })}
          />

          {errors.password && (
            <ErrorMessage message={errors.password.message} />
          )}
        </InputContainer>
        <ButtonContainer>
          <SubmitButton type="submit">
            {loading ? <Loader color="white" /> : <Translate Word={"login"} />}
          </SubmitButton>
          <CancelButton type="button" onClick={handleClose}>
            <Translate Word={"cancel"} />
          </CancelButton>
        </ButtonContainer>
        <SignupText>
          <p>
            <Translate Word={"does-not-have-account"} />?{" "}
            <span onClick={handleRegister}>
              <Translate Word={"signup-here"} />
            </span>
          </p>
        </SignupText>
        <ForgetPassword>
          <p onClick={handleForget}>
            <Translate Word={"forgot-your-password"} />?
          </p>
        </ForgetPassword>
      </FormContainer>
    </>
  );
};
const FormContainer = styled.form`
  position: relative;
  width: 350px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  transition: 0.4s ease-in-out;
  &:hover {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    scale: 0.99;
  }
  svg {
    position: relative;
    align-self: center;
    top: 4em;
  }
`;
const Title = styled.p`
  position: relative;
  text-align: center;
  color: black;
  top: 1em;
  font-weight: bold;
`;
const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  input {
    position: relative;
    margin: 8px 5px;
    width: 70%;
    height: 2.4em;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: rgb(235, 235, 235);
    padding-left: 15px;
    transition: 0.4s ease-in-out;

    &:hover {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
      background-color: lightgrey;
    }
    &:focus {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
      background-color: lightgrey;
    }
  }
`;
const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  top: 0.5em;
  width: 100%;
  margin: 2%;
  justify-content: center;
  align-items: center;
`;
const SubmitButton = styled.button`
  outline: none;
  position: relative;
  background-color: #01944D;
  margin-right: 13px;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:active {
    box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
  }
`;
const CancelButton = styled.button`
  margin-left: 13px;
  background-color: #989393;
  position: relative;
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;
  cursor: pointer;
  &:active {
    box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
  }
`;
const SignupText = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0px 20px;
  align-items: center;
  margin: 0;
  p {
    line-height: 0%;
  }
  p span {
    line-height: 1;
    color: #01944D;
    cursor: pointer;
  }
`;
const ForgetPassword = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  align-items: center;
  p {
    line-height: 1;
    color: #01944D;
    cursor: pointer;
    border-bottom: 1px solid #01944D;
  }
`;
export default Login;
