import { TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
const handleSearch = () => {
  // Perform search functionality
  // console.log('Searching...');
};

const handleFilter = () => {
  // Perform filter functionality
  // console.log('Filtering...');
};

const RequestSearch = () => {
    
  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder="Search request"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              edge="start"
              disableRipple
              onClick={handleSearch}
              sx={{ marginRight: '20px', outline: 'none' }}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={handleFilter} disableRipple>
              <FilterListIcon />
            </IconButton>
          </InputAdornment>
        ),
        sx: { borderRadius: '20px' },
      }}
      fullWidth
    />
  );
};

export default RequestSearch;
