import React, {useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../Upanzi Network logo.png";
// import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { logout } from "../../../app/features/authSlicer";
// import SendIcon from "@mui/icons-material/Send";
import LogoutIcon from "@mui/icons-material/Logout";
// import MenuIcon from "@mui/icons-material/Menu";
// import profile from "../david.jpg";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useHistory } from "react-router-dom";
import profileImg from "../avatar.jpg";
import { Link } from "react-router-dom";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
import {
  selectLanguage,
  changeToEn,
  changeToFr,
  changeLanguage,
} from "../../../app/features/languageSlice";

import LanguageIcon from "@mui/icons-material/Language";
import Suggestion from "../components/suggestion";
import Login from "../components/login";
import Register from "../components/register";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Box, Fab, Typography } from "@mui/material";
import Translate from "../translate";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
// import VerifyUser from "../components/verifyUser";
import ForgetPass from "../components/forgetPassword";
import ResetPassword from "../components/resetPassword";
import CustomModel from "../components/model";
import OtpBox from "../components/Otp";
import Footer from "./footer";
import CloseIcon from '@mui/icons-material/Close';
import FloatingButton from "../components/FloeatingButton";
import { set } from "react-hook-form";
import { scrollToTop } from "../../../utils/scroll";

const AppLayout = ({ children, triggerLoginFromOuside, setTriggerLoginFromOuside }) => {
  /*
  start navigation
   */
  const dispatch = useDispatch();
  const languageVal = useSelector(selectLanguage);

  const [sideBarVisible, setSidebarVisible] = useState(true);
  const [menuVisible, setMenuVisible] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [modelComponent, setModelComponent] = useState(<></>);
  const [open, setOpen] = useState(false);
  const [selectedLang, setselectedLang] = useState(languageVal);
  const history = useHistory();

  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };



  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  

  const handleGuide =(e)=>{
    e.preventDefault();
    history.push("/guide");
  }
  const handleHomepage = (e) => {
    e.preventDefault();
    history.push("/");
  };
  const handleAbout = (e) => {
    e.preventDefault();
    history.push("/about-us");
  };

  const handlePrivacy=(e)=>{
    e.preventDefault()
    history.push("/privacy-policy")
  }

  const handleContact=(e)=>{
    e.preventDefault();
    history.push("/contact-us");

  }

  const handleResetBox = () => {
    setShowModel(true);
    setOpen(true);
    setModelComponent(
      <ResetPassword
        handleLogin={handleLoginBox}
        handleClose={handleClose}
        handleOtp={handleOtpBox}
      />
    );
  };
  const handleOtpBox = (email) => {
    setShowModel(true);
    setOpen(true);
    setModelComponent(
      <OtpBox
        handleLogin={handleLoginBox}
        handleClose={handleClose}
        handleReset={handleResetBox}
        emailValue={email}
      />
    );
  };
  const handleSuggestionStyle = () => {
    setShowModel(true);
    setOpen(true);
    setModelComponent(<Suggestion handleClose={handleClose} />);
  };

  const handleToSDGS =()=>{
    // var body = document.getElementsByTagName('body')[0]

    
      var sdgs = document.getElementById('sdgs')

      sdgs.scrollIntoView({behavior:'smooth'})
    
  }
  const handleLoginBox = (next=false) => {
    setShowModel(true);
    setOpen(true);
    setModelComponent(
      <Login
        next={next}
        handleClose={handleClose}
        handleRegister={HandleRegisterBox}
        handleOtp={handleOtpBox}
        handleForget={handleForgetBox}
      />
    );
  };
  const HandleRegisterBox = () => {
    setShowModel(true);
    setOpen(true);
    setModelComponent(
      <Register
        handleLogin={handleLoginBox}
        handleOtp={handleOtpBox}
        handleClose={handleClose}
      />
    );
  };
  const handleForgetBox = (event) => {
    setShowModel(true);
    setOpen(true);
    setModelComponent(
      <ForgetPass handleClose={handleClose} handleReset={handleResetBox} />
    );
  };

  const handleSuggestions = (e) => {
    e.preventDefault();
    setShowModel(true);
    setOpen(true);
    setModelComponent(<Suggestion handleClose={handleClose} />);
  }
  const changeLanguage = (e) => {
    setselectedLang(e.target.value);
    localStorage.setItem("language", e.target.value);
    if (selectedLang === "En") {
      dispatch(changeToFr());
    } else {
      dispatch(changeToEn());
    }
    // console.log("change language" + languageVal);
  };
  /*
  end navigation
   */

  const handleProfileMenu = () => {
    setProfileVisible(!profileVisible);
  };
  const handleLogout = (e) => {
    // console.log("logged out");
    history.push("/");
    e.preventDefault();
    dispatch(logout());
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("isAuth");
    localStorage.removeItem("user");
    setProfileVisible(false);
  };

  const handleMenu = (e) => {
    e.preventDefault();
    setMenuVisible(!menuVisible);
  };

  // useEffect to trigger login from the link button in the dataset page
  useEffect(()=>{
    if(triggerLoginFromOuside){
      handleLoginBox(false)
      setTriggerLoginFromOuside(false)
    }

  },[triggerLoginFromOuside])

  return (
    <DashboardPage>
        <FloatingButton onClick={handleSuggestions} />
      <Container
        sideBarVisible={sideBarVisible}
        profileVisible={profileVisible}
      >
        <NavBar>
          <NavContainer>
            <LogoHolder to="/">
              <img
                style={{ cursor: "pointer" }}
                src={logo}
                alt="logo"
                onClick={handleHomepage}
              />
            </LogoHolder>
            <Left>
              <Navigation style={{ flexGrow: 4, justifyContent:'space-between' }}>
                <span onClick={handleContact}>
                  <Translate Word={"contact"} />
                </span>
                <span onClick={handleAbout}>
                  <Translate Word={"about-us"} />
                </span>
                {/* <span onClick={handlePrivacy}>
                  <Translate Word={"privacy_policy"} />
                </span> */}
                
                <span onClick={handleToSDGS}>
                {history.location.pathname === '/'  && <Translate Word={"sdgs"} /> }
                </span>
              </Navigation>
            </Left>
            <Right>
              {!sessionStorage.isAuth && (
                <AuthContainer style={{ flexGrow: 2 }}>
                  <span onClick={()=>{handleLoginBox(true)}}>
                    <Translate Word={"login"} />
                  </span>
                  <span onClick={HandleRegisterBox}>
                    <Translate Word={"signup"} />
                  </span>
                </AuthContainer>
              )}

              <UserGuideLanguage>
                <Language>
                  <Box display="flex" alignItems="center">
                    <IconLanguage variant="contained" />
                    <Typography variant="body1">
                      <select onChange={changeLanguage}>
                        {selectedLang === "En" && (
                          <>
                            <option value="En">En</option>
                            <option value="Fr">Fr</option>
                          </>
                        )}
                        {selectedLang === "Fr" && (
                          <>
                            <option value="Fr">Fr</option>
                            <option value="En">En</option>
                          </>
                        )}
                      </select>
                    </Typography>
                  </Box>
                </Language>
                <UserGuide onClick={handleGuide}>
                  <IconMenuBook size="16px"></IconMenuBook>
                  <span style={{ fontSize: 14, fontWeight: 500 }}>
                    <Translate Word={"user-guide"} />
                  </span>
                </UserGuide>
              </UserGuideLanguage>
              {menuVisible && (
              <Menu>
              <RightHumberger><IconClose onClick={handleMenu} size ="50px" color="white"/></RightHumberger>
              <NavLink marginTop="0px">
                <li>
                  <Link to="" onClick={handleContact}>
                    <span>
                    <Translate Word={"contact"} />
                  </span>
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={handleAbout}>
                    <span>
                    <Translate Word={"about-us"} />
                  </span>
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={handleAbout}>
                    <span>
                    <Translate Word={"privacy_policy"} />
                  </span>
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={handleGuide}>
                  <span>
                    <IconMenuBook size="16px" color="black"></IconMenuBook>
                    </span>
                      <Translate Word={"user-guide"} />
                    </Link>
                </li>
                <li>
                 
                  <Link to="#sdgs" >
                    <span>
                    <Translate Word={"sdgs"} />
                  </span>
                  </Link>
                </li>
            </NavLink>  
              </Menu>
              )}
              <RightHumberger ><IconMenu onClick={handleMenu} size ="26px"/></RightHumberger>
            </Right>
            {showModel && (
              <CustomModel
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                data={modelComponent}
              />
            )}
          </NavContainer>
          {sessionStorage.isAuth && (
            <Profile>
              {!profileVisible ? (
                <img
                  onClick={handleProfileMenu}
                  src={
                    JSON.parse(localStorage.getItem("user")).profile
                      ? JSON.parse(localStorage.getItem("user")).profile
                      : profileImg
                  }
                  alt=""
                />
              ) : (
                <IconExpand onClick={handleProfileMenu} />
              )}
            </Profile>
          )}
        </NavBar>
        {children}
        <Footer />
       
      </Container>
      {profileVisible && sessionStorage.isAuth && (
        <ProfileSide>
          <ImgHolder>
            <img
              src={
                JSON.parse(localStorage.getItem("user")).profile
                  ? JSON.parse(localStorage.getItem("user")).profile
                  : profileImg
              }
              alt=""
            />
            <span>{JSON.parse(localStorage.getItem("user")).username}</span>
          </ImgHolder>
          <ProfileMenu>
            <NavLink>
              <li>
                <Link to="/dash">
                  <span>
                    <IconDash />
                  </span>
                  <Translate Word={"dashboard"} />
                </Link>
              </li>
              <li>
                <Link to="/profile">
                  <span>
                    <IconProfile />
                  </span>
                  <Translate Word={"change-profile"} />
                </Link>
              </li>

              <li onClick={handleLogout}>
                <Link to="">
                  <span>
                    <IconLogout />
                  </span>
                  <Translate Word={"Logout"} />
                </Link>
              </li>
            </NavLink>
          </ProfileMenu>
        </ProfileSide>
      )}

          {isVisible && <Fab
                  onClick={scrollToTop}
                  style={{ position: "fixed", bottom: 100, right: 70 }}
                  sx={"fabStyle as SxProps"}
                  aria-label={"fab.label"}
                  color={"red"}
                >
                  <UpIcon />
                </Fab>}
    </DashboardPage>
  );
};
const Menu = styled.div`
@media screen and (min-width: 768px){
  display: none;
}
transition: transform 0.1s ease-in-out;
z-index: 100;
position: absolute;
background: #01944D;
width: 150px;
height: auto;
right: 0;
top:0;
`
const IconClose = styled(CloseIcon)`
cursor: pointer;
font-size: 50px!important;
vertical-align: bottom ;
color: ${props => props.color || '#01944D'};`
const RightHumberger = styled.div`
  display: none;
  @media screen and (max-width: 768px){
    vertical-align: bottom;
    margin-top: 0px;
    display: block;
  }`
const IconMenu = styled(MenuIcon)`
cursor: pointer;
color: ${props => props.color || '#01944D'};
vertical-align: bottom;
font-size: 50px!important;`
const IconDash = styled(DashboardIcon)`
  vertical-align: bottom;
`;
const IconMenuBook = styled(MenuBookIcon)`
  vertical-align: bottom;
  color: ${props => props.color || '#01944D'};
`;
const UserGuide = styled.div`
  span {
    font-family: "Bebas Neue", cursive;
    cursor: pointer;
  }
  @media screen and (max-width: 768px){
    display: none;
  }
`;
const IconLanguage = styled(LanguageIcon)`
  color: #01944D;
  vertical-align: bottom;
`;
const Language = styled.div`
  select {
    border-color: #01944D;
    border-radius: 2px;
    font-family: "Bebas Neue", cursive;
  }
`;
const UserGuideLanguage = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  @media screen and (max-width: 768px){
    display:none
  }
`;
const AuthContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: start;
  span {
    font-family: "Bebas Neue", cursive;
    cursor: pointer;
  }
`;
const Navigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: start;
  span {
    font-family: "Bebas Neue", cursive;
    color: #01944D;
    cursor: pointer;
  }
  @media screen and (max-width: 768px){
    display: none;
    font-size: 20px;
  }
`;
const LogoHolder = styled(Link)`
  width: 40%;
  img {
    padding: 10px 0px;
    height: 90px;
    width: auto;
    @media screen and (max-width: 768px){
      width: 100%;
      height: auto;
    }
  }
`;
const Left = styled.div`
  width: 40%;
  padding-left: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media screen and (max-width: 768px){
    display: none;
  }
`;
const Right = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media screen  and (max-width: 768px){
    width: 60%;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
  }
`;
const NavContainer = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  align-items: center;
  height: inherit;
`;
const IconExpand = styled(ExpandCircleDownIcon)`
  transform: rotate(-90deg);
  margin-top: 10px;
  vertical-align: bottom;
  cursor: pointer;
`;
const ImgHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Bebas Neue", cursive;
  color: #01944D;
  height: 80px;
  padding-right: 20px;
  img {
    height: inherit;
    width: auto;
    border-radius: 50%;
    border: 1px solid #01944D;
  }
`;
const ProfileMenu = styled.div`
  margin-top: 10px;
`;
const ProfileSide = styled.div`
  width: 20%;
  height: 100vh;
  transition: opacity 0.3s ease-in-out;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20px;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;
const Profile = styled.div`
  height: 50px;
  padding-right: 10px;
  img {
    height: inherit;
    width: auto;
    border-radius: 50%;
    border: 1px solid #01944D;
  }
`;
const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  background: white;

  @media screen and (max-width: 768px) {
    height: 100px;
  }
`;
const IconLogout = styled(LogoutIcon)`
  vertical-align: bottom;
`;
const IconProfile = styled(AccountCircleIcon)`
  vertical-align: bottom;
`;
const NavLink = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "40px")};
  width: 100%;
  li {
    list-style-type: none;
    padding: 0.5rem 0.1rem;
    width: 100%;
    a {
      font-family: "Bebas Neue", cursive;
      width: 100%;
      padding: 0, 1rem;
      color: black;
      span {
        padding-right: 0.5rem;
      }
      &.active {
        color: #01944D;
      }
    }
  }
`;
const Container = styled.div`
  touch-action: auto;
  // width: ${(props) => (props.profileVisible ? "50%" : "100%")};
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: inherit;
  background: #e1dfdf;
  transition: width 0.5s ease-in-out;
  min-height: 100vh;
  position: relative;
  @media screen and (max-width: 768px) {
    overflow: scroll;
    overflow-y: scroll;
  }
`;
const DashboardPage = styled.div`
  box-sizing: border-box;
  touch-action: auto;
  position: relative;
  overflow-x: hidden !important;
  overflow: hidden !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  width:99vw;
  @media screen and (max-width: 768px) {
    overflow-x: scroll;
    overflow-y: scroll !important;
  }
`;
export default AppLayout;
