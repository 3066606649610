import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AunthenticationService } from "../../services/AuthenticationService";
import { adminSignIn } from "../../services/admin/admin-authentication";

const initialState = {
  user: null,
  loading:false,
  cannotLoggeIn:false,
  isLoggedIn:false,
  message:"",
  upadteIsLoading:false,
  changeIsLoading:false,
};

export const loginUser = createAsyncThunk(
  "authenticationSlice/loginUser",
  async(user, { rejectWithValue }) => {
    try{
        const signIn = new AunthenticationService(user);
        const response = await signIn.signinUser();
        // console.log(response)
        return response;
    }catch(er){
        rejectWithValue("Something went wrong!")
    }
  }
);


export const loginAdminUser = createAsyncThunk(
  "authenticationSlice/loginAdminUser",
  async(user, { rejectWithValue }) => {
    try{
        // const signIn = new AunthenticationService(user);
        const signIn = await adminSignIn(user);
        // const response = await signIn.signinUser();
        // console.log(response)
        return signIn;
    }catch(er){
        rejectWithValue("Something went wrong!")
    }
  }
);

export const updateProfile = createAsyncThunk(
  "authenticationSlice/updateProfile",
  async ({user, name, affiliation, token}, {rejectWithValue})=>{
    try{
        const authService = new AunthenticationService(user);
        const response = await authService.updateProfile(name, affiliation, token);
        // console.log(name)
        return response;
    }catch(error){
      rejectWithValue(error)
    }
  }
)

export const autoLogin = createAsyncThunk("authenticationSlice/autoLogin", (value,{rejectWithValue}) => {
    let user = JSON.parse(localStorage.getItem("authUser"));
    return user;
})

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers:{
        logout:(state, action)=>{
            state.isLoggedIn = false;
            state.user = null;
        },
        reset:(state, action)=>{
          state.cannotLoggeIn = false;
        }
  },
  extraReducers: {
    [loginUser.pending]:(state, {payload})=>{
        state.loading = true;
    },
    [loginUser.fulfilled]:(state, {payload})=>{
        state.loading = false;
        if (payload.success) {
            state.user = payload;
            state.isLoggedIn = true;
            localStorage.setItem(
              "authUser",
              JSON.stringify({
                ...payload,
              })
            );
            const expdate = new Date();
            expdate.setMinutes(expdate.getMinutes() + 3);

            localStorage.setItem('expdate', expdate)
           
          } else {
            state.message = payload.message;
            state.isLoggedIn = false;
            state.cannotLoggeIn = true;

          }
    },
    [loginUser.rejected]:(state, {payload})=>{
        state.loading = false;
        state.message = payload;
        state.cannotLoggeIn = true;
    },


    [autoLogin.fulfilled]:(state, {payload})=>{
        state.user = payload;
        state.loading = false;
        state.isLoggedIn = true;
    },
    [autoLogin.pending]:(state, {payload})=>{
        state.loading = true;
    },
    [autoLogin.rejected]:(state, {payload})=>{
        state.loading = false;
        state.message = payload;
    },



    [updateProfile.fulfilled]:(state, {payload})=>{
        console.log(payload)
        state.user.username = payload.user.username;
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        authUser.name = payload.user.name;
        authUser.affiliation = payload.user.organization;
        localStorage.setItem(
          "authUser",
          JSON.stringify({
            ...authUser
          })
        );
        state.upadteIsLoading = false;
    },
    [updateProfile.pending]:(state, {payload})=>{
        state.upadteIsLoading = true;
    },
    [updateProfile.rejected]:(state, {payload})=>{
        state.upadteIsLoading = false;
        state.message = payload;
    },
    [loginAdminUser.pending]:(state, {payload})=>{
      state.loading = true;
    },
    [loginAdminUser.fulfilled]:(state, {payload})=>{
        state.loading = false;
        state.user = payload.data
        state.user.username = payload.data.name;
        let authUser = payload.data
        authUser.name = payload.data.name;
        authUser.username = payload.data.name
        authUser.isAdmin = true
        localStorage.setItem(
          "authUser",
          JSON.stringify({
            ...authUser
          })
        );
    },
    [loginAdminUser.rejected]:(state, {payload})=>{
      state.loading = false;
      state.message = payload.message;
    },

  },
});

export const { logout, reset } = authenticationSlice.actions;
export default authenticationSlice.reducer;
