import React from 'react';
import AssistantIcon from '@mui/icons-material/Assistant';
import styled from 'styled-components';

const FloatingButton = ({ onClick }) => {
  return (
    <Button  onClick={onClick}> 
       <span><IconAssistant/>Leave a suggestion</span>
    </Button>
  );
};

export default FloatingButton;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 0px;
  right: 10px;
  border-radius: 4%;
  width: auto;
  height: 50px;
  font-size: 24px;
  background-color: #01944D;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 1000;
  span {
    margin-right: 10px;
  }
    &:hover {
        background-color: #01944D;
    }
    &:focus {
        outline: none;
    }

    &:active {
        background-color: #01944D;
    }

    @media screen and (max-width: 500px) {
 
     
        display: none !important;
    }
    
`;

const IconAssistant = styled(AssistantIcon)`
  vertical-align: middle !important;
  font-size: 24px;
  color: white;
`;