import { useEffect, useState } from "react";
import styled from "styled-components";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AXIOS_GET } from "../config/axios";
import { EditDiscussion } from "./edit-discussion";
import { Bold } from "react-feather";
import Loader from "react-js-loader";
import Translate from "../translate";

export const Discussion = ({ dataset, handleCancel, user }) => {
  const [activeTab, setActiveTab] = useState("all");
  const [vote, setVote] = useState(0);
  const [discussion, setDiscussion] = useState([]);
  const [myDiscussion, setMyDiscussion] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleVote = (type) => {
    if (type === "up") {
      setVote((prev) => prev + 1);
    } else {
      setVote((prev) => prev - 1);
    }
  };

  const getDiscussion = () => {
    setLoading(true);
    setActiveTab("all");
    AXIOS_GET(`/datasets/${dataset.code}/discussions`)
      .then((res) => {
        console.log(res.data.discussions);
        setDiscussion(res.data.discussions);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setActiveTab("all");
        setLoading(false);
      });
  };

  const handleEditDiscussion = () => {
    setEdit(true);
  };
  const getMyDiscussion = () => {
    setLoading(true);
    setActiveTab("owned");
    AXIOS_GET(
      `/dataset-discussions/${dataset.code}/creators/me`,
      sessionStorage.getItem("token")
    )
      .then((res) => {
        console.log(res.data.discussions);
        setMyDiscussion(res.data.discussions);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setActiveTab("owned");
        setLoading(false);
      });
  };
  useEffect(() => {
    getDiscussion();
  }, []);
  return (
    <Container>
      <TabsContainer>
        <Tabs onClick={() => getDiscussion()} active={activeTab === "all"}>
          {/* All */}
          <Translate Word={"discussion-all"} />
        </Tabs>
        <Tabs onClick={() => getMyDiscussion()} active={activeTab === "owned"}>
          {/* Owned */}
          <Translate Word={"discussion-owned"} />

        </Tabs>
        {/* <Tabs
          onClick={() => setActiveTab("bookmarks")}
          active={activeTab === "bookmarks"}
        >
          Bookmarks
        </Tabs> */}
      </TabsContainer>
      {activeTab === "all" ? (
        <>
          {loading ? (
            <Loader
              type="spinner-circle"
              bgColor="#01944D"
              color="#01944D"
              size={100}
            />
          ) : (
            <AllContainer>
              {discussion && discussion.length > 0
                ? discussion.map((item) => (
                    <Content>
                      <ImageHolder>
                        <img
                          src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                          alt=""
                        />
                      </ImageHolder>
                      <Details>
                        <h6 style={{paddingLeft:0, textAlign:'left'}}>{item.title}</h6>
                        <p
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></p>
                        <p>
                          <Translate Word={"discussion-by"}/> {item.creator.username}, <Translate Word={"discussion-at"} /> 14:00 29 October 2024
                        </p>
                      </Details>
                      {/* <VoteAndMore>
                              <span href="" onClick={() => handleVote("up")}>
                                <IconArrowDropUp />
                              </span>
                              <span>{vote}</span>
                              <span href="" onClick={() => handleVote("down")}>
                                <IconArrowDropDown />
                              </span>
                              <span href="">
                                <IconBookmark />
                              </span>
                            </VoteAndMore> */}
                    </Content>
                  ))
                : null}
            </AllContainer>
          )}
        </>
      ) : activeTab === "owned" ? (
        <>
          {loading ? (
            <Loader
              type="spinner-circle"
              bgColor="#01944D"
              color="#01944D"
              size={100}
            />
          ) : (
            <OwnerContainer>
              {myDiscussion &&
                myDiscussion.map((item) => (
                  <Content onClick={handleEditDiscussion}>
                    <ImageHolder>
                      <img
                        src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                        alt=""
                      />
                    </ImageHolder>
                    <Details>
                      <h6 style={{ fontWeight: Bold }}>{item.title}</h6>
                      <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                      <p>by {item.creator.username} at 14:00 29 October 2024</p>
                    </Details>
                    {/* <VoteAndMore>
                    <span href="" onClick={() => handleVote("up")}>
                      <IconArrowDropUp />
                    </span>
                    <span>{vote}</span>
                    <span href="" onClick={() => handleVote("down")}>
                      <IconArrowDropDown />
                    </span>
                    <span href="">
                      <IconBookmark />
                    </span>
                  </VoteAndMore> */}
                  </Content>
                ))}
            </OwnerContainer>
          )}
        </>
      ) : (
        <BookmarkContainer>
          <Content>
            <ImageHolder>
              <img
                src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                alt=""
              />
            </ImageHolder>
            <Details>
              <h6>Incorrect data</h6>
              <p>by David at 14:00 29 October 2024</p>
            </Details>
            <VoteAndMore>
              <span href="" onClick={() => handleVote("up")}>
                <IconArrowDropUp />
              </span>
              <span>{vote}</span>
              <span href="" onClick={() => handleVote("down")}>
                <IconArrowDropDown />
              </span>
              <span href="">
                <IconBookmark />
              </span>
            </VoteAndMore>
          </Content>
          <Content>
            <ImageHolder>
              <img
                src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                alt=""
              />
            </ImageHolder>
            <Details>
              <h6>Incorrect data</h6>
              <p>by David at 14:00 29 October 2024</p>
            </Details>
            <VoteAndMore>
              <span href="" onClick={() => handleVote("up")}>
                <IconArrowDropUp />
              </span>
              <span>{vote}</span>
              <span href="" onClick={() => handleVote("down")}>
                <IconArrowDropDown />
              </span>
              <span href="">
                <IconBookmark />
              </span>
            </VoteAndMore>
          </Content>
        </BookmarkContainer>
      )}
    </Container>
  );
};

const IconVert = styled(MoreVertIcon)`
  vertical-align: bottom;
`;
const IconBookmark = styled(BookmarkIcon)`
  vertical-align: bottom;
`;
const IconArrowDropUp = styled(ArrowDropUpIcon)`
  vertical-align: bottom;
`;

const IconArrowDropDown = styled(ArrowDropDownIcon)`
  vertical-align: bottom;
`;
const VoteAndMore = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  border: 1px solid #989393;
  border-radius: 4px;
  span {
    font-size: 20px;
    color: #989393;
    cursor: pointer;
    padding: 0 5px;
  }
  span:hover {
    color: #01944D;
  }

  span:nth-child(2) {
    border-left: 1px solid #989393;
    border-right: 1px solid #989393;
    padding: 0 10px;
    font-weight: bold;
    color: #01944D;
  }
  span:nth-child(3) {
    border-left: 1px solid #989393;
    border-right: 1px solid #989393;
  }
  span:nth-child(4) {
    border-left: 1px solid #989393;
  }
  span:nth-child(1) {
    border-right: 1px solid #989393;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background: white;
  padding: 5px;
  margin: 4px;
`;
const ImageHolder = styled.div`
  img {
    width: 50px;
    height: 50px;
    background: #f8f6f6;
    border-radius: 50%;
    border: 1px solid #01944D;
    border-bottom: none;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  width: 90%;
  margin-left: 10px;
  h6 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  p {
    font-size: 12px;
    color: #989393;
  }
`;
const AllContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 4px;
`;
const OwnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 4px;
`;
const BookmarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 4px;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: flex-start;
  align-items: left;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background: #f8f6f6;
  /* border-radius: 4px;
  border: 1px solid #01944D; */
  border-bottom: none;
  margin-bottom: 10px;
  font-weight: bold;
`;
const Tabs = styled.div`
  padding: 0 5%;
  cursor: pointer;
  ${(props) => (props.active ? `border-bottom: 3px solid #01944D;` : ``)}
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  height: 100%;
  width: 90%;
  border-radius: 4px;
  /* border: 1px solid #01944D; */
  border-bottom: none;
  margin: 2% auto;
  padding: 10px;
  background: #f8f6f6;
`;
