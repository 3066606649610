import React, {useState} from 'react';
import { Tab, Tabs } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PendingIcon from '@mui/icons-material/HourglassEmpty';
import { AllInbox } from '@mui/icons-material';


const RequestsTabs = ({ status, handleTabChange }) => {
const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  // const { status, handleTabChange } = useRequests();
  return (
    <Tabs value={status} onChange={handleTabChange} variant="fullWidth">
      <Tab 
        style={{fontSize:11}}
        label="All" 
        value="all" 
        icon={!isMobile && <AllInbox />} 
        iconPosition="start" 
      />
      <Tab
        style={{fontSize:11}}
        label="Approved"
        value="approved"
        icon={!isMobile && <CheckCircleOutlineIcon />}
        iconPosition="start"
      />
      <Tab
        style={{fontSize:11}}
        label="Rejected"
        value="rejected"
        icon={!isMobile && <HighlightOffIcon />}
        iconPosition="start"
      />
      <Tab
        style={{fontSize:11}}
        label="Pending"
        value="unapproved"
        icon={!isMobile && <PendingIcon />}
        iconPosition="start"
      />
    </Tabs>
  );
};

export default RequestsTabs;
