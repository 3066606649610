import React, { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
// import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ProtectedUserRoute } from "./helper/protectedRoutes";
// new UI

import { autoLogin, reduxLogout } from "./app/features/authenticationSlice";

import NotFound from "./containers/ui/pages/NotFound";
import AdminProtectedRoute from "./components/AdminProtectedRoutes";
import AdminDashboard from "./containers/admincite/dashboard/AdminDashboard";
import { SnackbarProvider } from "notistack";

// UI
import Landing from "./containers/ui/pages/home";
import Filter_search from "./containers/ui/pages/Filter_search";

import Requests from "./containers/ui/pages/Requests";
import AboutUs from "./containers/ui/pages/About_us";
import Dashboard from "./containers/ui/pages/dashboard";
import UploadPage from "./containers/ui/pages/UploadPage";
import Profile from "./containers/ui/pages/Profile";
import Dataset_view from "./containers/ui/components/Datasetview";
import SentRequests from "./containers/ui/pages/SentRequests";
import Mobile from "./containers/ui/pages/Mobile";
import Contacts from "./containers/ui/pages/Contacts";
import UserGuide from "./containers/ui/pages/UserGuide";
import PrivacyUpdate from "./containers/ui/pages/privacy_update";
import PrivacyPolicy from "./containers/ui/pages/Privacy_policy";

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("authUser");
    if (user) {
      autoLogin();
    }
  }, []);

  //To log the user in, automatically
  useEffect(() => {
    const user = localStorage.getItem("authUser");
    if (user) {
      autoLogin();
    }
  }, []);

  return (
    <>
      {/* {isMobile ? (
        <Mobile />
      ) : ( */}
      <SnackbarProvider maxSnack={3}>
        <div>
          <div style={{ display: "flex" }} className="App">
            <div style={{ flex: 1 }}>
              <BrowserRouter>
                <Switch>
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/filter" component={Filter_search} />
                  <Route exact path="/about-us" component={AboutUs} />
                  <Route exact path="/contact-us" component={Contacts} />
                  <Route exact path="/guide" component={UserGuide} />
                  <Route exact path="/dataset/:id" component={Dataset_view} />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={PrivacyPolicy}
                  />
                  {/* user protected routes */}
                  <ProtectedUserRoute
                    exact
                    path="/profile"
                    component={Profile}
                  />
                  <ProtectedUserRoute
                    exact
                    path="/upload"
                    component={UploadPage}
                  />
                  <ProtectedUserRoute
                    exact
                    path="/dash"
                    component={Dashboard}
                  />
                  <ProtectedUserRoute
                    exact
                    path="/requests/recieved"
                    component={Requests}
                  />
                  <ProtectedUserRoute
                    exact
                    path="/requests/sent"
                    component={SentRequests}
                  />
                  <ProtectedUserRoute
                    exact
                    path="/privacy/update"
                    component={PrivacyUpdate}
                  />
                  {/* end of user protected routes */}
                  {/* <Route exact path="/" component={HomePage} />
                <Route exact path="/details/:id" component={Details} /> */}
                  {/* 
                <Route exact path="/signin" component={Signin} />
                <Route exact path="/signup" component={SignupNew} />
                <Route exact path="/reset" component={PasswordResetNew} />
                <Route component={AdminSignin} path="/admin" /> */}

                  {/* protected toutes */}
                  {/* <ProtectedRoute component={DashboardNew} path="/dashboard" />
                <ProtectedRoute component={AccessRequests} path="/access" />
                <ProtectedRoute
                  component={DatasetRequestsNew}
                  path="/requests"
                /> */}
                  {/* <ProtectedRoute component={CreateDataset} path="/edit" />
                <ProtectedRoute component={EditProfile} path="/profile" />
                <ProtectedRoute component={VerificationForm} path="/verify" /> */}
                  <AdminProtectedRoute
                    component={AdminDashboard}
                    path="/ad-dashboard"
                  />
                  <Route component={NotFound} path="*" />
                </Switch>
              </BrowserRouter>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </SnackbarProvider>
      {/* )} */}
    </>
  );
};
export default App;
