import axios from "axios";
import { Base_Url } from "./variable";
const instance = axios.create({
  baseURL: `${Base_Url}/`,
});

export const AXIOS_POST = async (Url, data, token = undefined, lang = "fr") => {
  return await instance({
    url: Url,
    data: data,
    method: "POST",
    headers: {
      authorization: token,
      // "Content-Type": "application/x-www-form-urlencoded",
      "Accept-Language": lang,
    },
  });
};

export const AXIOS_GET = async (Url, token = undefined) => {
  return await instance({
    url: Url,
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const AXIOS_PUT = async (Url, data, token = undefined, lang = "fr") => {
  return await instance({
    url: Url,
    method: "PUT",
    data: data,
    headers: {
      Authorization: token,
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept-Language": lang,
    },
  });
};

export const AXIOS_DELETE = async (Url, data, token = undefined) => {
  const result = await instance({
    url: Url,
    method: "DELETE",
    data: data,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  return result;
};

export const AXIOS_POST_FILE = async (Url, data, token = undefined) => {
  return await instance({
    url: Url,
    method: "POST",
    data: data,
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    },
  });
};
export const AXIOS_GET_PDF = async (Url, token = undefined) => {
  return await instance({
    url: Url,
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const AXIOS_PATCH = async (Url, data, token = undefined) => {
  console.log(token, "---------------------------------");
  return await instance({
    url: Url,
    data: JSON.stringify(data),
    method: "PATCH",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
};
export default instance;
