import React, { useState } from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AXIOS_POST } from "../config/axios";
import { POST_VERIFY_URL } from "../config/url";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../../app/features/authSlicer";
import Translate from "../translate";

const VerifyUser = ({ onCancel, loginForm }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const handleChange = (value) => {
    setValue(value);
  };
  const user = localStorage.getItem("authUser");
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    formState: { errors },
  } = useForm();
  const handleNavigation = (path) => {
    history.push(path); // Replace '/path' with the desired URL or route
  };

  const handleComplete = () => {};
  const submitForm = (e) => {
    e.preventDefault();

    AXIOS_POST(POST_VERIFY_URL, {
      email: localStorage.getItem("user"),
      otp: value,
    })
      .then((res) => {
        if(!res.data){
          return
        }
        toast.success("Account verified successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
        setTimeout(() => loginForm(), 2000);
      })
      .catch((error) => {});
  };
  return (
    <Container>
      <ToastContainer />
      <FormContainer onSubmit={submitForm}>
        <Title>
          <Translate Word={"OTP-verification-code"} />
          <p style={{ fontSize: "16px", fontWeight: "normal" }}>
            <Translate Word={"we-have-sent-verification-code"} />
          </p>
        </Title>
        <InputContainer class="box">
          <Input
            length={5}
            onComplete={handleComplete}
            value={value}
            onChange={handleChange}
            required
            // {...register("otp")}
          />
        </InputContainer>
        <ButtonContainer>
          <SubmitButton>
            <Translate Word={"verify-me"} />
          </SubmitButton>
          <CancelButton onClick={onCancel}>
            <Translate Word={"cancel"} />
          </CancelButton>
        </ButtonContainer>
        <ResendVerify>
          <p>
            <Translate Word={"does-not-receive-otp-code"} />?{" "}
            <span>
              <Translate Word={"resend"} />
            </span>
          </p>
        </ResendVerify>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: color-interpolation-filters;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;
  position: relative;
  top: 372px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
const FormContainer = styled.form`
  width: 350px;
  height: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  transition: 0.4s ease-in-out;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    scale: 0.99;
  }
`;
const Title = styled.p`
  position: relative;
  text-align: center;
  color: black;
  top: 2em;
  font-weight: bold;
`;
const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  top: 1.5em;
  width: 100%;
  margin: 2%;
  justify-content: center;
  align-items: center;
`;
const SubmitButton = styled.button`
  position: relative;
  background-color: #01944D;
  margin-right: 13px;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;

  &:active {
    box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
  }
`;
const CancelButton = styled.button`
  margin-left: 13px;
  background-color: #989393;
  position: relative;
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;
  &:active {
    box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
  }
`;
const Input = styled(MuiOtpInput)`
  padding: 0;
  margin: 0;
  width: 90%;
`;
const ResendVerify = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px 0px 20px;
  align-items: center;
  margin: 0;
  p {
    line-height: 0%;
  }
  p span {
    line-height: 1;
    color: #01944D;
    cursor: pointer;
  }
`;
export default VerifyUser;
