import { useEffect, useState, useRef } from "react"
import AccountsList from "./accounts/AccountsList"
import DatasetReqList from "./dataset_requests/DatasetReqList"
import DatasetsList from "./datasets/DatasetsList"
import VerificationListPage from "./verifications/VerificationListPage"
import { getTokenFromLocalStorage } from "../../../utils/localStorageUtil"
import { getAllAccounts, getVerificationRequests } from "../../../services/admin/AdminDataAPI"
import GetPublicDataService from "../../../services/GetPublicDataService"

const DashboarNav =(props)=>{

    const [requestList, setRequestList] = useState([])
    const [loading, setLoading] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [datasets, setDatasets] = useState([])
    const [updated, setUpdated] = useState(false)
    const [verificationStatus, setVerificationStatus] = useState("unapproved")
    const initialVerStatus = useRef('unapproved');

   const arangeRequestsData =(requestList)=>{
    
        const data= requestList.map((elemet)=>{
          if(elemet.sender){
            var sender = elemet.sender
            delete sender.code
            return {...elemet, ...sender}
          }else{
        //   In case the sender key is missing, I add a dummy sender and an invalid key to not show the more details popup
            let dummySender = {name: 'Deleted', email: 'deleted@notexist.zx', account: 'individual', isVerified: false, organization: 'Deleted'}
            elemet.sender = dummySender
            return {...elemet, ...elemet.sender, invalid:true}
          }
        })
        delete data.sender

        // console.log(data)
        return data     
        
   }

   const getRequests = async()=>{
        setLoading(true)
        const token = getTokenFromLocalStorage()
        const response1 = await getVerificationRequests({data:{token:token}, cursor:"start", status:verificationStatus})
    
        const response2 = await getAllAccounts({data:{token:token}, cursor:"start"})
        
        const api = new GetPublicDataService();

        const response3 = await api.getPublicData();
        console.log(response1)
        setRequestList(arangeRequestsData(response1.data))
        setAccounts(response2.data)
        setDatasets(response3.data)

        setLoading(false)
    }

    const getRequestsForUpdate = async()=>{
        // setLoading(true)
        const token = getTokenFromLocalStorage()
        // console.log(token)
        const response1 = await getVerificationRequests({data:{token:token}, cursor:"start", status:verificationStatus})
       
        const response2 = await getAllAccounts({data:{token:token}, cursor:"start"})
        
        const api = new GetPublicDataService();
    
        const response3 = await api.getPublicData();
        
        setRequestList( arangeRequestsData(response1.data))
        setAccounts(response2.data)
        setDatasets(response3.data)
    
        // console.log(response)
        // setLoading(false)
    }
    useEffect(()=>{    
        getRequests()
    },[])

    useEffect(()=>{
        if(updated){
            getRequestsForUpdate()
            setUpdated(false)
        }
    },[updated])

    useEffect(()=>{

        const getRequest= async()=>{
            const token = getTokenFromLocalStorage()
            const response1 = await getVerificationRequests({data:{token:token}, cursor:"start", status:verificationStatus})
            setRequestList(arangeRequestsData(response1.data))
        }
        if(verificationStatus !== initialVerStatus){
            getRequest()
        }
        initialVerStatus.current = verificationStatus;

    },[verificationStatus])

    if(props.page === 'accounts'){
        return <AccountsList {...props} setUpdated={setUpdated} accounts={accounts} loading={loading} />
    }else if(props.page=== 'verifications'){
        return <VerificationListPage status={verificationStatus} setStatus={setVerificationStatus} {...props} setUpdated={setUpdated} requestList={requestList} loading={loading}  />
    }else if(props.page === 'datasets_requests'){
        return <DatasetReqList {...props} />
    }else if(props.page === 'datasets'){
        return <DatasetsList {...props} setUpdated={setUpdated} datasets={datasets} loading={loading} />
    }
}

export default DashboarNav