import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./features/authenticationSlice";
import authReducer from "./features/authSlicer";
import dashboard from "./features/dashboard";
import dataset from "./features/dataset";
import datasets from "./features/datasets";
import upload from "./features/datauploads";
import LanguageReducer from "./features/languageSlice";
import authSlicer from "./features/authSlicer";

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    datasets: datasets,
    dataset: dataset,
    dashboard: dashboard,
    upload: upload,
    auth: authReducer,
    language: LanguageReducer,
  },
});

export const rootState = store.getState;
export const appDispatch = store.dispatch;
