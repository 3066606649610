import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { ToastContainer, toast } from "react-toastify";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";
import ReactDOMServer from 'react-dom/server';
import AXIOS_POST_FILE, { AXIOS_GET, AXIOS_POST } from "../config/axios";

import {
  Box,
  TextField,
  createTheme,
  Select,
  ThemeProvider,
} from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { countryList } from "../../../helper/country";
import Translate, {transalateToString } from "../translate";
import { selectLanguage } from "../../../app/features/languageSlice";
import { useSelector } from "react-redux";

const UploadForm = () => {

  const languageValue = useSelector(selectLanguage);

  const history = useHistory();
  const [accessibility, setAccessibility] = useState("Public");
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const [IsFileType, setIsFileType] = useState(false);
  const [loading, setLoading] = useState(false);

  //form values
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");

  const [source, setSource] = useState("");
  const [sourceError, setSourceError] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");

  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");

  const [link, setLink] = useState("");
  const [linkError, setLinkError] = useState("");

  const [tags, setTags] = useState("");
  const [tagsError, setTagsError] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [isValidated, setIsValidated] = useState(false);
  const handleFileType = (e) => {
    e.preventDefault();
    setIsFileType(!IsFileType);
  };
  const handleSubmit = (e) => {
    setIsValidated(true);
    if (category.trim() === "") {
      setCategoryError("Category is required");
      setIsValidated(false);
      // console.log("================================", category);
    } else {
      setCategoryError("");
    }
    if (title.trim() === "") {
      setTitleError("Title is required");
      // console.log("================================", title);
      setIsValidated(false);
    } else {
      setTitleError("");
    }

    if (source.trim() === "") {
      setSourceError("Source is required");
      // console.log("================================", source);
      setIsValidated(false);
    } else {
      setSourceError("");
    }

    if (country.trim() === "") {
      setCountryError("country is required");
      // console.log("================================", country);
      setIsValidated(false);
    } else {
      setCountryError("");
    }

    // if (file.trim() === "") {
    //   setFileError("File is required");
    //   setIsValidated(false);
    // } else {
    //   setFileError("");
    // }

    if (link.trim() === "") {
      setLinkError("Link is required");
      // console.log("================================", link);
      setIsValidated(false);
    } else {
      setLinkError("");
    }

    if (tags.trim() !== "") {
      validateTags(tags);
    }

    if (description.trim() === "") {
      setDescriptionError("Description is required");
      // console.log("================================", description);
    } else {
      setDescriptionError("");
    }
    // console.log("================================", isValidated);
    const formData = new FormData();
    const jsonData = {};
    if (!link) {
      // Create a FormData object to store the form data
      formData.append("name", title);
      formData.append("organization", source);
      formData.append("isPrivate", accessibility == "Public" ? false : true);
      formData.append("country", country);
      formData.append("tags", formatTags(tags));
      formData.append(`${link ? "link" : "data"}`, link ? link : file);
      formData.append("type", link ? "link" : "file");
      formData.append("description", description);
      formData.append("category", category);
    } else {
      jsonData.name = title;
      jsonData.organization = source;
      jsonData.isPrivate = accessibility === "Public" ? false : true;
      jsonData.country = country;
      jsonData.tags = tags;
      jsonData[link ? "link" : "data"] = link ? link : file;
      jsonData.type = link ? "link" : "file";
      jsonData.description = description;
      jsonData.category = category;
    }

    try {
      if (isValidated) {
        setLoading(true);
        AXIOS_POST(
          link ? "/link" : "/data",
          link ? jsonData : formData,
          sessionStorage.getItem("token")
        ).then((response) => {
          // console.log(response.status)
          // if(response.)
          toast.success("Dataset uploaded successfull", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
          setTimeout(function() { }, 2000);
          history.push("/dash");
        }).catch((error)=>{
          setLoading(false);
          // console.error(error); // Handle the error
          toast.error(error.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        })
      }
    } catch (error) {
      setLoading(false);
      // console.error(error); // Handle the error
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "colored",
      });
    } finally {
      // Set loading back to false after the request is completed (success or error)
      setLoading(false);
    }
  };
  const validateTags = () => {
    const regex = /^("[\w\s]+",?\s*)*"[^,"]*"$/;
    // Regular expression for the specified format
    if (!regex.test(tags)) {
      setIsValidated(false);
      // console.log("================================", tags);
      setTagsError(
        'Tags must be in the format: "Education","Quality Education",'
      );
    } else {
      setTagsError("");
    }
  };
  const formatTags = (value) => {
    const formattedTags = value
      .split(",")
      .map((tag) => tag.trim().replace(/"/g, ""))
      .map((tag) => tag.charAt(0).toUpperCase() + tag.slice(1));
    setTags(formattedTags.join(", "));
  };

  return (
    <Container>
      <ToastContainer />
      <Title><Translate Word="dataset-upload-form-title"/></Title>
      <InputContainer>
        <FormControl>
          <label htmlFor="title">
            <Translate Word="dataset-upload-form-label-title" />
          </label>
          <input
            onChange={(e) => {
              setTitle(e.target.value);
              setTitleError("");
            }}
            type="text"
            value={title}
            placeholder="E.g: Education in East Africa"
            id="title"
          />
        </FormControl>
        <FormControl>
          <label htmlFor="source">Source</label>
          <input
            value={source}
            onChange={(e) => {
              setSource(e.target.value);
              setSourceError("");
            }}
            type="text"
            placeholder="E.g: World Bank"
            id="source"
          />
        </FormControl>
      </InputContainer>
      <InputContainer>
        <FormControl>
          {titleError && <FormHelperText error>{titleError}</FormHelperText>}
        </FormControl>
        <FormControl>
          {sourceError && <FormHelperText error>{sourceError}</FormHelperText>}
        </FormControl>
      </InputContainer>
      <InputContainer>
        <FormControl>
          <label htmlFor="tags">Tags</label>
          <input
            value={tags}
            onChange={(e) => {
              setTags(e.target.value);
              validateTags();
            }}
            type="text"
            placeholder='E.g: "Education", "africa", "East Africa"'
            id="tags"
          />
        </FormControl>
        <FormControl>
          <label htmlFor="country"><Translate Word="dataset-upload-form-label-country" /></label>
          <select
            value={country}
            onChange={(e) => {
              setCountry(e.target.value);
              setCountryError("");
            }}
            type="text"
            // placeholder="E.g: World Bank"
            id="country"
          >
            <option value="" disabled selected>
              {transalateToString("dataset-upload-form-placeholder-country", languageValue )}
              {/* Select Country related to dataset */}
            </option>
            {countryList.map((country) => (
              <option value={country}>{country}</option>
            ))}
          </select>
        </FormControl>
      </InputContainer>
      <InputContainer>
        <FormControl>
          {tagsError && <FormHelperText error>{tagsError}</FormHelperText>}
        </FormControl>
        <FormControl>
          {countryError && (
            <FormHelperText error>{countryError}</FormHelperText>
          )}
        </FormControl>
      </InputContainer>
      <InputContainer>
        <FormControl>
          <label htmlFor="category">Category</label>
          <input
            type="text"
            list="options"
            id="search-input"
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            placeholder="Type to search"
          />
          <datalist id="options">
            <option value="Education" />
            <option value="Health" />
            <option value="Geospatiol" />
            <option value="Others" />
          </datalist>
        </FormControl>
        <FormControl>
          <label htmlFor="access">Accessibility</label>
          <select
            id="access"
            value={accessibility}
            onChange={(e) => setAccessibility(e.target.value)}
          >
            <option value="Public" selected>
              Public
            </option>
            <option value="Private">Private</option>
          </select>
        </FormControl>
      </InputContainer>
      <InputContainer>
        <FormControl>
          {categoryError && (
            <FormHelperText error>{categoryError}</FormHelperText>
          )}
        </FormControl>
        <FormControl></FormControl>
      </InputContainer>
      <FileContainer>
        <DTitle><Translate Word="dataset-upload-form-label-description" /></DTitle>
        <StyledTextareaAutosize
          label="Description"
          labelId="textareaLabel"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          error={!!descriptionError}
          helperText={descriptionError}
          id="textarea"
          minRows={3}
          placeholder={transalateToString("dataset-upload-form-placeholder-description", languageValue)}
          // placeholder={<Translate Word="dataset-upload-form-placeholder-description"/>}
          />
        <DTitle>
        <Translate Word="dataset-upload-form-label-paste-link" />
          {/* {!IsFileType
            ? "Paste a link to the dataset"
            : "Choose the file (.csv)"}{" "}
          or{" "}
          <a href="" onClick={handleFileType}>
            {!IsFileType ? "Upload a file" : "Paste a dataset link"}
          </a> */}
          <FileInput>
            {IsFileType ? (
              <input type="File" />
            ) : (
              <input
                type="text"
                name="link"
                onChange={(e) => {
                  setLink(e.target.value);
                }}
                error={!!linkError}
                helperText={linkError}
                placeholder={transalateToString("dataset-upload-form-placeholder-paste-link", languageValue)}
              />
            )}
          </FileInput>
        </DTitle>
      </FileContainer>
      <SubmitContainer>
        <SubmitButton disabled={loading} onClick={handleSubmit}>
          {loading ? <Translate Word="dataset-upload-form-button-submit-loading" /> : <Translate Word="dataset-upload-form-button-submit" />}
        </SubmitButton>
      </SubmitContainer>
    </Container>
  );
};
const FormControl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 50%;
  padding: 1% 2%;
  gap: 0;
  input,
  select {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 3px;
    resize: vertical;
    padding: 0.3em 0;
    margin: 0; /* Remove margin */
  }
  label {
    margin: 0;
    color: #01944D;
    font-family: "Bebas Neue", cursive;
    font-size: 15px;
  }
`;
const override = css`
  display: block;
  margin: 0 auto;
`;

const loaderColor = "#ffffff"; // Customize the color of the loader
const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
`;
const SubmitContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const SubmitButton = styled.button`
  cursor: pointer;
  width: 90%;
  padding: 5px;
  border: none;
  background-color: #01944D;
  font-family: "Bebas Neue", cursive;
  color: white;
  border-radius: 2px;
`;
const FileInput = styled.div`
  height: 50px;
  width: 100%;
  input {
    width: 100%;
    height: inherit;
  }
`;
const DTitle = styled.p`
  width: 100%;
  text-align: center;
  font-family: "Bebas Neue", cursive;
`;
const StyledTextareaAutosize = styled(TextareaAutosize)`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  width: 100%;
  resize: vertical;
  margin-bottom: 10px;
`;
const InputContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  gap: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
const Title = styled.p`
  font-family: "Bebas Neue", cursive;
  text-align: center;
`;
const Container = styled.div`
  width: 70%;
  margin: 1%;
  padding: 2%;
  background: white;

  @media screen and (max-width: 768px) {
    width: 98%;
  }
`;
export default UploadForm;
