import React from "react";
import "./footer.css";
import { FaTwitter } from "react-icons/fa";
import { AiFillGithub } from "react-icons/ai";

const Footer = (props) => {
  return (
    <div
      style={{
        borderTop: "solid",
        borderTopWidth: 0.7,
        borderTopColor: "#EBE9E9",
      }}
      className="m-footer"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 m-footer-elements">
            <h4 className="m-logo logo-color">Open Data Portal</h4>
            <p className="m-footer-owner"> CMU Africa | Cylab Africa</p>
          </div>
          <div className="col-md-3 m-footer-elements">
            <ul className="m-footer-list">
              <li>
                <a
                  target=""
                  href="https://www.africa.engineering.cmu.edu/research/cylab/index.html"
                >
                  Cylab Africa
                </a>{" "}
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.africa.engineering.cmu.edu/"
                >
                  CMU Africa
                </a>
              </li>
              <li>
                <a href="">Contacts</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 m-footer-elements">
            <ul className="m-footer-list">
              <li>
                {" "}
                <FaTwitter size={13} />{" "}
                <a target="_blank" href="https://twitter.com/CyLabAfrica">
                  Twitter
                </a>
              </li>
              <li>
                {" "}
                <AiFillGithub size={13} />{" "}
                <a
                  target="_blank"
                  href="https://github.com/Application-Data-Platform-CMU-Africa"
                >
                  Github
                </a>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
