import { React, useEffect, useState } from "react";
import "./page.css";
import homepage_image from "./english/homepage.png";
import resultspage_image from "./english/resultspage.png";
import filter_image from "./english/filters.png";
import signuppage_image from "./english/signuppage.png";
import loginpage_image from "./english/loginpage.png";
import dashboard_image from "./english/dashboardpage.png";
import profilepage_image from "./english/profilepage.png";
import upladdataset_image from "./english/UploadDatasetPage.png";

import homepage_image_french from "./french/homepage-french.png";
import resultspage_image_french from "./french/resultspage-french.png";
import filter_image_french from "./french/filters-french.png";
import signuppage_image_french from "./french/signuppage-french.png";
import loginpage_image_french from "./french/loginpage-french.png";
import dashboard_image_french from "./french/dashboardpage-french.png";
import profilepage_image_french from "./french/profilepage-french.png";
import upladdataset_image_french from "./french/UploadDatasetPage-french.png";

import Header from "../layouts/header";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import Translate, { RichTextTransalater } from "../translate";
import Fab from "@mui/material/Fab";
import styled from "styled-components";
import Footer from "../layouts/footer";
import { AXIOS_GET } from "../config/axios";
import { GET_LANDING } from "../config/url";
import ContentLoader, { Facebook } from "react-content-loader";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import AppLayout from "../layouts/AppLayout";
import { selectLanguage } from "../../../app/features/languageSlice";
import { useSelector } from "react-redux";
import { scrollToTop } from "../../../utils/scroll";


const UserGuide = () => {
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const languageValue = useSelector(selectLanguage);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };



  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    AXIOS_GET(GET_LANDING)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <AppLayout>
      <AboutContainer>
        <div className="right item" style={{ flex: 1 }}>
          <DataIcon style={{ fontSize: "200px" }} />
        </div>
        <div className="left item" style={{ flex: 5 }}>
          <h1 style={{ fontWeight: "bold" }}>
            <Translate Word={"guide-title"} />
          </h1>
          {loading ? (
            <ContentLoader>
              <rect x="20" y="17" rx="4" ry="4" width="300" height="13" />
              <rect x="20" y="40" rx="3" ry="3" width="250" height="10" />
            </ContentLoader>
          ) : (
            <>
              <h3>
                <Translate Word="guide-background" />
              </h3>

              <Paragraph>
                <Translate Word={"guide-opendata-background"} />
              </Paragraph>
              <p></p>
              <br />

              <h3>
                <Translate Word="guide-content" />
              </h3>

              <ul>
                <li>
                  {" "}
                  <a href="#find-dataset">
                    <Translate Word={"guide-finddataset"} />
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#filter-results">
                    <Translate Word={"guide-filterdataset"} />
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#create-an-account">
                    <Translate Word={"guide-createaccount"} />
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#login">
                    <Translate Word={"guide-logintitle"} />
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#dashboard">
                    <Translate Word={"guide-dashboardtitle"} />
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#upload-dataset">
                    <Translate Word={"guide-uploaddataset"} />
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#update-profile">
                    <Translate Word={"guide-updateprofile"} />
                  </a>
                </li>
              </ul>
              <p></p>
              <br />
              <h3 id="find-dataset">
                <Translate Word={"guide-finddataset"} />
              </h3>

              <Paragraph>
                <RichTextTransalater Word={"guide-find-dataset"} />
              </Paragraph>
              <img
                style={{ width: "90%", border: "solid #01944D 0.5px" }}
                src={languageValue === "En"? homepage_image:homepage_image_french}
                alt=""
              />
              <Paragraph>
                <RichTextTransalater Word={"guide-find-dataset-details"} />
              </Paragraph>
              <img
                style={{ width: "90%", border: "solid #01944D 0.5px" }}
                src={languageValue === "En"? resultspage_image:resultspage_image_french}
                alt=""
              />
              <Paragraph>
                <RichTextTransalater Word={"guide-find-dataset-more-details"} />
              </Paragraph>
              <br />

              <h3 id="filter-results">
                <Translate Word={"guide-filterdataset"} />
              </h3>
              <Paragraph>
                <RichTextTransalater Word={"guide-find-dataset-filters"} />
              </Paragraph>
              <img
                style={{ width: "90%", border: "solid #01944D 0.5px" }}
                src={languageValue === "En"? filter_image:filter_image_french}
                alt=""
              />
              <p></p>
              <br />

              <h3 id="create-an-account">
                <Translate Word={"guide-createaccount"} />
              </h3>
              <Paragraph>
                <RichTextTransalater Word={"guide-create-account"} />
              </Paragraph>
              <img
                style={{ width: "90%", border: "solid #01944D 0.5px" }}
                src={languageValue === "En" ? signuppage_image:signuppage_image_french}
                alt=""
              />

              <Paragraph>
                <RichTextTransalater Word={"guide-create-account-more"} />
              </Paragraph>
              <br />
              <h3 id="login">
                <Translate Word={"guide-logintitle"} />
              </h3>
              <Paragraph>
                <RichTextTransalater Word={"guide-login"} />
              </Paragraph>
              <img
                style={{ width: "90%", border: "solid #01944D 0.5px" }}
                src={languageValue === "En"? loginpage_image:loginpage_image_french}
                alt=""
              />
              <p></p>
              <br />
              <h3 id="dashboard">
                <Translate Word={"guide-dashboardtitle"} />
              </h3>
              <Paragraph>
                <RichTextTransalater Word={"guide-dashboard"} />
              </Paragraph>
              <img
                style={{ width: "90%", border: "solid #01944D 0.5px" }}
                src={languageValue === "En" ? dashboard_image:dashboard_image_french}
                alt=""
              />
              {/* guide-dashboard-more */}
              <Paragraph>
                <RichTextTransalater Word={"guide-dashboard-more"} />
              </Paragraph>
              <p></p>
              <br />

              <h3 id="upload-dataset">
                <Translate Word={"guide-uploaddataset"} />
              </h3>
              <Paragraph>
                <RichTextTransalater Word={"guide-upload-dataset"} />
              </Paragraph>
              <img
                style={{ width: "90%", border: "solid #01944D 0.5px" }}
                src={languageValue === "En" ? upladdataset_image : upladdataset_image_french}
                alt=""
              />
              <Paragraph>
                <RichTextTransalater Word={"guide-upload-dataset-more"} />
              </Paragraph>
              <p></p>
              <br />
              <h3 id="update-profile">
                <Translate Word={"guide-updateprofile"} />
              </h3>
              <Paragraph>
                <RichTextTransalater Word={"guide-update-profile"} />
              </Paragraph>
              <img
                style={{ width: "90%", border: "solid #01944D 0.5px" }}
                src={languageValue === "En"? profilepage_image: profilepage_image_french}
                alt=""
              />
              {isVisible && (
                <Fab
                  onClick={scrollToTop}
                  style={{ position: "fixed", bottom: 100, right: 70 }}
                  sx={"fabStyle as SxProps"}
                  aria-label={"fab.label"}
                  color={"red"}
                >
                  <UpIcon />
                </Fab>
              )}
            </>
          )}
        </div>
      </AboutContainer>
    </AppLayout>
  );
};
const DataIcon = styled(DataUsageIcon)`
  vertical-align: bottom;
  color: #01944D;
  padding-right: 40px;
`;

const Paragraph = styled.p`
  width: 90%;
`;
const AboutContainer = styled.div`
  background: #e1dfdf;
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  // align-items: center;
`;
export default UserGuide;
