// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* :root {
    --main_blue: #4679B2;
    --text_grey:#8F8E8E;
    --text_black:#252424;
    --footer_bg:#D9D9D9;
} */

.m-footer {
  font-family: "Bebas Neue", cursive;
  min-height: 250px;
  background-color: var(--footer_bg);
  display: flex;
  align-items: center;
}

.m-footer-owner {
  text-align: left;
  color: var(--main_blue);
}
.m-footer-list {
  list-style: none;
}

.m-footer-list li {
  margin-bottom: 12px;
  font-size: 17px;
  font-weight: bold;
  color: var(--main_color);
  cursor: pointer;
  /* padding-bottom: 2px; */
}

.m-footer-elements {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* text-align: center; */
  flex-direction: column;
  margin-top: 20px;
}

@media only screen and (max-width: 900px) {
  .m-logo,
  .m-footer-owner {
    /* margin-left: 60px; */
  }
  .m-footer-elements {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin-top: 20px;
  }
  .m-footer-elements li {
    margin-left: -50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/new_components/footer/footer.css"],"names":[],"mappings":"AAAA;;;;;GAKG;;AAEH;EACE,kCAAkC;EAClC,iBAAiB;EACjB,kCAAkC;EAClC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,wBAAwB;EACxB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,wBAAwB;EACxB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE;;IAEE,uBAAuB;EACzB;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;EAClB;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":["/* :root {\n    --main_blue: #4679B2;\n    --text_grey:#8F8E8E;\n    --text_black:#252424;\n    --footer_bg:#D9D9D9;\n} */\n\n.m-footer {\n  font-family: \"Bebas Neue\", cursive;\n  min-height: 250px;\n  background-color: var(--footer_bg);\n  display: flex;\n  align-items: center;\n}\n\n.m-footer-owner {\n  text-align: left;\n  color: var(--main_blue);\n}\n.m-footer-list {\n  list-style: none;\n}\n\n.m-footer-list li {\n  margin-bottom: 12px;\n  font-size: 17px;\n  font-weight: bold;\n  color: var(--main_color);\n  cursor: pointer;\n  /* padding-bottom: 2px; */\n}\n\n.m-footer-elements {\n  display: flex;\n  align-items: center;\n  /* justify-content: center; */\n  /* text-align: center; */\n  flex-direction: column;\n  margin-top: 20px;\n}\n\n@media only screen and (max-width: 900px) {\n  .m-logo,\n  .m-footer-owner {\n    /* margin-left: 60px; */\n  }\n  .m-footer-elements {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    flex-direction: column;\n    margin-top: 20px;\n  }\n  .m-footer-elements li {\n    margin-left: -50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
