// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/*  for the display messages component */

.m-messages{
    /* min-height: 40px; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main_blue);
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10%;
    padding-right: 10%;
}
.m-messages-quotation{
    color: var(--footer_bg);
    margin-left: 4px;
    font-size: 13px;
    font-style: italic;
}
.m-messages-content{
clear: both;
display: inline-block;
overflow: hidden;
white-space: nowrap;
}

.m-messages-quotation:hover{
    color: #fff;
    margin-left: 4px;
    font-size: 13px;
    font-style: italic;
}


h5{
    font-weight: bold;
    font-size: 22px;
}
.m-messages p{
    text-align: left;
    font-size: 17px;
}


@media only screen and (max-width: 900px) {
    .m-messages{
        /* padding-left: 2%;
        padding-right: 2%;
        text-align: center; */
        display: none;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/new_components/messages_bar/messages.css"],"names":[],"mappings":";;AAEA,wCAAwC;;AAExC;IACI,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kCAAkC;IAClC,WAAW;IACX,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;AACA;AACA,WAAW;AACX,qBAAqB;AACrB,gBAAgB;AAChB,mBAAmB;AACnB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;;AAGA;IACI,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;;;AAGA;IACI;QACI;;6BAEqB;QACrB,aAAa;IACjB;EACF","sourcesContent":["\n\n/*  for the display messages component */\n\n.m-messages{\n    /* min-height: 40px; */\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: var(--main_blue);\n    color: #fff;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 10%;\n    padding-right: 10%;\n}\n.m-messages-quotation{\n    color: var(--footer_bg);\n    margin-left: 4px;\n    font-size: 13px;\n    font-style: italic;\n}\n.m-messages-content{\nclear: both;\ndisplay: inline-block;\noverflow: hidden;\nwhite-space: nowrap;\n}\n\n.m-messages-quotation:hover{\n    color: #fff;\n    margin-left: 4px;\n    font-size: 13px;\n    font-style: italic;\n}\n\n\nh5{\n    font-weight: bold;\n    font-size: 22px;\n}\n.m-messages p{\n    text-align: left;\n    font-size: 17px;\n}\n\n\n@media only screen and (max-width: 900px) {\n    .m-messages{\n        /* padding-left: 2%;\n        padding-right: 2%;\n        text-align: center; */\n        display: none;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
