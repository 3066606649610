// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-side-nav-item{
    display: flex;
    align-items: center;
    padding-left: 15px;
    height: 45px;
    margin-bottom: 2px;
    cursor: pointer;
    transition: 0.4s;
  }
  .admin-side-nav-item:hover{
    background-color: var(--footer_bg);
    color: var(--main_color);
  }

  .admin-side-nav-item-active{
    background-color: var(--footer_bg);
    color: var(--main_color);
  }

  .side-logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 100%;
    border-bottom: solid 1px rgb(211, 209, 209);
    transition: 0.7s;
  }
  
  .admin-logo-admin{
    color: #ffbc78;
  }
  .side-logo h4{
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
  }
  
  .side-logo:hover{
    background-color: var(--main_color);
    color: #fff ;
  }


  `, "",{"version":3,"sources":["webpack://./src/containers/admincite/sidenav/sidenav.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,kCAAkC;IAClC,wBAAwB;EAC1B;;EAEA;IACE,kCAAkC;IAClC,wBAAwB;EAC1B;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,2CAA2C;IAC3C,gBAAgB;EAClB;;EAEA;IACE,cAAc;EAChB;EACA;IACE,eAAe;IACf,yBAAyB;IACzB,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,mCAAmC;IACnC,YAAY;EACd","sourcesContent":[".admin-side-nav-item{\n    display: flex;\n    align-items: center;\n    padding-left: 15px;\n    height: 45px;\n    margin-bottom: 2px;\n    cursor: pointer;\n    transition: 0.4s;\n  }\n  .admin-side-nav-item:hover{\n    background-color: var(--footer_bg);\n    color: var(--main_color);\n  }\n\n  .admin-side-nav-item-active{\n    background-color: var(--footer_bg);\n    color: var(--main_color);\n  }\n\n  .side-logo{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 70px;\n    width: 100%;\n    border-bottom: solid 1px rgb(211, 209, 209);\n    transition: 0.7s;\n  }\n  \n  .admin-logo-admin{\n    color: #ffbc78;\n  }\n  .side-logo h4{\n    font-size: 20px;\n    text-transform: uppercase;\n    font-weight: bold;\n    cursor: pointer;\n  }\n  \n  .side-logo:hover{\n    background-color: var(--main_color);\n    color: #fff ;\n  }\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
