import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { ToastContainer, toast } from "react-toastify";
import LinkIcon from "@mui/icons-material/Link";
import DownloadIcon from "@mui/icons-material/Download";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import IosShareIcon from "@mui/icons-material/IosShare";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useHistory } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";
import { AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from "../config/axios";
import { DATASET } from "../config/url";
import { useEffect } from "react";

const DatasetDetails = ({ data, isAuthor, handleLikes }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // data tab: 1 = details, 2 = discussion, 3 = bookmarks
  const [datatabs, setDatatabs] = useState(1);
  const handleDelete = (code) => {
    if (!window.confirm("Are you sure you want to delete this dataset?")){
      return;
    }
    AXIOS_PATCH(
      `/datasets/${code}/delete/`,
      {},
      sessionStorage.getItem("token")
    )
      .then((response) => {
        // console.log(response);
        toast.success("Dataset deleted successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
      })
      .catch((error) => {
        // console.error(error);
      })
      .finally(() => {
        setTimeout(() => {
          history.push("/dash");
        }, 2000);
      });
  };
  const handleRequest = (id) => {
    setLoading(true);
    if (sessionStorage.getItem("token")) {
      AXIOS_POST(
        `${DATASET}/requests`,
        { file_requested: id },
        sessionStorage.getItem("token")
      )
        .then((response) => {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        })
        .finally(() => setLoading(false));
    } else {
      toast.error("Please sign in order to request access", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "colored",
      });
    }
  };
  const handleLinkClick = (e) => {
    e.preventDefault();
    if (sessionStorage.getItem("token")) {
      AXIOS_GET(`datasets/${data.code}/links`, sessionStorage.getItem("token"))
        .then((data) => {
          window.open(data.data.link, "_blank");
          // console.log(data.data.link);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      alert("Please log in to access this link");
    }
  };
  const handleLikesClick = () => {
    handleLikes();
    AXIOS_PATCH(
      `${DATASET}/${data.code}/likes`,
      {},
      sessionStorage.getItem("token")
    )
      .then((data) => {
        // console.log("Likes recorded");
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  return (
    <Container>
      <ToastContainer />
      <Content>
        <Icon>
          <DataIcon style={{ fontSize: 60, color: "#01944D" }} />
        </Icon>

        <Details>
          <sub>
            <span> {data.organization} </span>
          </sub>
          <span>
            {" "}
            By {data.creator.name ? data.creator.name : data.creator.username}
          </span>
          <span
            style={{
              fontStyle: "italic",
              fontSize: "12px",
              borderLeft: "2px solid #989393;",
            }}
          >
            {" At "}
            {data.updatedAt}
          </span>
          <br />
          <p style={{ paddingTop: "10px" }}>{data.description}</p>
          <LinkP>
            {data.is_private ? (
              <Link
                onClick={(event) => {
                  event.preventDefault();
                  handleRequest(data.code);
                }}
              >
                <IconRequest />
                {loading ? (
                  <BeatLoader color="#000000" css={override} size={10} />
                ) : (
                  "  Request Access"
                )}
              </Link>
            ) : (
              <>
                <Link href="" onClick={handleLinkClick}>
                  <IconLInk />
                  Link
                </Link>
                {data.url ? (
                  <></>
                ) : (
                  <a href="">
                    <IconDonwload />
                    Download
                  </a>
                )}
              </>
            )}
            {isAuthor ? (
              <>
                {" "}
                <Link
                  to={`/upload?update=true&d=${data.code}`}
                  // onClick={(event) => {
                  //   event.preventDefault();
                  // }}
                >
                  <IconEdit />
                  Edit
                </Link>
                <Link
                  onClick={(event) => {
                    event.preventDefault();
                    handleDelete(data.code);
                  }}
                >
                  <IconDelete />
                  Delete
                </Link>
              </>
            ) : (
              ""
            )}
            <span href="" onClick={handleLikesClick}>
              <Likes />
            </span>
          </LinkP>
        </Details>
      </Content>
    </Container>
  );
};

const Likes = styled(FavoriteIcon)`
  font-size: 20px;
  vertical-align: bottom;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: #01944D;
  }
`;
const override = css`
  display: block;
  margin: 0 auto;
`;
const IconEdit = styled(EditIcon)`
  vertical-align: bottom;
  padding-right: 2px;
`;
const IconDelete = styled(DeleteIcon)`
  vertical-align: bottom;
  padding-right: 2px;
`;
const IconRequest = styled(IosShareIcon)`
  vertical-align: bottom;
  padding-right: 2px;
`;
const IconLInk = styled(LinkIcon)`
  vertical-align: bottom;
  padding-right: 2px;
`;
const IconDonwload = styled(DownloadIcon)`
  vertical-align: bottom;
  padding-right: 2px;
`;
const IconReadMore = styled(ReadMoreIcon)`
  vertical-align: bottom;
  padding-right: 2px;
`;
const LinkP = styled.p`
  a {
    padding-right: 10px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
`;

const Icon = styled.div`
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
const DataIcon = styled(DataUsageIcon)`
  vertical-align: bottom;
  @media screen and (max-width: 768px) {
    font-size: 30px !important;
  }
`;
const Details = styled.div`
  width: 70%;
  sub {
    line-height: 0;
    margin-bottom: 5px;

    span {
      font-weight: bold;
      border-right: 2px solid #989393;
      line-height: 0;
      padding: 0 5px 0 0;
      margin: 0 5px 5px 0;
    }
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  height: 100%;
  width: 90%;
  border-radius: 4px;
  margin: 2% auto;
  padding: 10px;
  background: #f8f6f6;
`;
export default DatasetDetails;
