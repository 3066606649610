import React from "react";
import AppLayout from "../layouts/AppLayout";
import styled from "styled-components";

const NotFound = () => {
  return (
    <AppLayout>
      <Container>
        <h1>404</h1>
        <p>Oops page not found !</p>
      </Container>
    </AppLayout>
  );
};
const Container = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 40px;
    font-weight: bold;
    font-family: "Bebas Neue", cursive;
  }
  p {
    margin: 0;
    color: #01944D;
    font-family: "Bebas Neue", cursive;
    font-size: 25px;
  }
`;
export default NotFound;
