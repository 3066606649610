import React, { useState } from "react";
import styled from "styled-components";
import Translate from "../translate";
import { useForm } from "react-hook-form";
import { AXIOS_POST } from "../config/axios";
import { SUGGESTION } from "../config/url";
import Loader from "../../../helper/Loader";
import { FormControl } from "react-bootstrap";

const Suggestion = ({ handleClose }) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      category: "",
      name:"",
      description:"",
      category:"",
      contact:"",
      occupation:""
      // data: {}
    },
  });

  const onSubmit = async (data) => {
    data.location = await getCountry();
    console.log(data);
    setLoading(true);
    AXIOS_POST(SUGGESTION, data).then((response) => {
      if (!response.data) {
        alert("Something went wrong!")
        setLoading(false);
        return;
      }
      setLoading(false);
      handleClose();
    }).catch((error) => {
      // console.error("Error submitting suggestion:", error);
      alert(error.response? error.response.data.message:"Something went wrong")
      setLoading(false);
    })
    .finally(() => {
      setLoading(false);
    });
  }
  async function getCountry() {
    try {
      const response = await fetch("https://ipinfo.io/json");
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching country:", error);
      return null; // Handle the error gracefully in your application
    }
  }
  return (
    <>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Title>
          <Translate Word={"suggest-a-feature"} />
          <p style={{ fontSize: "16px", fontWeight: "normal" }}>
            <Translate Word={"suggestion-title"} />
          </p>
        </Title>

        <InputContainer>
              <label style={{textAlign:'left', width:'100%'}} htmlFor="">Names</label>
              <input
                // name="name"
                {...register("name", { required: true })}
                length={5}
                // onComplete={handleComplete}
                // value={value}
                // onChange={handleChange}
                // {...register("otp")}
                style={{width:'100%'}}
                placeholder="Your full name"
              />
        </InputContainer>


        <InputContainer>
              <label style={{textAlign:'left', width:'100%'}} htmlFor="">Email/Phone</label>

              <input
                // name="contact"
                {...register("contacts", { required: true })}
                length={5}
                // onComplete={handleComplete}
                // value={value}
                // onChange={handleChange}
                // {...register("otp")}
                type="text"
                style={{width:'100%'}}
                placeholder="Contact Email or Phone number"
              />
        </InputContainer>

        <InputContainer>
              <label style={{textAlign:'left', width:'100%'}} htmlFor="">Your current occupation</label>
              <input
                // name="occupation"
                {...register("occupation", { required: true })}
                length={5}
                // onComplete={handleComplete}
                // value={value}
                // onChange={handleChange}
                // {...register("otp")}
                style={{width:'100%'}}
                placeholder="Instructor, Students ..."
              />
        </InputContainer>


        <InputContainer>  
        <label style={{textAlign:'left', width:'100%'}} htmlFor="">Category</label>

          <SelectInput name="category" className="input_field" 
            {...register("category", { required: true })}
          required>
            <option value="" disabled selected>
              What do you want to suggest
            </option>
            <option value="data">New datasets</option>
            <option value="feature">A new feature</option>
            <option value="design">A Design suggestion</option>
            <option value="others">Others</option>
          </SelectInput>

        </InputContainer>
        
        <InputContainer>
        <label style={{textAlign:'left', width:'100%'}} htmlFor="">Suggestion</label>

          <TextAreaInput
          // name="description"
            {...register("description", { required: true })}
            rows={5}
            placeholder="Type your suggestion here..."
            className="input_field"
            required
          ></TextAreaInput>
        </InputContainer>
        <ButtonContainer>
          <SubmitButton onClick={handleSubmit}>
          {loading ? <Loader color="white" /> : <Translate Word={"send"} />}
          </SubmitButton>
          <CancelButton type="button" onClick={handleClose}>
            <Translate Word={"cancel"} />
          </CancelButton>
        </ButtonContainer>
      </FormContainer>
    </>
  );
};

const TextAreaInput = styled.textarea`
  position: relative;
  margin: 8px 5px;
  width: 100% !important  ;
  height: 6.4em !important;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: rgb(235, 235, 235);
  padding-left: 15px;
  transition: 0.4s ease-in-out;
  &:hover {
    box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
    background-color: lightgrey;
    }
    &:focus {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
      background-color: lightgrey;
      }
      `;

      

const SelectInput = styled.select`
  position: relative;
  margin: 8px 5px;
  width: 100%;
  height: 2.4em;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: rgb(235, 235, 235);
  padding-left: 15px;
  transition: 0.4s ease-in-out;
  `
const FormContainer = styled.form`
  width: 55%;
  height: 90%;
  // height: auto;
  overflow-y:scroll;
  overflow-x: hidden;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  transition: 0.4s ease-in-out;
  position: relative;

  &:hover {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    scale: 0.99;
  }
  svg {
    position: relative;
    align-self: center;
    top: 4em;
  }

    /* Scrollbar Styling */
    ::-webkit-scrollbar {
        width: 10px;
    }
 
    ::-webkit-scrollbar-track {
        background-color: #ebebeb;
        -webkit-border-radius: 10px;
        // border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        // border-radius: 10px;
        background: #6d6d6d; 
    }
`;
const Title = styled.p`
  position: relative;
  text-align: center;
  color: black;
  top: 1em;
  font-weight: bold;
`;
const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    position: relative;
    margin: 8px 5px;
    width: 70%;
    height: 2.4em;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: rgb(235, 235, 235);
    padding-left: 15px;
    transition: 0.4s ease-in-out;

    &:hover {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
      background-color: lightgrey;
    }
    &:focus {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
      background-color: lightgrey;
    }
  }
  textarea {
    position: relative;
    margin: 8px 5px;
    width: 80%;
    height: 4.4em;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: rgb(235, 235, 235);
    padding-left: 15px;
    transition: 0.4s ease-in-out;

    &:hover {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
      background-color: lightgrey;
    }
    &:focus {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
      background-color: lightgrey;
    }
  }
`;
const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  top: 0.5em;
  width: 100%;
  margin: 2%;
  justify-content: center;
  align-items: center;
`;
const SubmitButton = styled.button`
  position: relative;
  background-color: #01944D;
  margin-right: 13px;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;
  cursor: pointer;

  &:active {
    box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
  }
`;
const CancelButton = styled.button`
  margin-left: 13px;
  background-color: #989393;
  position: relative;
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;
  cursor: pointer;
  &:active {
    box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
  }
`;

export default Suggestion;
