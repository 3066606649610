import React from "react";
import { TextField } from "@mui/material";

const CustomTextField = ({
  label,
  placeholder,
  width,
  readOnly,
  disabled,
  value,
  onChange,
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <TextField
      type="text"
      width={width}
      onChange={handleChange}
      label={label}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled}
      size="medium"
      color="warning"
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        style: {
          borderColor: "#01944D",
          width: `${width}`,
        },
      }}
    />
  );
};
export default CustomTextField;
