import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import GetPublicDataService from "../../services/GetPublicDataService";
import UploadDatasetService from "../../services/UploadDatasetService";

const initialState = {
  dataset: null,
  message: "",
  uploaded:false,
  loading: false,
};

export const uploadDataset = createAsyncThunk(
  "dataset/uploadDataset",
  async (params, { rejectWithValue }) => {
    try {
      const pulicData = new UploadDatasetService();
      const data = await pulicData.uploadDataset(params);
  
      return data;
    } catch (e) {
      rejectWithValue("Something went wrong!");
    }
  }
);


export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  
  extraReducers: {
    [uploadDataset.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [uploadDataset.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dataset = payload;
      state.uploaded = true;
    },
    [uploadDataset.rejected]: (state, { payload }) => {
      state.loading = false;
      state.dataset = null;
      state.message = payload;
      state.uploaded = false;
    },


  },
});

// export const { addDatasets } = authenticationSlice.actions;
export default uploadSlice.reducer;
