import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export const RichTextEditor = (props) => {
  return (
    <ReactQuill
      theme="snow"
      value={props.value}
      onChange={props.onChange}
      modules={RichTextEditor.modules}
      formats={RichTextEditor.formats}
      bounds={".app"}
      placeholder={props.placeholder}
      style={{ width: "90%",  height: window.innerWidth <= 768 ? '30vh' : props.height}}
    />
  );
};
