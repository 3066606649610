import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GetPublicDataService from "../../services/GetPublicDataService";

const initialState = {
  datasets:[],
  // Your requests not approved
  requestsYouMade:[],
  // The requests you did not approve
  resquests:[],
  //  Your requests that were approved
  approvedRequests:[],

    // The requests you approved
  requestsApproved:[],
  
  message: "",
  isLoaded: false,
  loading: false,
  statistics:null
};

export const getUploadedDatasets= createAsyncThunk(
    "datasets/getUploadedDatasets",
    async(user, {rejectWithValue})=>{
        try {
            const publicData = new GetPublicDataService();
            const data = await publicData.getUserUploads(user)
            // console.log(data)

            return data;
        } catch (error) {

            rejectWithValue(error)
        }
    }
)

export const getStatistics= createAsyncThunk(
  "datasets/getStatistics",
  async(user, {rejectWithValue})=>{
      try {
          const publicData = new GetPublicDataService();
          const data = await publicData.getStatisticsData(user)
          return data;
      } catch (error) {
          rejectWithValue(error)
      }
  }
)


export const getRequestsYouMade = createAsyncThunk(
  "datasets/getRequestsYouMade",
  async (user, {rejectWithValue})=>{
    try{
      const requestsData = new GetPublicDataService();
      const unapproveddata = await requestsData.getRequestsYouMade(user, "unapproved")
      const approveddata = await requestsData.getRequestsYouMade(user, "approved")

      return {unapproveddata:unapproveddata.data, approveddata:approveddata.data};
    }catch(error){
      rejectWithValue(error)

    }
  }
)

export const getRequestsYouMadeApproved = createAsyncThunk(
  "datasets/getRequestsYouMade",
  async (user, {rejectWithValue})=>{
    try{
      const requestsData = new GetPublicDataService();
      const data = await requestsData.getYourRequests(user, "approved")
      // console.log(data)
      return data;
    }catch(error){
      rejectWithValue(error)

    }
  }
)

export const getYourRequests = createAsyncThunk(
  "datasets/getYourRequests",
  async(user, {rejectWithValue})=>{
      try {
          const publicData = new GetPublicDataService();
          const data_approved = await publicData.getYourRequests(user, "approved")
          const data_unapproved = await publicData.getYourRequests(user, "unapproved")
          return {data_approved:data_approved.data, data_unapproved:data_unapproved.data};
      } catch (error) {

          rejectWithValue(error)
      }
  }
)

export const dashboard = createSlice({
  name: "dataset",
  initialState,
  reducers:{
    reset : ()=>initialState
},
  extraReducers: {
      // uploaded datasets (Your datasets)
      [getUploadedDatasets.pending]: (state, { payload }) => {
        state.loading = true;
      },
      [getUploadedDatasets.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.datasets = payload;
        state.isLoaded = true;
      },
      [getUploadedDatasets.rejected]: (state, { payload }) => {
        state.loading = false;
        state.message = payload;
        state.isLoaded = false;
      },

      // Pending requests

      [getYourRequests.pending]: (state, { payload }) => {
        state.loading = true;
      },
      [getYourRequests.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.resquests = payload.data_approved;
        state.requestsApproved = payload.data_unapproved;
        state.isLoaded = true;
      },
      [getYourRequests.rejected]: (state, { payload }) => {
        state.loading = false;
        state.message = payload;
        state.isLoaded = false;
      },

      // Your requestes that were approved 

      [getRequestsYouMadeApproved.pending]: (state, { payload }) => {
        state.loading = true;
      },
      [getRequestsYouMadeApproved.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.approvedRequests = payload;
        state.isLoaded = true;
      },
      [getRequestsYouMadeApproved.rejected]: (state, { payload }) => {
        state.loading = false;
        state.message = payload;
        state.isLoaded = false;
      },

      
      // Requests you made
      [getRequestsYouMade.pending]: (state, { payload }) => {
        state.loading = true;
      },
      [getRequestsYouMade.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.requestsYouMade = payload.unapproveddata;
        state.approvedRequests = payload.approveddata;
        state.isLoaded = true;
      },
      [getRequestsYouMade.rejected]: (state, { payload }) => {
        state.loading = false;
        state.message = payload;
        state.isLoaded = false;
      },
      
      // Get statistics

      [getStatistics.pending]: (state, { payload }) => {
      },
      [getStatistics.fulfilled]: (state, { payload }) => {
        state.statistics = payload;
      },
      [getStatistics.rejected]: (state, { payload }) => {
        state.message = payload;
      },
  },
});

export const { reset } = dashboard.actions;
export default dashboard.reducer;
