import React from 'react'
// import SearchBar from "material-ui-search-bar";
import { useState } from 'react';

/**
 * 
 * @param displayedList 
 * @param originalList 
 * @param filterAttribs
 * @param changeDisplayList
 * @returns "Nothing"
 */
const CustomSearchBar = ({displayedList, originalList, filterAttribs, changeDisplayList}) => {
  const [value, setValue] = useState("")
 
  
  const changeValueHandler =(value)=>{
    if(value === ""){
      changeDisplayList(originalList)
    }
   
    const filtared = originalList.filter((element)=>{
      
      if(element[filterAttribs] && element[filterAttribs].toLowerCase().includes(value.toLowerCase())){
        return element
      }
    })
    /**
     * Fix me!!!
     * I want that we can filter from many keywords like email etc...
     */
    // const emailFiltared = originalList.filter((element)=>{
      
    //   if(element.email && element.email.toLowerCase().includes(value.toLowerCase())){
        
    //     if(filtared.find((el)=>{return el.code !== element.code})){
    //       return element
    //     }
    //   }
    // })
    
    changeDisplayList(filtared)
  }

  const cancelSearch=()=>{
    setValue("")
    changeDisplayList(originalList)
  }

  return (
    <input 
     type='text'
     value={value}
     onChange={(value)=>{changeValueHandler(value)}}
    />
  //   <SearchBar
  //   value={value}
  //   onChange={(value)=>{changeValueHandler(value)}}
  //   onCancelSearch={cancelSearch}
  // />
  )
}

export default CustomSearchBar