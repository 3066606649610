import React, { useState } from "react";
import styled from "styled-components";
import profile from "../avatar.jpg";
import UserLayout from "../layouts/UserLayout";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  changeLanguage,
  selectLanguage,
} from "../../../app/features/languageSlice";
import {
  Box,
  TextField,
  createTheme,
  Select,
  ThemeProvider,
} from "@mui/material";
import PasswordTextField from "../components/PasswordTextField";
import CustomTextField from "../components/TextField";
import { original } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { AXIOS_GET, AXIOS_PATCH } from "../config/axios";
import { GET_PROFILE, PATCH_CHANGE_PASSWORD } from "../config/url";
import Loader from "../../../helper/Loader";
import Translate from "../translate";



const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: "#01944D !important",
        },
      },
    },
  },
});

const Profile = () => {
  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [organization, setOrganization] = useState("");
  const [affiliation, setAffiliation] = useState(user.affiliation);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleAffiliationChange = (newValue) => {
    setAffiliation(newValue);
  };
  const handleNameChange = (newValue) => {
    setFullName(newValue);
  };
  const handleEmailChange = (newValue) => {
    setEmail(newValue);
  };
  const handlePasswordChange = (newValue) => {
    setPassword(newValue);
  };

  const handleConfirmPasswordChange = (newValue) => {
    setConfirmPassword(newValue);
  };
  const handleOldPasswordChange = (newValue) => {
    setOldPassword(newValue);
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    setIsChanging(true);
    const data = {
      oldpassword: oldPassword,
      newpassword: password,
      confirmpassword: confirmPassword,
    };
    AXIOS_PATCH(PATCH_CHANGE_PASSWORD, data, sessionStorage.getItem("token"))
      .then((res) => {
        toast.success("Your password changed successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
      })
      .catch((err) => {
        // console.error(err.response);
        setIsChanging(false);
        toast.error(err.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
      })
      .finally(() => {
        setIsChanging(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    AXIOS_GET(
      `${GET_PROFILE}${JSON.parse(localStorage.getItem("user")).code}`,
      sessionStorage.getItem("token")
    )
      .then((res) => {
        // console.log(res.data);
        setFullName(res.data.user.username);
        setAffiliation(res.data.user.affiliation);
        setOrganization(res.data.user.organization);
        setEmail(res.data.user.email);
        setUser(res.data.user);
      })
      .catch((error) => {
        setLoading(false);
        // console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);
  const languageValue = useSelector(selectLanguage);
  return (
    <UserLayout isProfile={true}>
      <ToastContainer />
      <Container>
        <Basic>
          <ImageContainer>
            <Image src={profile} alt="Profile Image" />
            <CustomFileLabel language={languageValue} htmlFor="profile-pic">
              <IconUpload />
            </CustomFileLabel>
            <CustomFileInput type="file" id="profile-pic" accept="image/*" />
          </ImageContainer>

          <Info>
            <ThemeProvider theme={theme}>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <div style={{marginRight:10}}>
                  <TextField
                    width="120%"
                    // id="outlined-size-small"
                    placeholder="Full Name"
                    size="medium"
                    color="warning"
                    disabled="disabled"
                    readOnly="true"
                    // variant="outlined"
                    value={fullName}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        borderColor: "#01944D",
                        cursor: "pointer",
                      },
                      readOnly: true,
                    }}
                    focused
                  />
                </div>
              </Box>
            </ThemeProvider>
          </Info>
        </Basic>
        <DetailsContainer>
          <Title>
            <span style={{ color: "#01944D", fontWeight: "bold" }}>
              <Translate Word={"profile-details"} />
            </span>
          </Title>
          <FormContainer>
            <InputContainer>
              <Provider>
                <TextInput
                  label={<Translate Word="profile-form-label-full-name"/>}
                  value={fullName}
                  
                  disabled="disabled"
                  width={isMobile ? "100%":"120%"}
                  readOnly="true"
                  placeholder="Full Name"
                  onChange={handleNameChange}
                />
              </Provider>
              <Provider>
                <TextInput
                  label={<Translate Word="profile-form-label-organization"/>}
                  disabled="disabled"
                  width={isMobile ? "100%":"120%"}
                  readOnly="true"
                  placeholder="Organization"
                  value={organization}
                  onChange={handleAffiliationChange}
                />
              </Provider>
            </InputContainer>
            <InputContainer>
              <Provider>
                <TextInput
                  label={<Translate Word="profile-form-label-email"/>}
                  placeholder="Email"
                  width={isMobile ? "100%":"120%"}
                  size="medium"
                  disabled="disabled"
                  readOnly="true"
                  color="warning"
                  value={email}
                  onChange={handleEmailChange}
                />
              </Provider>
              <Provider>
                <TextInput
                  label={<Translate Word="profile-form-label-join-date"/>}
                  width={isMobile ? "100%":"120%"}
                  placeholder="Join Date"
                  disabled="disabled"
                  readOnly="true"
                  value={user.joinDate}
                />
              </Provider>
            </InputContainer>
            {/* <UpdateButton>
              <button type="button">
                <Translate Word={"update-profile"} />
              </button>
            </UpdateButton> */}
          </FormContainer>
          <Title>
            <span style={{ color: "#01944D", fontWeight: "bold" }}>
              <Translate Word={"profile-form-label-change-password"} />
            </span>
          </Title>
          <FormContainer>
            <InputContainer>
              <Provider>
                <Password
                  requied={true}
                  label={<Translate Word="profile-form-label-new-password"/>}
                  placeholder="New Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Provider>
              <Provider>
                <Password
                  requied={true}
                  label={<Translate Word="profile-form-label-confirm-password"/>}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
              </Provider>
            </InputContainer>
            <InputContainer>
              <Provider>
                <Password
                  requied={true}
                  label={<Translate Word="profile-form-label-old-password"/>}
                  placeholder="Old Password"
                  value={oldPassword}
                  onChange={handleOldPasswordChange}
                />
              </Provider>
            </InputContainer>
            <UpdateButton>
              <button onClick={handleChangePassword}>
                {" "}
                {isChanging ? (
                  <Loader color="white" />
                ) : (
                  <Translate Word={"profile-form-button-change-password"} />
                )}{" "}
              </button>
            </UpdateButton>
          </FormContainer>
        </DetailsContainer>
      </Container>
    </UserLayout>
  );
};
const TextInput = styled(CustomTextField)`
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 250% !important;
    background-color:red;
  }
`;
const Password = styled(PasswordTextField)`
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const IconUpload = styled(CameraAltIcon)`
  vertical-align: bottom;
  color: #01944D;
`;
const UpdateButton = styled.div`
  button {
    color: white;
    padding: 5px;
    width: 100%;
    border-radius: 4px;
    background: #01944D;
    border: none;
    outline: none;
    cursor: pointer;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1% 3%;
  width: 94%;
`;
const Provider = styled.div`
  display: flex;
  width: 50% ;
  flex: 1;

  @media screen and (max-width: 768px) {
    width: 100% !important;
    flex: 1;
    margin: 10px 0px;
    // background-color:red;
    align-items:center;
    justify-content:center;
  }
`;
const FormContainer = styled.form`
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 1%;
  justify-content: flex-start;
  border: 2px solid white;
  padding: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0px;
    // background-color:red;
  }
`;
const InputContainer = styled.div`
  padding: 1% 3%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 1% 0%;
    // background-color:red;
  }
`;
const Title = styled.p`
  text-align: center;
  font-size: 17px;
  width: 100%;
  /* border-bottom: 1px solid #01944D; */
`;
const Label = styled.span`
  color: rgb(231, 131, 37);
`;

// const ImageContainer = styled.form`
//   width: 100%;
//   label::after {
//     content: "Change Profile";
//   }
//   input {
//     visibility: hidden;
//     position: absolute;
//   }
//   img {
//     width: 100%;
//     height: auto;
//     /* object-fit: contain; */
//     /* mix-blend-mode: color-burn; */
//     border-radius: 50%;
//   }
// `;
const ImageContainer = styled.div`
  position: relative;
  margin-right:10px;
  display: inline-block;
  @media screen and (max-width: 768px) {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;
  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;

const CustomFileLabel = styled.label`
  &:after {
    content: "Change Profile image";
    cursor: pointer;
    color: black;
  }
`;

const CustomFileInput = styled.input`
  display: none;
`;
const Info = styled.div`
  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const DetailsContainer = styled.div`
  flex: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
`;
const Basic = styled.div`
  flex: 5%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex: 40%;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-around;
  padding: 2%;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export default Profile;
