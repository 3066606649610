import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../Upanzi Network logo.png";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  selectLanguage,
  changeToEn,
  changeToFr,
  changeLanguage,
} from "../../../app/features/languageSlice";
import LanguageIcon from "@mui/icons-material/Language";
import { Box, Typography } from "@mui/material";
import { logout } from "../../../app/features/authSlicer";
import SendIcon from "@mui/icons-material/Send";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import profile from "../david.jpg";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import profileImg from "../avatar.jpg";
import { useDispatch, useSelector } from "react-redux";
import { Inbox } from "@material-ui/icons";
import Translate from "../translate";
import { set } from "react-hook-form";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';

const UserLayout = ({
  children,
  isProfile,
  isDash,
  isReceivedRequest,
  isSentRequest,
  isUpload,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const dispatch = useDispatch();
  const languageVal = useSelector(selectLanguage);
  const [selectedLang, setselectedLang] = useState(languageVal);
  const [sideBarVisible, setSidebarVisible] = useState(window.innerWidth > 768 ? true : false);
  const [profileVisible, setProfileVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleMenuChange = (e) => {
    if(window.innerWidth < 768 ){
      setSidebarVisible(false);
      setProfileVisible(false);
    }
  }
  const handleProfileMenu = () => {
    setProfileVisible(!profileVisible);
  };
  const HandleSideBar = () => {
    setSidebarVisible(!sideBarVisible);
    setProfileVisible(false);
  };
  const handleLogout = (e) => {
    // console.log("logged out");
    history.push("/");
    e.preventDefault();
    dispatch(logout());
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("isAuth");
    localStorage.removeItem("user");
    setProfileVisible(false);
    setSidebarVisible(false);
  };
  const changeLanguage = (e) => {
    setselectedLang(e.target.value);
    localStorage.setItem("language", e.target.value);
    if (selectedLang == "En") {
      dispatch(changeToFr());
    } else {
      dispatch(changeToEn());
    }
    // console.log("change language" + languageVal);
  };
  return (
    <DashboardPage>
      {sideBarVisible && !profileVisible && (
        <SideBar visible={sideBarVisible}>
          <Logo to="/" style={{ flexGrow: 1 }}>
            <img
              style={{ cursor: "pointer" }}
              className="image-logo"
              src={logo}
              alt="logo"
            />
          </Logo>
          <NavLink>
            <li>
              <Link onClick={handleMenuChange} to="/dash" style={isDash ? { color: "#01944D" } : null}>
                <span>
                  <IconHome />
                </span>
                <Translate Word={"home"} />
              </Link>
            </li>
            {!isMobile &&
            <li>
              <Link onClick={handleMenuChange} to="/upload" style={isUpload ? { color: "#01944D" } : null}>
                <span>
                  <IconAdd />
                </span>
                <Translate Word={"upload-dataset"} />
              </Link>
            </li>}
            <li>
              <Link
                onClick={handleMenuChange}
                to="/profile"
                style={isProfile ? { color: "#01944D" } : null}
              >
                <span>
                  <IconProfile />
                </span>
                <Translate Word={"profile"} />
              </Link>
            </li>
            <li>
              <Link
                onClick={handleMenuChange}
                to="/requests/recieved"
                style={isReceivedRequest ? { color: "#01944D" } : null}
              >
                <span>
                  <IconInbox />
                </span>
                <Translate Word={"incoming-requests"} />
              </Link>
            </li>
            <li>
              <Link
                onClick={handleMenuChange}
                to="/requests/sent"
                style={isSentRequest ? { color: "#01944D" } : null}
              >
                <span>
                  <IconRequest />
                </span>
                <Translate Word={"sent-Requests"} />
              </Link>
            </li>
            <li onClick={handleLogout}>
              <Link to="">
                <span>
                  <IconLogout />
                </span>
                <Translate Word={"Logout"} />
              </Link>
            </li>
            {/* <PrivacyLink>
              <Link
                onClick={handleMenuChange}
                to="/privacy/update"
              >
                <span>
                  <IconPrivacy />
                </span>
                <Translate Word={"update_privacy"} />
              </Link>
            </PrivacyLink> */}
          </NavLink>
        </SideBar>
      )}
      <Container
        sideBarVisible={sideBarVisible}
        profileVisible={profileVisible}
      >
        <NavBar>
          <IconMenu
            profileVisible={profileVisible}
            sidebarvisible={sideBarVisible}
            onClick={HandleSideBar}
            style={{ fontSize: "30px" }}
          />
          <LanguageProfile>
            <Language>
              <Box display="flex" alignItems="center">
                <IconLanguage variant="contained" />
                <Typography variant="body1">
                  <select onChange={changeLanguage}>
                    {selectedLang == "En" && (
                      <>
                        <option value="En">En</option>
                        <option value="Fr">Fr</option>
                      </>
                    )}
                    {selectedLang == "Fr" && (
                      <>
                        <option value="Fr">Fr</option>
                        <option value="En">En</option>
                      </>
                    )}
                  </select>
                </Typography>
              </Box>
            </Language>
            <Profile>
              {!profileVisible ? (
                <img
                  onClick={handleProfileMenu}
                  src={user?.profile ? user?.profile : profileImg}
                  alt=""
                />
              ) : (
                <IconExpand onClick={handleProfileMenu} />
              )}
            </Profile>
          </LanguageProfile>
        </NavBar>
        {children}
      </Container>
      {profileVisible && (
        <ProfileSide>
          <ImgHolder>
            <img
              src={
                JSON.parse(localStorage.getItem("user")).profile
                  ? JSON.parse(localStorage.getItem("user")).profile
                  : profileImg
              }
              alt=""
            />
            <span>{user?.username}</span>
          </ImgHolder>
          <ProfileMenu>
            <NavLink>
              <li>
                <Link onClick={handleMenuChange} to="/profile">
                  <span>
                    <IconProfile />
                  </span>
                  <Translate Word={"change-profile"} />
                </Link>
              </li>
              <li onClick={handleLogout}>
                <Link to="">
                  <span>
                    <IconLogout />
                  </span>
                  <Translate Word={"Logout"} />
                </Link>
              </li>
            </NavLink>
          </ProfileMenu>
        </ProfileSide>
      )}
    </DashboardPage>
  );
};
const PrivacyLink = styled.li`
margin-top: 200px;
`
const IconPrivacy = styled(PrivacyTipIcon)`
  vertical-align: bottom;
  color: #01944D;`
const IconLanguage = styled(LanguageIcon)`
  color: #01944D;
  vertical-align: bottom;
`;
const Language = styled.div`
  padding-right: 50px;
  select {
    border-color: #01944D;
    border-radius: 2px;
    font-family: "Bebas Neue", cursive;
  }
`;
const IconAdd = styled(AddCircleIcon)`
  vertical-align: bottom;
`;
UserLayout.defaultProps = {
  isProfile: false,
  isDash: false,
  isRequest: false, // Assign default value for isActive prop
};
const IconExpand = styled(ExpandCircleDownIcon)`
  transform: rotate(-90deg);
  margin-top: 10px;
  vertical-align: bottom;
  cursor: pointer;
`;
const ImgHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Bebas Neue", cursive;
  color: #01944D;
  height: 80px;
  padding-right: 20px;
  img {
    height: inherit;
    width: auto;
    border-radius: 50%;
    border: 1px solid #01944D;
  }
`;
const ProfileMenu = styled.div`
  margin-top: 10px;
`;
const ProfileSide = styled.div`
  width: 20%;
  height: 100vh;
  transition: opacity 0.3s ease-in-out;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20px;
  align-items: center;

  @media screen and (max-width: 768px) {
    position: absolute;
    width: 50vw;
    background: white;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 1);
    z-index: 100;
    right: 0;
  }
`;
const LanguageProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
`;
const Profile = styled.div`
  height: 50px;
  padding-right: 10px;
  img {
    height: inherit;
    width: auto;
    border-radius: 50%;
    border: 1px solid #01944D;
  }
`;
const IconMenu = styled(MenuIcon)`
  vertical-align: bottom;
  margin-left: 10px;
  font-size: 5px;
  &:hover {
    cursor: pointer;
  }
`;
const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  background: white;
`;
const IconLogout = styled(LogoutIcon)`
  vertical-align: bottom;
`;
const IconRequest = styled(SendIcon)`
  vertical-align: bottom;
`;
const IconInbox = styled(Inbox)`
  vertical-align: bottom;
`;
const IconProfile = styled(AccountCircleIcon)`
  vertical-align: bottom;
`;
const IconHome = styled(HomeIcon)`
  vertical-align: bottom;
`;
const NavLink = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  margin-top: 40px;
  width: 100%;
  li {
    list-style-type: none;
    padding: 0.5rem 0.1rem;
    width: 100%;
    a {
      font-family: "Bebas Neue", cursive;
      width: 100%;
      padding: 0, 1rem;
      color: black;
      span {
        padding-right: 0.5rem;
      }
      &.active {
        color: #01944D;
      }
    }
  }
`;
const Logo = styled(Link)`
  text-decoration: none !important;
  border-bottom: none !important;
  width: 100%;
  max-height: 100px;
  img {
    padding: 10px 10px;
    width: 100%;
    height: auto;
  }
`;
const SideBar = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100vh;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062);
  transition: opacity 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    position: absolute;
    width: 50vw;
    background: white;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 1);
    z-index: 100;
  }
`;
const Container = styled.div`
  width: ${(props) =>
    props.sideBarVisible || props.profileVisible ? "80%" : "100%"};
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: inherit;
  background: #e1dfdf;
  transition: width 0.5s ease-in-out;
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    width: 100vw !important;
    overflow: auto;
  }
`;
const DashboardPage = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  width: 100vw;

  @media screen and (max-width: 768px) {
    overflow: auto;
  }
`;
export default UserLayout;
