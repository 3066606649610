import { React, useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import image from "../image.png";
import illustation_image from '../open_data_image_two.png'
import "./page.css";
import { Box, Typography } from "@mui/material";
// import Header from "../layouts/header";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import {Warning} from "@mui/icons-material"
// import { selectLanguage } from "../../../app/features/languageSlice";
import Translate from "../translate";
import CategoriesCard from "../components/categoriesCard";
import DatasetCard from "../components/datasetCard";
import GroupCategory from "../components/groupCategory";
// import styled from "styled-components";
// import Footer from "../layouts/footer";
// import { Link, Element } from "react-scroll";
import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { AXIOS_GET } from "../config/axios";
import { GET_LANDING } from "../config/url";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import PreviewIcon from "@mui/icons-material/Preview";
import GradeIcon from "@mui/icons-material/Grade";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ContentLoader, { Facebook } from "react-content-loader";
import AppLayout from "../layouts/AppLayout";

const Landing = () => {
  const MyLoader = () => (
    <>
      <ContentLoader>
        {/* <rect x="0" y="0" rx="5" ry="5" width="70" height="70" /> */}
        <rect x="80" y="17" rx="4" ry="40" width="300" height="300" />
        {/* <rect x="80" y="40" rx="3" ry="3" width="200" height="10" />
        <rect x="80" y="60" rx="3" ry="3" width="200" height="10" /> */}
      </ContentLoader>
      {/* <Facebook />; */}
    </>
  );

  // const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const history = useHistory();

  const [datasets, setDatasets] = useState(0);
  const [categories, setCategories] = useState([]);
  const [recents, setRecents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [category, setCategory] = useState("");
  const [sdgsStatistics, setSdgsStatistics] = useState([]);


  const sendThemeId=(theme, id, number)=>{
    // console.log(number)
    if(number == 0 ){
        return
    }
    try {
      history.push({
        pathname: "/filter",
        state: { theme: theme, themeId:id },
      });
    } catch (error) {
      // console.error("Error navigating to filter page:", error);
    }
  }
  const handleSubmit = () => {
    if (searchValue?.length > 5) {
      try {
        history.push({
          pathname: "/filter",
          state: { searchValue: searchValue },
        });
      } catch (error) {
        // console.error("Error navigating to filter page:", error);
      }
    } else {
      alert(
        "Search field must have something to search e.g: Education in Africa"
      );
    }
  };

  useEffect(() => {
    AXIOS_GET(GET_LANDING)
      .then((res) => {
        // console.log(res.data.data);
        setDatasets(res.data.data.number_of_datasets);
        setCategories(res.data.data.datasets_per_category);
        setRecents(res.data.data.recent_datasets);
        setSdgsStatistics(res.data.sdgs);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  const educationHealthGeospatial = ["education", "health", "geospatial"];

  const specialCategories = categories.filter((category) =>
    educationHealthGeospatial.includes(category.category.toLowerCase())
  );

  const otherCategories = categories.filter(
    (category) =>
      !educationHealthGeospatial.includes(category.category.toLowerCase())
  );
  const totalOtherCategories = otherCategories.reduce(
    (total, category) => total + category.number,
    0
  );
  
  return (
    <AppLayout>
      <div className="landig-page">
        <div className="search-container">
          <div className="left item">
            <h1>OPEN DATA PORTAL</h1>
            {loading ? (
              <ContentLoader>
                <rect x="20" y="17" rx="4" ry="4" width="300" height="13" />
                <rect x="20" y="40" rx="3" ry="3" width="250" height="10" />
              </ContentLoader>
            ) : (
              <p>
                <span style={{ color: "#01944D", fontWeight: "bold" }}>
                  Open Data Portal
                </span>
                , <Translate Word={"open-data-description"} />
              </p>
            )}
            <div className="search">
              <div className="search-input">
                <span className="search-text">
                  <Translate Word="search-through" />
                  <span className="search-number"> {datasets} </span>
                  <Translate Word={"search-through-continue"} />
                </span>

                <form className="search-box-container" onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <div className="search-button" onClick={handleSubmit}>
                    <Box display="flex" alignItems="center">
                      <PersonSearchIcon
                        variant="contained"
                        className="person-search"
                      />
                      <Typography variant="body1"></Typography>
                    </Box>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="right item" style={{ flex: 1 }}>
            {/* <img src={image} alt="" /> */}
            <img className="image-illustartion" src={illustation_image} alt="" />


          </div>
        </div>
        <section className="cat-section">
          <h1 className="cat-header">
            <Translate Word={"explore-more-categories"} />
          </h1>
          <div className="dataset-category education-health-geo">
            {loading ? (
              <MyLoader />
            ) : (
              specialCategories.map((category, index) => (
                <CategoriesCard
                  key={index}
                  title={category.category}
                  desc={`${category.number} Dataset(s) on Africa ${category.category}`}
                  category={category.category}
                />
              ))
            )}
          </div>
        </section>
       
        <section className="most-recent">
          <h1 className="dataset-header">
            <Translate Word={"most-recent-datasets"} />
          </h1>
          <div
            className="dataset-category"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {loading ? (
              <MyLoader />
            ) : (
              recents
                .slice(0, 3)
                .map((recent, index) => (
                  <DatasetCard
                    index={index}
                    title={recent.name}
                    author={recent.creator.username}
                    desc={`${recent.description.substring(0, 1000)}...`}
                    published={recent.createdAt}
                    views={recent.views}
                    likes={recent.likes}
                    data={recent}
                  />
                ))
            )}
          </div>
        </section>
        <h1 style={{marginLeft:'1%'}} className="dataset-header">
            <Translate Word={"most-recent-datasets"} />
          </h1>
        <div
          id="sdgs"
          style={{width:'100%', padding:'2%'}}
        >
            <img  className="un-sgd-logo" src={require('../../../images/sdgs/E_SDG_logo_UN_emblem_horizontal_trans_PRINT.png')} alt="UN SDG" srcset="" />
            <p style={{paddingLeft:15}} ><a target="_blank" href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/" rel="noreferrer">The 17 United Nations Sustainable Development Goals (SDGs)</a> aim to tackle worldwide challenges such as poverty, inequality, climate change, etc.. fostering a more sustainable and equitable world by 2030.  </p>
            <p style={{paddingLeft:15}} ><b>This section allows you to explore various types of datasets categorized under the 17 SDGs goals.</b></p>
            <p style={{paddingLeft:15}} ><i><Warning style={{color:'#01944D', paddingTop:5}} variant="contained" />The classification is conducted using the OpenAI GPT-3.5 Turbo LLM. Therefore, there may be occasional inconsistencies in the classification. Please report any abnormalities you encounter to assist us in enhancing the classification model.<Warning style={{color:'#01944D', paddingTop:5}} variant="contained" /></i></p>
            
            <div className="sdgs-icons">
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-01.png')} alt="" srcset="" />

                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 1: No Poverty</h6>
                      <p>Economic growth must be inclusive to provide sustainable jobs and promote equality.</p>
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS','SDG1', sdgsStatistics[0].datasets)}} class="sdg_card_button">{sdgsStatistics[0].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-02.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 2: Zero Hunger</h6>
                      <p>The food and agriculture sector offers key solutions for development, and is central for hunger and poverty eradication.</p>
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS', 'SDG2', sdgsStatistics[1].datasets)}} class="sdg_card_button">{sdgsStatistics[1].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-03.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 3: Good Health and Well-Being</h6>
                      <p>Ensuring healthy lives and promoting the well-being for all at all ages is essential to sustainable development.</p>
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS', 'SDG3', sdgsStatistics[2].datasets)}} class="sdg_card_button">{sdgsStatistics[2].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-04.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 4: Quality Education</h6>
                      <p>Obtaining a quality education is the foundation to improving people’s lives and sustainable development.</p>
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS', 'SDG4', sdgsStatistics[3].datasets)}} class="sdg_card_button">{sdgsStatistics[3].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-05.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 5: Gender Equality</h6>
                      <p>Gender equality is not only a fundamental human right, but a necessary foundation for a peaceful, prosperous and sustainable world.</p>
                      {/* <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p> */}
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS', 'SDG5', sdgsStatistics[4].datasets)}} class="sdg_card_button">{sdgsStatistics[4].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-06.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 6: Clean Water and Sanitation</h6>
                      <p>Clean, accessible water for all is an essential part of the world we want to live in.</p>
                      {/* <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p> */}
                     {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS','SDG6', sdgsStatistics[5].datasets)}} class="sdg_card_button">{sdgsStatistics[5].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-07.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 7: Affordable and Clean Energy</h6>
                      <p>Energy is central to nearly every major challenge and opportunity.</p>
                      {/* <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p> */}
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS','SDG7', sdgsStatistics[6].datasets)}} class="sdg_card_button">{sdgsStatistics[6].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-08.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 8: Decent Work and Economic Growth</h6>
                      <p>Sustainable economic growth will require societies to create the conditions that allow people to have quality jobs.</p>
                      {/* <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p> */}
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS','SDG8', sdgsStatistics[7].datasets)}} class="sdg_card_button">{sdgsStatistics[7].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-09.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 9: Industry, Innovation, and Infrastructure</h6>
                      <p>Investments in infrastructure are crucial to achieving sustainable development.</p>
                      {/* <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p> */}
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS', 'SDG9', sdgsStatistics[8].datasets)}} class="sdg_card_button">{sdgsStatistics[8].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-10.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 10: Reduced Inequalities</h6>
                      <p>To reduce inequalities, policies should be universal in principle, paying attention to the needs of disadvantaged and marginalized populations.</p>
                      {/* <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p> */}
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS', 'SDG10', sdgsStatistics[9].datasets)}} class="sdg_card_button">{sdgsStatistics[9].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-11.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 11: Sustainable Cities and Communities</h6>
                      <p>There needs to be a future in which cities provide opportunities for all, with access to basic services, energy, housing, transportation and more.</p>
                      {/* <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p> */}
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS','SDG11', sdgsStatistics[10].datasets)}} class="sdg_card_button">{sdgsStatistics[10].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-12.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 12: Responsible Consumption and Production</h6>
                      <p>Responsible Production and Consumption.</p>
                      {/* <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p> */}
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS','SDG12', sdgsStatistics[11].datasets)}} class="sdg_card_button">{sdgsStatistics[11].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-13.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 13: Climate Action</h6>
                      <p>Climate change is a global challenge that affects everyone, everywhere.</p>
                      {/* <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p> */}
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS','SDG13', sdgsStatistics[12].datasets)}} class="sdg_card_button">{sdgsStatistics[12].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-14.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 14: Life Below Water</h6>
                      <p>Careful management of this essential global resource is a key feature of a sustainable future.</p>
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS','SDG14', sdgsStatistics[13].datasets)}} class="sdg_card_button">{sdgsStatistics[13].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-15.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 15: Life on Land</h6>
                      <p>Sustainably manage forests, combat desertification, halt and reverse land degradation, halt biodiversity loss.</p>
                      {/* <p>Provident consectetur natus voluptatem quis tenetur sed beatae eius sint.</p> */}
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS','SDG15', sdgsStatistics[14].datasets)}} class="sdg_card_button">{sdgsStatistics[14].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-16.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 16: Peace, Justice and Strong Institutions</h6>
                      <p>Access to justice for all, and building effective, accountable institutions at all levels.</p>
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS', 'SDG16', sdgsStatistics[15].datasets)}} class="sdg_card_button">{sdgsStatistics[15].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
            <div class="sdg_card" href="#!">
                  <div className="sdg_card_front" >
                  <img style={{width:'100%', height:'100%'}} src={require('../../../images/sdgs/E-WEB-Goal-17.png')} alt="" srcset="" />
                  </div>
                  <div class="sdg_card_back">
                    <div>
                      <h6>Goal 17: Partnerships</h6>
                      <p>Revitalize the global partnership for sustainable development.</p>
                      {sdgsStatistics?.length > 0 && <button onClick={()=>{sendThemeId('SDGS','SDG17', sdgsStatistics[16].datasets)}} class="sdg_card_button">{sdgsStatistics[16].datasets} Datasets</button>}
                    </div>
                  </div>
            </div>
              {/* <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-01.png')} alt="" srcset="" /> */}
              {/* <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-02.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-03.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-04.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-05.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-06.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-07.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-08.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-09.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-10.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-11.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-12.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-13.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-14.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-15.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-16.png')} alt="" srcset="" />
              <img style={{width:'15%', margin:2}} src={require('../../../images/sdgs/E-WEB-Goal-17.png')} alt="" srcset="" /> */}
              <div style={{width:'15%', margin:2}}></div>
            </div>
        </div>
        {/* <section className="cat-section">
          <h1 className="cat-header">
            <Translate Word={"explore-more-by-rating"} />
          </h1>
          <div className="dataset-category">
            <GroupCategory
              title="most-rated"
              icon={<GradeIcon />}
              number="1,050"
            />
            <GroupCategory
              title="most-viewed"
              icon={<PreviewIcon />}
              number="1,300"
            />
            <GroupCategory
              title="most-downloaded"
              icon={<DownloadForOfflineIcon />}
              number="1,000"
            />
          </div>
        </section> */}
      </div>
    </AppLayout>
  );
};
export default Landing;
