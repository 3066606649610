import React, {useEffect} from 'react'
import { FaExchangeAlt } from 'react-icons/fa'
import { HiUsers } from 'react-icons/hi'
import { IoDocumentsSharp } from 'react-icons/io5'
import { MdVerified } from 'react-icons/md'
import "./sidenav.css"
import { getAccount, getCurrentNav, setCurrentNav } from '../../../utils/localStorageUtil'


function SideNav(props) {

  useEffect(()=>{
    const current  = getCurrentNav()
    navigate(current)
  },[])

  const navigate =(id)=>{
      setCurrentNav(id)

      // document.getElementById
      // active  Class removal and assignment
      document.getElementsByClassName('admin-side-nav-item-active')[0].classList.remove('admin-side-nav-item-active')
      document.getElementById(`item-${id}`).classList.add('admin-side-nav-item-active')      
      props.setScreens(props.screens.map((screen)=>{
        if(screen.id === id){
          screen.active = true
        }else{
          screen.active = false
        }
        return screen
      }))
    // console.log(screens)
  }

  return (
    <div >
          {/* <div className="side-logo">
            <h4 className="logo">{"Patrick"}</h4>
          </div>
          <br /> */}
         
          <ul style={{listStyle:'none', padding:0}}>
            <li id='item-1' onClick={()=>{navigate(1)}} className="admin-side-nav-item admin-side-nav-item-active" ><HiUsers className="admin-nav-icon" style={{marginRight:6, marginBottom:3}} /> <br/> <span className="list-text" >Users</span></li>
            <li id='item-2' onClick={()=>{navigate(2)}} className="admin-side-nav-item" ><IoDocumentsSharp className="admin-nav-icon" style={{marginRight:6, marginBottom:3}} /> <span className="list-text" >Datasets</span></li>
            {/* <li id='item-3' onClick={()=>{navigate(3)}} className="admin-side-nav-item" ><FaExchangeAlt className="admin-nav-icon" style={{marginRight:6, marginBottom:3}} /> <span className="list-text" >Datasets Requests</span></li> */}
            <li id='item-4' onClick={()=>{navigate(4)}} className="admin-side-nav-item" ><MdVerified className="admin-nav-icon" style={{marginRight:6, marginBottom:3}} /> <span className="list-text" >Verification Requests</span></li>
          </ul>
        </div>
  )
}

export default SideNav