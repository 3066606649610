import React, { useState, useEffect } from "react";
import UserLayout from "../layouts/UserLayout";
import styled from "styled-components";
import RequestTable from "../components/RequestTable";
import { Chip, IconButton } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { Cancel } from "@material-ui/icons";
import axios from "axios";
import moment from "moment/moment";
import { useConfirm } from "material-ui-confirm";
import Translate from "../translate";

const Requests = () => {
  const confirm = useConfirm();
  const [status, setStatus] = useState("all");
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [hasNext, setHasNext] = useState(false)
  const [cursor, setCursor] = useState()
  const [loadingPagination, setLoadingPagination] = useState(false)
  const token = sessionStorage.getItem("token");
  const [actionLoader, setActionLoader] = useState(false)


  const getMoreData = async ()=>{
    try {
        setLoadingPagination(true)
        const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/datasets/requests/all/owner?limit=20&cursor=${cursor}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setCursor(response.data.paging.nextCursor)
      setHasNext(response.data.paging.hasMore)
      const responseData = response.data.data.map((item) => {
        const { code, from, file, sentOn, ...rest } = item;
        return {
          id: code,
          sendor: from.username,
          file: file.name,
          sentOn: moment(sentOn).format("MM/DD/YYYY HH:MM"),
          ...rest,
        };
      });
      setRows([...rows, ...responseData]);
      setFilteredRows([...rows, ...responseData]);
      setLoadingPagination(false)

    } catch (error) {
      setLoadingPagination(false)

    } finally {
      setLoadingPagination(false)

    }
  }
  const getRequests = async (useLoader) => {
    try {
      if (useLoader) {
        setLoading(true);
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/datasets/requests/all/owner?limit=20`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setCursor(response.data.paging.nextCursor)
      setHasNext(response.data.paging.hasMore)
      const responseData = response.data.data.map((item) => {
        const { code, from, file, sentOn, ...rest } = item;
        return {
          id: code,
          sender: from.username,
          file: file.name,
          sentOn: moment(sentOn).format("MM/DD/YYYY HH:MM"),
          ...rest,
        };
      });
      setRows(responseData);
      setFilteredRows(responseData);
    } catch (error) {
      // console.log(error);
    } finally {
      if (useLoader) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getRequests(true);
    return () => {
      setRows();
    };
  }, []);

  useEffect(() => {
    const filteredData =
      status === "all" ? rows : rows.filter((row) => row.status === status);
    setFilteredRows(filteredData);
  }, [status]);

  const handleClickApprove = (id) => {
    setActionLoader(true)
    confirm({
      title: "Are you sure you want to grant access to this data",
      confirmationText: "Approve",
    })
      .then(async () => {
        const body = {
          status: "approved",
        };

        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/change-dataset-request/${id}`,
          body,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        getRequests(false);
      })
      .catch((error) => {
        // console.log(error);
      });
    setActionLoader(false)

  };

  const handleClickReject = async (id) => {

    confirm({
      description: "This action is will revoke access to this dataset!",
    })
      .then(async () => {
        const body = {
          status: "rejected",
        };

        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/change-dataset-request/${id}`,
          body,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        getRequests(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleTabChange = (event, newStatus) => {
    setStatus(newStatus);
  };

  const columns = [
    // { field: 'id', headerName: 'ID', width: 200 },
    { field: "sender", headerName: <Translate Word={"incoming-requests-sender"} />, minWidth: 200 },
    { field: "file", headerName: <Translate Word={"incoming-requests-file"} />, minWidth: 300 },
    { field: "sentOn", headerName: <Translate Word={"incoming-request-senton"} />, minWidth: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        const status = params.value;
        return status === "approved" ? (
          <Chip label={"APPROVED"} color="success" variant="outlined" />
        ) : status === "rejected" ? (
          <Chip label={"REJECTED"} color="error" variant="outlined" />
        ) : (
          <Chip label={"PENDING"} color="warning" variant="outlined" />
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton>
            <IconApprove onClick={() => handleClickApprove(params.row.id)} />
          </IconButton>
          <IconButton>
            <IconReject onClick={() => handleClickReject(params.row.id)} />
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <UserLayout isReceivedRequest={true}>
      <>
        <Container>
          <Title>
            <h2>
              {" "}
              <Translate Word={"requests-received"} />
            </h2>
          </Title>
          <TableContainer>
            <RequestTable

              hasMore={hasNext}
              loadingPagination={loadingPagination}
              getMoreData={getMoreData}
              status={status}
              handleTabChange={handleTabChange}
              loading={loading}
              columns={columns}
              rows={filteredRows}
              type="incoming"
            />
          </TableContainer>
        </Container>
      </>
    </UserLayout>
  );
};
const Title = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 20px;
    color: #01944D;
    /* font-weight: bold; */
    font-family: "Bebas Neue", cursive;
  }
`;
const Container = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TableContainer = styled.div`
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;
const IconApprove = styled(CheckCircle)`
  vertical-align: bottom;
  cursor: pointer;
  color: #fa8518;
  font-weight: bold;
`;

const IconReject = styled(Cancel)`
  vertical-align: bottom;
  cursor: pointer;
  color: #fa8518;
  font-weight: bold;
`;

export default Requests;
